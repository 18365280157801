import React, { useEffect, useState } from "react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { inputStyle } from "../formStyles";
import { ArrowBack } from "@mui/icons-material";
import apiRequest from "../../../services/api-request";
import { disabledButton, primaryButton } from "../../../../common-styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

export default function VpnConfigurationForm(props) {
  const classes = useStyles();

  const { handleGoBack, currentTask } = props;

  const { companyId } = currentTask?.wfCurrTrans;

  let [sdpVpn, setSdpVpn] = useState({
    sdp: props?.sdpVpn?.sdp,
    vpn: props?.sdpVpn?.vpn,
    companyId,
  });

  let vpnConfigurationFormStatus = "Not Started";

  const initialState = {
    snackbarOpen: false,
    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
    loading: false,
  };

  const [state, setState] = useState(initialState);
  const [isSubmitDisabledButton, setSubmitDisabledButton] = useState(false);

  let [vpnDetails, setVpnDetails] = useState(sdpVpn?.vpn ?? {});
  let [vpnDtlsData, setVpnDtlsData] = useState(sdpVpn?.sdp ?? {});

  const {
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
    loading,
  } = state;

  // console.log({ sdpVpn });

  const {
    authenticationAlgorithm,
    authenticationMode,
    diffieHellmanGroup,
    encryptionAlgorithm,
    lifetime,
    lifetimeMeasurement,
    model,
    os,
    peerAddress,
    preSharedKey,
    proposalname,
    supplier,
    testPeerAddress,
    authenticationAlgorithmEsp,
    encryptionAlgorithmAes,
    encapsulationModeEsp,
    perfectForwardSecrecy,
    lifetimeMeasurementEsp,
    lifetimeEsp,
    network,
    type,
    comment,
  } = vpnDetails;

  const supplierError =
    !supplier ||
    supplier?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const supplierHelperText = supplierError ? "Required/Invalid" : "";

  // console.log({ supplierError });

  const typeError =
    !type || type?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const typeHelperText = typeError ? "Required/Invalid" : "";

  // console.log({ typeError });

  const modelError =
    !model || model?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const modelHelperText = modelError ? "Required/Invalid" : "";

  // console.log({ modelError });

  const osError =
    !os || os?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const osHelperText = osError ? "Required/Invalid" : "";

  // console.log({ osError });

  const peerAddressError =
    !peerAddress ||
    peerAddress?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const peerAddressHelperText = peerAddressError ? "Required/Invalid" : "";

  // console.log({ peerAddressError });

  const testPeerAddressError =
    !testPeerAddress ||
    testPeerAddress?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const testPeerAddressHelperText = testPeerAddressError
    ? "Required/Invalid"
    : "";

  // console.log({ testPeerAddressError });

  const proposalNameError =
    !proposalname ||
    proposalname?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const proposalNameHelperText = proposalNameError ? "Required/Invalid" : "";

  // console.log({ proposalNameError });

  const authenticationModeError =
    !authenticationMode ||
    authenticationMode?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const authenticationModeHelperText = authenticationModeError
    ? "Required/Invalid"
    : "";

  // console.log({ authenticationModeError });

  const preSharedKeyError =
    !preSharedKey ||
    preSharedKey?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const preSharedKeyHelperText = preSharedKeyError ? "Required/Invalid" : "";

  // console.log({ preSharedKeyError });

  const authenticationAlgorithmError =
    !authenticationAlgorithm ||
    authenticationAlgorithm?.length >
      process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const authenticationAlgorithmHelperText = authenticationAlgorithmError
    ? "Required/Invalid"
    : "";

  // console.log({ authenticationAlgorithmError });

  const encryptionAlgorithmError =
    !encryptionAlgorithm ||
    encryptionAlgorithm?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const encryptionAlgorithmHelperText = encryptionAlgorithmError
    ? "Required/Invalid"
    : "";

  // console.log({ encryptionAlgorithmError });

  const diffieHellmanGroupError =
    !diffieHellmanGroup ||
    diffieHellmanGroup?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const diffieHellmanGroupHelperText = diffieHellmanGroupError
    ? "Required/Invalid"
    : "";

  // console.log({ diffieHellmanGroupError });

  const lifetimeError =
    !lifetime ||
    !(lifetime > 0) ||
    lifetime?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TEN;
  const lifetimeHelperText = lifetimeError
    ? "Required/Invalid/Should be greater than zero"
    : "";

  // console.log({ lifetimeError });

  const lifetimeMeasurementError =
    !lifetimeMeasurement ||
    !(lifetimeMeasurement > 0) ||
    lifetimeMeasurement?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TEN;
  const lifetimeMeasurementHelperText = lifetimeMeasurementError
    ? "Required/Invalid/Should be greater than zero"
    : "";

  // console.log({ lifetimeMeasurementError });

  const authenticationAlgorithmEspError =
    !authenticationAlgorithmEsp ||
    authenticationAlgorithmEsp?.length >
      process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const authenticationAlgorithmEspHelperText = authenticationAlgorithmEspError
    ? "Required/Invalid"
    : "";

  // console.log({ authenticationAlgorithmEspError });

  const encryptionAlgorithmAesError =
    !encryptionAlgorithmAes ||
    encryptionAlgorithmAes?.length >
      process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const encryptionAlgorithmAesHelperText = encryptionAlgorithmAesError
    ? "Required/Invalid"
    : "";

  // console.log({ encryptionAlgorithmAesError });

  const encapsulationModeEspError =
    !encapsulationModeEsp ||
    encapsulationModeEsp?.length >
      process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const encapsulationModeEspHelperText = encapsulationModeEspError
    ? "Required/Invalid"
    : "";

  // console.log({ encapsulationModeEspError });

  const perfectForwardSecrecyError =
    !perfectForwardSecrecy ||
    perfectForwardSecrecy?.length >
      process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const perfectForwardSecrecyHelperText = perfectForwardSecrecyError
    ? "Required/Invalid"
    : "";

  // console.log({ perfectForwardSecrecyError });

  const lifetimeEspError =
    !lifetimeEsp ||
    !(lifetimeEsp > 0) ||
    lifetimeEsp?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TEN;
  const lifetimeEspHelperText = lifetimeEspError
    ? "Required/Invalid/Should be greater than zero"
    : "";

  // console.log({ lifetimeEspError });

  const lifetimeMeasurementEspError =
    !lifetimeMeasurementEsp ||
    !(lifetimeMeasurementEsp > 0) ||
    lifetimeMeasurementEsp?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TEN;
  const lifetimeMeasurementEspHelperText = lifetimeMeasurementEspError
    ? "Required/Invalid/Should be greater than zero"
    : "";

  // console.log({ lifetimeMeasurementEspError });

  const networkError =
    !network || network?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const networkHelperText = networkError ? "Required/Invalid" : "";

  // console.log({ networkError });

  //   const commentError = !comment ||  comment?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_HUNDRED ;
  // const commentHelperText = commentError ? "Required/Invalid" : "";

  const handleInput = (event) => {
    // console.log({ isValidData });
    const name = event.target.name;
    const value = event.target.value;

    setVpnDetails({
      ...vpnDetails,
      [name]: value,
    });
    // console.log({ vpnDetails });
  };

  // const isEmpty = (data) => {
  //   return data == null || String(data).trim() === "";
  // };

  // let isValidData =
  //   !isEmpty(authenticationAlgorithm) &&
  //   !isEmpty(authenticationMode) &&
  //   !isEmpty(diffieHellmanGroup) &&
  //   !isEmpty(encryptionAlgorithm) &&
  //   !isEmpty(lifetime) &&
  //   !isEmpty(lifetimeMeasurement) &&
  //   !isEmpty(model) &&
  //   !isEmpty(os) &&
  //   !isEmpty(peerAddress) &&
  //   !isEmpty(preSharedKey) &&
  //   !isEmpty(proposalname) &&
  //   !isEmpty(supplier) &&
  //   !isEmpty(testPeerAddress) &&
  //   !isEmpty(type) &&
  //   !isEmpty(authenticationAlgorithmEsp) &&
  //   !isEmpty(encryptionAlgorithmAes) &&
  //   !isEmpty(perfectForwardSecrecy) &&
  //   !isEmpty(lifetimeMeasurementEsp) &&
  //   !isEmpty(lifetimeEsp) &&
  //   !isEmpty(network);

  let isValidData =
    !supplierError &&
    !typeError &&
    !modelError &&
    !osError &&
    !peerAddressError &&
    !testPeerAddressError &&
    !proposalNameError &&
    !authenticationModeError &&
    !authenticationAlgorithmError &&
    !encryptionAlgorithmError &&
    !encryptionAlgorithmAesError &&
    !diffieHellmanGroupError &&
    !lifetimeError &&
    !lifetimeMeasurementError &&
    !authenticationAlgorithmEspError &&
    !encapsulationModeEspError &&
    !perfectForwardSecrecyError &&
    !lifetimeEspError &&
    !lifetimeMeasurementEspError &&
    !networkError;

  useEffect(() => {
    if (vpnDetails) {
      setVpnDetails(vpnDetails);
    }
    // if (isValidData) {
    //   setVpnDetails({
    //     ...vpnDetails,
    //     vpnConfigurationFormStatus: isValidData ? "Complete" : "In Progress",
    //   });
    // }
  }, [vpnDetails]);

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const handleApiData = async (params) => {
    // console.log{params});
    const { data, dataName, severity, message } = params;
    if (severity === "success") {
      if (dataName === "vpnDetails") {
        // console.log({ data });
        setVpnDetails(data?.companyDetails?.vpnConfiguration);

        setVpnDtlsData(data);

        setSdpVpn({
          ...sdpVpn,
          vpn: data?.companyDetails?.vpnConfiguration,
          sdp: data,
        });

        if (isValidData) {
          // console.log({ vpnDetails });
          handleGoBack(vpnConfigurationFormStatus, data);
        }
      }
    }
    setState((prevState) => ({
      ...prevState,
      vpnConfigurationFormStatus: isValidData ? "Complete" : "In Progress",
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleSubmitVpnDetails = async () => {
    // console.log({ sdpVpn });
    // console.log({ vpnDetails });

    setSubmitDisabledButton(true);
    await makeApiRequest({
      uri: `/kyc/company/sdpDetails/${companyId}`,
      dataName: `vpnDetails`,
      method: `post`,
      data: {
        ...sdpVpn,
        companyId,
        vpn: {
          ...vpnDetails,
          vpnConfigurationFormStatus: isValidData ? "Complete" : "In Progress",
        },
      },
    });
    setSubmitDisabledButton(false);
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <Box sx={{ mt: 10 }} textAlign="left">
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <Stack direction="row" spacing={1}>
        <IconButton
          onClick={() => {
            console.log({ vpnDetails });
            handleGoBack(
              vpnConfigurationFormStatus,
              vpnDtlsData
              // "vpnConfiguration"
            );
          }}
          sx={{ mb: 4, mt: -0.5 }}
        >
          <ArrowBack />
        </IconButton>
        <Box>
          <Typography variant="h6" sx={{ fontWeight: "bold" }}>
            VPN Configuration Form{" "}
          </Typography>
        </Box>
      </Stack>
      <Box>
        <Typography sx={{ mb: 3, fontWeight: "bold", color: "#E62634" }}>
          Please Note: Kindly do not leave any field blank. Add N/A to any field
          that is not applicable
        </Typography>
        <Typography sx={{ mb: 3 }}>
          VPN stands for <b>"Virtual Private Network"</b> and describes the
          opportunity to establish a protected network connection when using
          public networks. VPNs encrypt your internet traffic and disguise your
          online identity. This makes it more difficult for third parties to
          track your activities online and steal data.
        </Typography>
      </Box>
      <Grid container sx={{ backgroundColor: "#159F4A", color: "#fff" }}>
        <Grid item xs={4}>
          <Typography sx={{ pl: 1 }}>VPN Information</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography>Your Information</Typography>
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>Safaricom</Typography>
        </Grid>
      </Grid>

      <Grid container sx={{ mt: 4 }}>
        <Grid item xs={4}>
          <Typography>Supplier:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={supplierError}
            helperText={supplierHelperText}
            label="Supplier"
            name="supplier"
            size="small"
            onChange={handleInput}
            value={vpnDetails.supplier}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>Cisco</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Type:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={typeError}
            helperText={typeHelperText}
            label="Type"
            name="type"
            size="small"
            onChange={handleInput}
            value={type}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>ASA 5540</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Model:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={modelError}
            helperText={modelHelperText}
            label="Model"
            name="model"
            size="small"
            onChange={handleInput}
            value={model}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography></Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>OS:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={osError}
            helperText={osHelperText}
            label="OS"
            name="os"
            size="small"
            onChange={handleInput}
            value={os}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography></Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={4}>
          <Typography>Peer Address:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={peerAddressError}
            helperText={peerAddressHelperText}
            label="Peer Address"
            name="peerAddress"
            size="small"
            onChange={handleInput}
            value={peerAddress}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>196.201.212.240</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Test Peer Address:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={testPeerAddressError}
            helperText={testPeerAddressHelperText}
            label="Test Peer Address"
            name="testPeerAddress"
            size="small"
            onChange={handleInput}
            value={testPeerAddress}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>196.201.212.240</Typography>
        </Grid>
      </Grid>
      <Divider />

      <Grid container sx={{ mt: 4 }}>
        <Grid item xs={4}>
          <Typography>Proposal Name:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={proposalNameError}
            helperText={proposalNameHelperText}
            label="Proposal Name"
            name="proposalname"
            size="small"
            onChange={handleInput}
            value={proposalname}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>IKE-3DES-SHA</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Authentication Mode:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={authenticationModeError}
            helperText={authenticationModeHelperText}
            label="Authentication Mode"
            name="authenticationMode"
            size="small"
            onChange={handleInput}
            value={authenticationMode}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>Pre-shared Key</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Pre-shared Key:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={preSharedKeyError}
            helperText={preSharedKeyHelperText}
            label="Pre-shared Key"
            name="preSharedKey"
            size="small"
            onChange={handleInput}
            value={preSharedKey}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>To Be Shared Later</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Authentication Algorithm:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={authenticationAlgorithmError}
            helperText={authenticationAlgorithmHelperText}
            label="Authentication Algorithm"
            name="authenticationAlgorithm"
            size="small"
            onChange={handleInput}
            value={authenticationAlgorithm}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>SHA</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Encryption Algorithm:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={encryptionAlgorithmError}
            helperText={encryptionAlgorithmHelperText}
            label="Encryption Algorithm"
            name="encryptionAlgorithm"
            size="small"
            onChange={handleInput}
            value={encryptionAlgorithm}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>3DES-168</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Diffie-Hellman Group:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={diffieHellmanGroupError}
            helperText={diffieHellmanGroupHelperText}
            label="Diffie-Hellman Group"
            name="diffieHellmanGroup"
            size="small"
            onChange={handleInput}
            value={diffieHellmanGroup}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>Group 2 (1024 bits)</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Lifetime Measurement:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            className={classes.input}
            error={lifetimeMeasurementError}
            helperText={lifetimeMeasurementHelperText}
            label="Lifetime Measurement"
            name="lifetimeMeasurement"
            size="small"
            type="number"
            onChange={handleInput}
            value={lifetimeMeasurement}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>Time</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Lifetime:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            className={classes.input}
            error={lifetimeError}
            helperText={lifetimeHelperText}
            label="Lifetime"
            name="lifetime"
            size="small"
            type="number"
            onChange={handleInput}
            value={lifetime}
            style={inputStyle}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>86400</Typography>
        </Grid>
      </Grid>
      <Divider />

      <Grid container sx={{ mt: 4 }}>
        <Grid item xs={4}>
          <Typography>Authentication Algorithm:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={authenticationAlgorithmEspError}
            helperText={authenticationAlgorithmEspHelperText}
            label="Authentication Algorithm"
            name="authenticationAlgorithmEsp"
            size="small"
            onChange={handleInput}
            style={inputStyle}
            value={authenticationAlgorithmEsp}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>ESP/SHA</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Encryption Algorithm:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={encryptionAlgorithmAesError}
            helperText={encryptionAlgorithmAesHelperText}
            label="Encryption Algorithm"
            name="encryptionAlgorithmAes"
            size="small"
            onChange={handleInput}
            style={inputStyle}
            value={encryptionAlgorithmAes}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>AES-128</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Encapsulation Mode:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={encapsulationModeEspError}
            helperText={encapsulationModeEspHelperText}
            label="Encapsulation Mode"
            name="encapsulationModeEsp"
            size="small"
            onChange={handleInput}
            style={inputStyle}
            value={encapsulationModeEsp}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>ESP tunnel</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Perfect Forward Secrecy:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={perfectForwardSecrecyError}
            helperText={perfectForwardSecrecyHelperText}
            label="Perfect Forward Secrecy"
            name="perfectForwardSecrecy"
            size="small"
            onChange={handleInput}
            style={inputStyle}
            value={perfectForwardSecrecy}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>Disabled</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Lifetime Measurement:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            className={classes.input}
            error={lifetimeMeasurementEspError}
            helperText={lifetimeMeasurementEspHelperText}
            label="Lifetime Measurement"
            name="lifetimeMeasurementEsp"
            size="small"
            type="number"
            onChange={handleInput}
            style={inputStyle}
            value={lifetimeMeasurementEsp}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>Time</Typography>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={4}>
          <Typography>Lifetime:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            className={classes.input}
            error={lifetimeEspError}
            helperText={lifetimeEspHelperText}
            label="Lifetime"
            name="lifetimeEsp"
            size="small"
            type="number"
            onChange={handleInput}
            style={inputStyle}
            value={lifetimeEsp}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography>28800</Typography>
        </Grid>
      </Grid>
      <Divider />

      <Grid container sx={{ mt: 4 }}>
        <Grid item xs={4}>
          <Typography>Network:</Typography>
        </Grid>
        <Grid item xs={4}>
          <TextField
            error={networkError}
            helperText={networkHelperText}
            label="Network"
            name="network"
            multiline
            onChange={handleInput}
            style={inputStyle}
            value={network}
            fullWidth
          />
        </Grid>
        <Grid item xs={4} sx={{ pl: 5 }}>
          <Typography sx={{ color: "#159F4A" }}>USSD</Typography>
          <Typography>Test USSD- 196.201.213.92</Typography>

          <Typography sx={{ mt: 2, color: "#159F4A" }}>
            Primary ussd sites
          </Typography>
          <Typography>196.201.213.81</Typography>
          <Typography>196.201.213.82</Typography>
          <Typography>196.201.213.83</Typography>

          <Typography sx={{ mt: 2, color: "#159F4A" }}>
            Secondary site
          </Typography>
          <Typography>196.201.213.85</Typography>
          <Typography>196.201.213.86</Typography>
          <Typography>196.201.213.87</Typography>

          <Typography sx={{ mt: 2, color: "#159F4A" }}>SDP IP</Typography>
          <Typography> Prod SDP - 196.201.214.81</Typography>
          <Typography> Test SDP - 196.201.214.82</Typography>
          <Typography> Port 8310</Typography>
          <Typography> Port 8310 </Typography>
        </Grid>
      </Grid>
      <Divider />

      <Grid container sx={{ mt: 4 }}>
        <Grid item xs={12}>
          <TextField
            label="Comments"
            name="comment"
            multiline
            onChange={handleInput}
            style={inputStyle}
            value={comment}
            fullWidth
          />
        </Grid>
      </Grid>

      <Button
        variant="contained"
        style={isSubmitDisabledButton ? disabledButton : primaryButton}
        onClick={() => handleSubmitVpnDetails()}
        disabled={isSubmitDisabledButton}
        disableElevation
      >
        {isValidData ? "Save & Go Back" : "Save"}
      </Button>

      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
