import {
  Box,
  Card,
  CardActions,
  CardContent,
  Container,
  Divider,
  Grid,
  Link,
  Typography,
  Backdrop,
  CircularProgress
} from "@mui/material";
import React, { useEffect, useState } from "react";
import customLocalStorage from "../services/local-storage";
import ApplicationFormPage from "./application-form/csp-application-form";
import apiRequest from "../services/api-request";

export default function PartnerLanding() {
  // const role = 'vas-account-manager';

  const { firstName, lastName, userId } =
    customLocalStorage.decrypt("cpoUser") || {};

  const initialState = {
    loading: false,
    applications: [],
    companyId: "",
    componentHasError: false,
    currentPage: "landingPage",
  };

  const [state, setState] = useState(initialState);

  const {
    loading,
    componentHasError,
    applications,
    currentPage,
  } = state;

  const handleApiData = (params) => {
    // console.log{params});

    const { data, dataName, severity, message } = params;

    setState((prevState) => ({
      ...prevState,
      componentHasError:
        dataName === "applications" && !(severity === "success"),
      [dataName]: data,
      companyId: data[0]?.company?.companyId,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleGetApplications = async () => {
    handleLoader(true);
    await makeApiRequest({
      uri: `/home/${userId}`,
      dataName: `applications`,
      method: `get`,
      data: "",
    });
    handleLoader(false);
  };

  useEffect(() => {
    (async () => {
      await handleGetApplications();
    })();
  }, []);

  // const toggleLandingPage = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     isLandingPage: !prevState.isLandingPage,
  //   }));
  // };

  const toggleLandingPage = (params) => {
    const { currentPage, currentTask } = params;
    setState((prevState) => ({
      ...prevState,
      currentPage
    }));
  };

  return (
    <>
      {(!componentHasError || loading) && currentPage === "landingPage" && (
        <Box sx={{ height: "100vh" }}>
           <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
          <CircularProgress color="success" />
        </Backdrop>

          <Container sx={{ ml: 1, mt: 3 }}>
            <Typography
              variant="h4"
              textAlign="left"
              sx={{ fontWeight: "bold", mb: 1 }}
            >
              Applications
            </Typography>
            <Typography textAlign="left">
              Welcome {firstName} {lastName}
            </Typography>
          </Container>
          <Divider sx={{ mt: 1, ml: 4 }} />

          {/*<Stack direction="row" spacing={5} sx={{ml: 5, mt: 4}}>*/}

          <Grid container sx={{ p: 4 }} spacing={2}>
            <Grid item xs={12} sm={4}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h5"
                    component="div"
                    align="left"
                    sx={{ pb: 2, fontWeight: "bold", color: "#159F4A" }}
                  >
                    Content Service Provider
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    align="left"
                  >
                    Make application to provide this service.
                  </Typography>
                </CardContent>
                <CardActions align="right">
                  <Link
                    sx={{
                      color: "#159F4A",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: 16,
                      pb: 3,
                      pl: 1,
                    }}
                    onClick={() =>
                      toggleLandingPage({
                        currentPage: "cspApplication",
                      })
                    }
                  >
                    GO TO APPLICATION
                  </Link>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h5"
                    component="div"
                    align="left"
                    sx={{ pb: 2, fontWeight: "bold", color: "#159F4A" }}
                  >
                    Skiza Partner
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    align="left"
                  >
                    Make application to provide this service.
                  </Typography>
                </CardContent>
                <CardActions align="right">
                  <Link
                    disabled
                    sx={{
                      color: "#159F4A",
                      cursor: "pointer",
                      fontWeight: "bold",
                      textDecoration: "none",
                      fontSize: 16,
                      pb: 3,
                      pl: 1,
                    }}
                  >
                    COMING SOON
                  </Link>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Card>
                <CardContent>
                  <Typography
                    variant="h5"
                    component="div"
                    align="left"
                    sx={{ pb: 2, fontWeight: "bold", color: "#159F4A" }}
                  >
                    Video Content Partner
                  </Typography>
                  <Typography
                    sx={{ mb: 1.5 }}
                    color="text.secondary"
                    align="left"
                  >
                    Make application to provide this service.
                  </Typography>
                </CardContent>
                <CardActions align="right">
                  <Link
                    disabled
                    sx={{
                      color: "#159F4A",
                      cursor: "pointer",
                      fontWeight: "bold",
                      textDecoration: "none",
                      fontSize: 16,
                      pb: 3,
                      pl: 1,
                    }}
                  >
                    COMING SOON
                  </Link>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Box>
      )}
      {currentPage === "cspApplication" && (
        <ApplicationFormPage
          toggleLandingPage={toggleLandingPage}
          applications={applications}
          componentHasError={componentHasError}
          handleGetApplications={handleGetApplications}
        />
      )}

      {/*{ role == 'vas-account-manager' &&*/}
      {/*    <VasAccountManager></VasAccountManager>*/}
      {/*}*/}
      {/*{role == 'finance' &&*/}
      {/*<Finance></Finance>*/}
      {/*}*/}
    </>
  );
}
