import * as React from "react";
import {
  Box,
  Button,
  Stack,
  Typography,
  Snackbar,
  Alert,
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import { primaryButton, numberContainer } from "../../common-styles";
import { AddCircle } from "@mui/icons-material";
import { useEffect, useState } from "react";
import apiRequest from "../services/api-request";
import AddUser from "./add-user";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import customLocalStorage from "../services/local-storage";
import PropTypes from "prop-types";
import AddTaskAssignee from "./add-task-assignee";
import UtilizationReport from "./utilization-report/utilization-report";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SuperAdminLanding() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { firstName, lastName } = customLocalStorage.decrypt("cpoUser") || {};

  const initialState = {
    users: [],
    tasks: [],
    currentUser: {},
    allRoles: [],
    currentAssignedTask: {},
    currentPage: "pendingTaskList",
    loading: false,
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,

    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
  };

  const [state, setState] = useState(initialState);

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const {
    action,
    currentUser,
    tasks,
    users,
    allRoles,
    currentPage,
    loading,
    currentAssignedTask,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarVerticalPosition,
    snackBarHorizontalPosition,
  } = state;

  const handleApiData = (params) => {
    // console.log{params});
    const { severity, message, data, dataName } = params;
    setState((prevState) => ({
      ...prevState,
      [dataName]: data,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      handleLoader,
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  useEffect(() => {
    const page = 1;
    const limit = 500;
    makeApiRequest({
      uri: `/users?page=${page}&limit=${limit}`,
      dataName: `users`,
      method: `get`,
      data: {},
    });
    makeApiRequest({
      uri: `/users/getRoles`,
      dataName: `allRoles`,
      method: `get`,
      data: {},
    });
    makeApiRequest({
      uri: `/tickets?page=${page}&limit=${limit}`,
      dataName: `tasks`,
      method: `get`,
      data: {},
    });
  }, []);

  const userCount = users?.length;
  const taskCount = tasks?.length;

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  const userColumns = [
    {
      name: "userId",
      label: "User ID",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "firstName",
      label: "First Name",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "lastName",
      label: "Last Name",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "email",
      label: "Email Address",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "msisdn",
      label: "Phone Number",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "roles",
      label: "Roles",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                {value.map((role) => {
                  return (
                    <Typography sx={{ fontSize: 15 }} key={role?.name}>
                      {role?.name}
                    </Typography>
                  );
                })}
              </Stack>
            </>
          );
        },
      },
    },
  ];

  const taskColumns = [
    {
      name: "wfId",
      label: "Task Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "wfCurrTrans",
      label: "Company ID",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.companyId}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Company Registration Number",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.companyRegNumber}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Registered Company Name",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.registeredCompanyName}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfTaskName",
      label: "Task Name",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "wfActiveProcess",
      label: "Active Stage",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "wfCreatedDate",
      label: "Date Created",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {new Date(value).toLocaleDateString()}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfId",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          const currentAssignedTask = tasks?.filter(
            (task) => task?.wfId == value
          )[0];
          return (
            <>
              {!(
                currentAssignedTask?.wfActiveProcess == "self_onboarding_task"
              ) &&
                !(
                  currentAssignedTask?.wfActiveProcess ==
                  "task_upload_payment_receipts"
                ) &&
                !(
                  currentAssignedTask?.wfActiveProcess ==
                  "task_fill_sdp_details"
                ) && (
                  <Button
                    style={primaryButton}
                    onClick={() =>
                      toggleTaskPage({
                        currentPage: "taskDetails",
                        currentAssignedTask: currentAssignedTask,
                      })
                    }
                  >
                    REASSIGN TASK
                  </Button>
                )}
            </>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    download: false,
    jumpToPage: true,
    onRowClick: (_rowData, rowMeta) => {
      if (rowMeta?.dataIndex >= 0) {
        const currentUser = {
          ...users[rowMeta?.dataIndex],
        };
        setState((prevState) => ({
          ...prevState,
          currentUser,
          action: "Update",
        }));
      }
    },
    onRowsDelete: (rowsDeleted) => {
      if (!window.confirm(`Are you sure you wish to delete this item?`))
        return false;
      if (Array.isArray(rowsDeleted?.data) && rowsDeleted?.data?.length > 0) {
        rowsDeleted.data.forEach((dataItem, index) => {
          const user = users[dataItem.dataIndex];
          if (user?.id) {
            (async () => {
              await makeApiRequest({
                uri: `/users/${user?.id}`,
                dataName: `deletedUser`,
                method: `delete`,
                data: {},
              });
              if (index === rowsDeleted.data.length - 1)
                await makeApiRequest({
                  uri: `/users/all`,
                  dataName: `allUsers`,
                  method: `get`,
                  data: {},
                });
            })();
          }
        });
      }
    },
  };

  const toggleTaskPage = (params) => {
    const { currentPage, currentAssignedTask } = params;
    setState((prevState) => ({
      ...prevState,
      currentPage,
      currentAssignedTask,
    }));
  };

  const handleAddUser = () => {
    setState((prevState) => ({
      ...prevState,
      action: "Add",
    }));
  };

  const handleCloseDialog = () => {
    setState((prevState) => ({
      ...prevState,
      action: "Close",
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ p: 3 }}>
        <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
          <CircularProgress color="success" />
        </Backdrop>

        <Typography
          variant="h5"
          textAlign="left"
          sx={{ fontWeight: "bold", pb: 2 }}
        >
          Manage Users
        </Typography>
        <Typography textAlign="left" sx={{ mb: 2 }}>
          Welcome {firstName} {lastName}
        </Typography>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Users" {...a11yProps(0)} />
            <Tab label="Task Assignment" {...a11yProps(1)} />
            <Tab label="Utilization Report" {...a11yProps(2)} />

          </Tabs>
        </Box>

        <TabPanel value={value} index={0}>
          <Box sx={{ mt: 1, mb: 3 }}>
            <Typography
              variant="h6"
              textAlign="left"
              sx={{ fontWeight: "bold" }}
            >
              <span>Users</span>
              <span style={numberContainer}>{userCount}</span>
            </Typography>
          </Box>

          <Box>
            {(action === "Add" || action === "Update") && (
              <AddUser
                action={action}
                users={users}
                allRoles={allRoles}
                handleCloseDialog={handleCloseDialog}
                currentUser={currentUser}
              />
            )}
          </Box>
          <MUIDataTable
            sx={{ p: 3 }}
            title={
              <Button
                style={primaryButton}
                onClick={() => {
                  handleAddUser(`Add`);
                }}
                startIcon={<AddCircle />}
              >
                New User
              </Button>
            }
            data={users}
            columns={userColumns}
            options={options}
          />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box sx={{ mt: 1, mb: 3 }}>
            <Typography
              variant="h6"
              textAlign="left"
              sx={{ fontWeight: "bold" }}
            >
              <span>Tasks</span>
              <span style={numberContainer}>{taskCount}</span>
            </Typography>
          </Box>

          {currentPage == "pendingTaskList" && (
            <MUIDataTable
              sx={{ p: 3 }}
              title="Task List"
              data={tasks}
              columns={taskColumns}
              options={options}
            />
          )}
          {currentPage == "taskDetails" && (
            <AddTaskAssignee
              currentAssignedTask={currentAssignedTask}
              toggleTaskPage={toggleTaskPage}
            />
          )}
        </TabPanel>
        <TabPanel value={value} index={2}>
          <UtilizationReport/>
          </TabPanel>

      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
