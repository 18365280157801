import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { formTitleStyle, formSubtitleStyle } from "../formStyles";
import { primaryButton } from "../../../../common-styles";

export default function CspAppointmentInstructions(props) {
  const { handleNext } = props;

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Box sx={{ pt: 5, pl: 5 }}>
        {/*<Typography textAlign="left" style={formTitleStyle}>PART A</Typography>*/}
        <Typography textAlign="left" style={formSubtitleStyle}>
          Appointment Process Instructions
        </Typography>
        <Typography textAlign="left" sx={{ mb: 5 }}>
          Read the following instructions carefully
        </Typography>
        <Typography textAlign="left" style={formTitleStyle}>
          SAFARICOM PRS APPOINTMENT PROCESS
        </Typography>
        <Typography textAlign="left">
          i. Submit application form and all documents scanned copies on this
          platform. Contact <b>prspsupport@safaricom.co.ke </b> if you have any
          questions or require any assistance
          <Typography>
            {" "}
            ii. Safaricom through an assigned account manager will start the
            process and issue an NDA (Non-Disclosure Agreement) for execution
            which is required to continue the onboarding process.
          </Typography>
          <Typography>
            iii. Safaricom will start contracting once signed NDA is returned to
            account manager.
          </Typography>
          <Typography>
            iv. Safaricom will then issue an appointment contract through the
            account manager
          </Typography>
          <Typography>
            v. Applicant will return contract together and will be issued with a
            profoma invoice for payment of the initial connection fee of{" "}
            <b>KShs 87,000/-</b>
          </Typography>
          <Typography>
            vi. Return of the signed contract and clearing of the initial fee
            payment cheque will allow Safaricom to create partner onto the
            various systems and integration can begin.
          </Typography>
          <Typography>
            vii. On full integration we will have a training session to accustom
            the applicant with the systems needed through our consumer product
            support team
          </Typography>
        </Typography>

        <Typography textAlign="left" style={formTitleStyle} sx={{ mt: 4 }}>
          TECHNICAL REQUIREMENTS
        </Typography>

        <Typography textAlign="left">
          I. Point to point link with Safaricom at own cost. (VPN also available
          on request)
        </Typography>
        <Typography textAlign="left">
          ii. Offer minimum capacity of 40 sms’s per second
        </Typography>
        <Typography textAlign="left">
          iii. Safaricom approved 3rd party content software and hardware
          specifications
        </Typography>
        <Typography textAlign="left">
          iv. Technical and Customer Support center team available 24 hours via{" "}
          <b>prstechsupport@safaricom.co.ke</b>
        </Typography>

        <Typography textAlign="left" style={formTitleStyle} sx={{ mt: 4 }}>
          FINANCIAL REQUIREMENTS
        </Typography>

        <Typography textAlign="left">
          i. Initial setup fee of <b>Ksh. 87,000/- inclusive of tax</b>
        </Typography>
        <Typography textAlign="left">
          ii. Monthly short code fee of <b>Ksh. 11,600 inclusive of tax</b>
        </Typography>
        <Typography textAlign="left">
          iii. One off golden number(easy to remember numbers) fee of{" "}
          <b>Ksh. 200,000/=</b>
        </Typography>

        <Typography textAlign="left">
          NB: This must be returned with the signed contract
        </Typography>

        <Typography textAlign="left" style={formTitleStyle} sx={{ mt: 4 }}>
          REVENUE SHARE/PAYMENT METHODOLOGY
        </Typography>
        <Typography textAlign="left">
          The premium rate services attracts a charge above the normal sms/call
          charge rate.
        </Typography>
        <Typography textAlign="left">
          The revenue generated is therefore split between the network operator
          and the PRS provider
        </Typography>
        <Typography textAlign="left">
          In cases where the PRS provider is running a campaign on behalf of a
          3rd party, they too get revenue share based on the agreement with the
          PRS provider.
        </Typography>
        <Typography textAlign="left">
          The revenue share varies from service to service and is exclusive of
          government taxes and VAT.
        </Typography>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
        <Box sx={{ flex: "1 1 auto" }} />
        <Button
          variant="contained"
          style={primaryButton}
          onClick={() => handleNext()}
          disableElevation
        >
          Start Application
        </Button>
      </Box>
    </Box>
  );
}
