import "./App.css";
import * as React from "react";
import Home from "./components/content-partner-onboarding/navigation/home";
import CacheBuster from "./CacheBuster";

function App() {
  return (
      <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }
        return <Home />;
      }}
    </CacheBuster>
  );
}

export default App;
