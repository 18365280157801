import * as React from "react";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Grid,
  CircularProgress,
  Typography,
  Divider,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import apiRequest from "../services/api-request";
import { primaryButton, disabledButton } from "../../common-styles";
import { formSubtitleStyle } from "../partner/application-form/formStyles";
import VpnConfigurationForm from "./application-form/form-parts/vpn-configuration-form";
import SdpDetailsForm from "./application-form/form-parts/sdp-testing-prod-details-form";

export default function SdpApplicationFormPage(props) {
  const { handleNext, currentTask } = props;

  const { wfId } = currentTask;

  const { companyId } = currentTask?.wfCurrTrans;

  const initialState = {
    currentSection: "A1",
    currentSelection: "SDPHome",
    vpnConfigurationFormStatus: "Not Started",
    sdpDetailsFormStatus: "Not Started",
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,
    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
    loading: false,
  };

  const [state, setState] = useState(initialState);

  let [sdpVpn, setSdpVpn] = useState({
    vpn: currentTask?.wfCurrTrans?.vpnConfiguration,
    companyId: companyId,
  });

  const {
    currentSection,
    currentSelection,
    vpnConfigurationFormStatus,
    sdpDetailsFormStatus,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
    loading,
  } = state;

  const handleOpen = (currentSelection) => {
    setState((prevState) => ({
      ...prevState,
      currentSelection,
    }));
  };

  const handleGoBack = (sdpStatus, data) => {
    setState((prevState) => ({
      ...prevState,
      currentSelection: "SDPHome",
      vpnConfigurationFormStatus: sdpStatus,
    }));

    setSdpVpn({
      ...sdpVpn,
      vpn: data?.companyDetails?.vpnConfiguration,
      sdp: data,
    });
  };

  const statusStyle = (status) => {
    return status === "Not Started"
      ? { color: "red" }
      : status === "In Progress"
      ? { color: "#FFCC00" }
      : status === "Complete"
      ? { color: "#159F4A" }
      : { color: "#121212" };
  };

  const isValidData =
    vpnConfigurationFormStatus === "Complete" &&
    sdpDetailsFormStatus === "Complete";

  const handleAccordionChange = (currentSection) => {
    setState((prevState) => ({
      ...prevState,
      currentSection,
    }));
  };

  const handleApiData = (params) => {
    // console.log{params});
    let { data, dataName, severity, message } = params;

    // console.log({ data });
    // console.log({ severity });
    // console.log({ message });

    if (severity === "success") {
      if (dataName === "sdpVpn") {
        // console.log({ data });
        setSdpVpn({
          ...sdpVpn,
          sdp: data,
        });
        // console.log({ sdpVpn });
      }

      if (wfId && isValidData) {
        handleNext();
      }
    }
    if (
      severity === "error" &&
      message === "SDP Details have not been defined!"
    ) {
      setSdpVpn({
        ...sdpVpn,
        sdp: {},
      });
      severity = "warning";
      // console.log("sdp details have not been defined");
      // console.log({ sdpVpn });
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);

    const { uri, dataName, method, data } = params;

    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };

    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleGetSdpDetails = async () => {
    await makeApiRequest({
      uri: `/kyc/company/sdpDetails/${companyId}`,
      dataName: `sdpVpn`,
      method: `get`,
      data: "",
    });
  };

  const getSdpStatus = (sdpConfig) => {
    const properties = [
      sdpConfig?.technicalEmail,
      sdpConfig?.skypeId,
      sdpConfig?.phoneNumber,
      sdpConfig?.applicationSourceIp,
      sdpConfig?.businessEmail,
    ];
    const arrays = [
      sdpConfig?.sdpServices,
      sdpConfig?.businessPhones,
      sdpConfig?.whitelistIps,
    ];

    if (properties.every(Boolean) && arrays.every((arr) => arr?.length > 0)) {
      return "Complete";
    } else if (
      properties.some(Boolean) ||
      arrays.some((arr) => arr?.length > 0)
    ) {
      return "In Progress";
    } else {
      return "Not Started";
    }
  };

  useEffect(() => {
    (async () => {
      await handleGetSdpDetails();
    })();
    // console.log({ sdpVpn });
  }, []);

  useEffect(() => {
    if (sdpVpn) {
      let vpnConfig = sdpVpn?.vpn;
      let sdpConfig = sdpVpn?.sdp;
      setState((prevState) => ({
        ...prevState,
        vpnConfigurationFormStatus:
          vpnConfig?.vpnConfigurationFormStatus ?? "Not Started",
        sdpDetailsFormStatus: getSdpStatus(sdpConfig),
      }));
      // console.log(vpnConfig);
    }
    // console.log({ sdpVpn });

    // console.log({ sdpVpn });
  }, [sdpVpn]);

  const handleCompleteSdpProcess = async () => {
    await makeApiRequest({
      uri: `/tickets/complete-task-update-sdp-application`,
      dataName: `completeApplication`,
      method: `post`,
      data: {
        approved: true,
        comment: "Application complete",
        wfId: wfId,
      },
    });
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      {currentSelection === "SDPHome" && (
        <Box sx={{ flexGrow: 1, p: 2, mt: 5, backgroundColor: "#f9f9f9" }}>
          <Grid container direction="row" alignItems="center">
            <Typography
              variant="h4"
              textAlign="left"
              sx={{ fontWeight: "bold" }}
            >
              Service Delivery Platform Onboarding Application Form
            </Typography>

            <Divider />
          </Grid>

          <Box sx={{ mt: 4 }}>
            <Accordion
              expanded={currentSection === "A1"}
              onChange={() => handleAccordionChange("A1")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography textAlign="left" style={formSubtitleStyle}>
                  1: VPN Configuration Form
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <TableContainer sx={{ mt: 1, ml: 3 }}>
                  <Typography textAlign="left" sx={{ pb: 3 }}>
                    Kindly fill in the details in all the form below
                  </Typography>
                  <Table sx={{ width: "95%" }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Application
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Status
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          VPN Configuration Form
                        </TableCell>
                        <TableCell>FORM</TableCell>
                        <TableCell>
                          <Typography
                            style={statusStyle(vpnConfigurationFormStatus)}
                          >
                            {vpnConfigurationFormStatus}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            style={primaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() => handleOpen("vpnConfiguration")}
                          >
                            APPLY
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Accordion
              expanded={currentSection === "A2"}
              onChange={() => handleAccordionChange("A2")}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
              >
                <Typography textAlign="left" style={formSubtitleStyle}>
                  2: SDP Details Form
                </Typography>
              </AccordionSummary>

              <AccordionDetails>
                <TableContainer sx={{ mt: 1, ml: 3 }}>
                  <Typography textAlign="left" sx={{ pb: 3 }}>
                    Kindly fill in the details in all the form below
                  </Typography>
                  <Table sx={{ width: "95%" }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Application
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Status
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          SDP Details Form
                        </TableCell>
                        <TableCell>FORM</TableCell>
                        <TableCell>
                          <Typography style={statusStyle(sdpDetailsFormStatus)}>
                            {sdpDetailsFormStatus}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            style={primaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() => handleOpen("sdpDetailsForm")}
                          >
                            APPLY
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </AccordionDetails>
            </Accordion>

            <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                variant="contained"
                style={isValidData ? primaryButton : disabledButton}
                onClick={() => {
                  handleCompleteSdpProcess();
                }}
                disableElevation
                disabled={!isValidData}
              >
                SUBMIT SDP APPLICATION
              </Button>
            </Box>
          </Box>

          <Snackbar
            anchorOrigin={{
              vertical: snackBarVerticalPosition,
              horizontal: snackBarHorizontalPosition,
            }}
            open={snackbarOpen}
            autoHideDuration={snackBarAutoHideDuration}
            onClose={() => snackBarHandle(false)}
          >
            <Alert
              onClose={handleClose}
              severity={snackBarSeverity}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      )}

      {currentSelection === "vpnConfiguration" && (
        <VpnConfigurationForm
          handleGoBack={handleGoBack}
          sdpVpn={sdpVpn}
          currentTask={currentTask}
        />
      )}
      {currentSelection === "sdpDetailsForm" && (
        <SdpDetailsForm
          handleGoBack={handleGoBack}
          sdpVpn={sdpVpn}
          currentTask={currentTask}
        />
      )}
    </>
  );
}
