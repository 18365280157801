import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Link,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import {
  inputStyle,
  formTitleStyle,
  formSubtitleStyle,
  keyContactStyle,
} from "../formStyles";
import {
  primaryButton,
  disabledButton,
  secondaryButton,
} from "../../../../common-styles";
import apiRequest from "../../../services/api-request";
import validator from "validator";
import * as Email from "email-validator";

export default function PartA(props) {
  const { handleCompanyData, handleNext, handleBack, applications } = props;

  delete applications[0]?.company?.application;

  const initialState = {
    registeredCompanyName: "",
    physicalLocation: "",
    companyId: "",
    companyMobileNumber: "",
    companyPinNumber: "",
    companyEmailAddress: "",
    companyVatNumber: "",
    companyRegNumber: "",
    companyTelephoneNumber: "",
    companyWebsiteUrl: "",
    keyContacts: [],
    keyContactCheckbox: "Business Lead",
    currentSection: "A1",
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,
    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
    loading: false,
    currentKeyContact: {
      name: "",
      email: "",
      mobileNumber: "",
    },
    keyContactPhoneNumberError: false,
    keyContactPhoneNumberHelperText: "",
    keyContactEmailError: false,
    keyContactEmailHelperText: "",
  };

  const [state, setState] = useState(initialState);

  const {
    currentKeyContact,
    keyContactCheckbox,
    currentSection,
    registeredCompanyName,
    companyEmailAddress,
    physicalLocation,
    companyId,
    companyRegNumber,
    companyWebsiteUrl,
    companyTelephoneNumber,
    companyMobileNumber,
    companyPinNumber,
    companyVatNumber,
    keyContacts,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
    loading,
  } = state;

  const { name, email, mobileNumber } = currentKeyContact;

  const registeredCompanyNameError =
    !registeredCompanyName ||
    !validator.isAlpha(registeredCompanyName, ["en-US"], { ignore: " -,'" }) ||
    registeredCompanyName?.length >
      process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const registeredCompanyNameHelperText = registeredCompanyNameError
    ? "Required/Invalid"
    : "";

  const companyRegNumberError =
    !companyRegNumber ||
    !validator.isAlphanumeric(companyRegNumber, ["en-US"], { ignore: " -/" }) ||
    companyRegNumber?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const companyRegNumberHelperText = companyRegNumberError
    ? "Required/Invalid"
    : "";

  const physicalLocationError =
    !physicalLocation ||
    !validator.isAlphanumeric(physicalLocation, ["en-US"], {
      ignore: " -,'",
    }) ||
    physicalLocation?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const physicalLocationHelperText = physicalLocationError
    ? "Required/Invalid"
    : "";

  const companyEmailAddressError =
    !companyEmailAddress || !Email.validate(companyEmailAddress);
  const companyEmailAddressHelperText = companyEmailAddressError
    ? "Required/Should contain company domain name/"
    : "";

  const companyWebsiteUrlError =
    !companyWebsiteUrl || !validator.isURL(companyWebsiteUrl);
  const companyWebsiteUrlHelperText = companyWebsiteUrlError
    ? "Required/Invalid"
    : "";

  const companyTelephoneNumberError =
    !companyTelephoneNumber ||
    !(companyTelephoneNumber > 0) ||
    companyTelephoneNumber?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TEN;
  const companyTelephoneNumberHelperText = companyTelephoneNumberError
    ? "Required/Invalid"
    : "";

  // const companyMobileNumberError =
  //   !companyMobileNumber ||
  //   !validator.isMobilePhone(companyMobileNumber, ["en-KE"]);
  // const companyMobileNumberHelperText = companyMobileNumberError
  //   ? "Required/Invalid/0xxxxxxxx"
  //   : "";

  const companyMobileNumberError =
    !companyMobileNumber ||
    !(companyMobileNumber > 0) ||
    !validator.isNumeric(companyMobileNumber.toString(), {
      no_symbols: true,
    }) ||
    companyMobileNumber?.length >
      process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTEEN;
  const companyMobileNumberHelperText = companyMobileNumberError
    ? "Required/Invalid/Numbers only"
    : "";

  const companyPinNumberError =
    !companyPinNumber ||
    !validator.isAlphanumeric(companyPinNumber) ||
    companyPinNumber?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const companyPinNumberHelperText = companyPinNumberError
    ? "Required/Invalid"
    : "";

  const companyVatNumberError =
    !companyVatNumber ||
    !validator.isAlphanumeric(companyVatNumber) ||
    companyVatNumber?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const companyVatNumberHelperText = companyVatNumberError
    ? "Required/Invalid"
    : "";

  const keyContactEmailError = !email || !Email.validate(email);
  const keyContactEmailHelperText = keyContactEmailError
    ? "Required/Should contain company domain name/"
    : "";

  const keyContactPhoneNumberError =
    !mobileNumber ||
    !(mobileNumber > 0) ||
    !validator.isNumeric(mobileNumber.toString(), {
      no_symbols: true,
    }) ||
    mobileNumber?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTEEN;

  const keyContactPhoneNumberHelperText = keyContactPhoneNumberError
    ? "Required/Invalid/Numbers only"
    : "";

  const isValidKeyContacts = () => {
    let isValid = false;

    for (let index = 0; index < keyContacts.length; index++) {
      const keyContact = keyContacts[index];

      const keyContactNameError =
        !keyContact?.name ||
        !validator.isAlpha(keyContact?.name, ["en-US"], { ignore: " -'" }) ||
        name?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;

      const keyContactEmailError =
        !keyContact?.email || !Email.validate(keyContact?.email);

      // const keyContactPhoneNumberError =
      //   !keyContact?.mobileNumber ||
      //   !validator.isMobilePhone(keyContact?.mobileNumber, ["en-KE"]);

      const keyContactPhoneNumberError =
        !keyContact?.mobileNumber ||
        !(keyContact?.mobileNumber > 0) ||
        !validator.isNumeric(keyContact?.mobileNumber.toString(), {
          no_symbols: true,
        }) ||
        keyContact?.mobileNumber?.length >
          process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTEEN;

      isValid =
        !keyContactNameError &&
        !keyContactEmailError &&
        !keyContactPhoneNumberError;

      if (!isValid) {
        break;
      }
    }
    return isValid;
  };

  const isValidData =
    !registeredCompanyNameError &&
    !companyRegNumberError &&
    !companyVatNumberError &&
    !physicalLocationError &&
    !companyEmailAddressError &&
    !companyWebsiteUrlError &&
    !companyTelephoneNumberError &&
    !companyMobileNumberError &&
    !companyPinNumberError &&
    isValidKeyContacts();

  const handleInput = (event) => {
    const name = event?.target?.name;
    let value = event?.target?.value;

    let {
      keyContacts,
      registeredCompanyNameError,
      registeredCompanyNameHelperText,
      companyRegNumberError,
      companyRegNumberHelperText,
      physicalLocationError,
      physicalLocationHelperText,
      companyEmailAddressError,
      companyEmailAddressHelperText,
      keyContactPhoneNumberError,
      keyContactPhoneNumberHelperText,
      keyContactEmailError,
      keyContactEmailHelperText,
    } = state;

    if (currentSection === "A2") {
      let businessLead = keyContacts?.length > 0 ? keyContacts[0] : {};
      if (keyContactCheckbox === "Business Lead") {
        businessLead = {
          ...businessLead,
          [name]: value,
          type: keyContactCheckbox,
        };
      }
      let financeLead = keyContacts?.length > 1 ? keyContacts[1] : {};
      if (keyContactCheckbox === "Finance Lead") {
        financeLead = {
          ...financeLead,
          [name]: value,
          type: keyContactCheckbox,
        };
      }
      let itLead = keyContacts?.length > 2 ? keyContacts[2] : {};
      if (keyContactCheckbox === "IT Lead") {
        itLead = { ...itLead, [name]: value, type: keyContactCheckbox };
      }
      let admin = keyContacts?.length > 3 ? keyContacts[3] : {};
      if (keyContactCheckbox === "Admin") {
        admin = { ...admin, [name]: value, type: keyContactCheckbox };
      }
      keyContacts = [businessLead, financeLead, itLead, admin];

      setState((prevState) => ({
        ...prevState,
        keyContacts,
        currentKeyContact: { ...prevState.currentKeyContact, [name]: value },
      }));

      return;
    }

    setState((prevState) => ({
      ...prevState,
      [name]: value,
      registeredCompanyNameError,
      registeredCompanyNameHelperText,
      companyRegNumberError,
      companyRegNumberHelperText,
      physicalLocationError,
      physicalLocationHelperText,
      companyEmailAddressError,
      companyEmailAddressHelperText,
      keyContactPhoneNumberError,
      keyContactPhoneNumberHelperText,
      keyContactEmailError,
      keyContactEmailHelperText,
    }));
  };

  const handleCheckbox = (keyContactCheckbox) => {
    setState((prevState) => ({
      ...prevState,
      keyContactCheckbox,
      currentKeyContact: {},
    }));
  };

  const handleAccordionChange = (currentSection) => {
    setState((prevState) => ({
      ...prevState,
      currentSection,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const handleApiData = async (params) => {
    // console.log{params});
    const { data, severity, message } = params;

    // console.log({ data });

    if (severity === "success") {
      const { companyId } = data;

      // console.log({companyId})

      if (isValidData) {
        if (companyId) {
          await props.getCompany(data);
          await handleNext();
        }
      }
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleCompany = async () => {
    const action = companyId ? "update" : "create";
    let uri = `/kyc/company`;
    let method = `post`;
    if (action === "update") {
      uri = `/kyc/company/${companyId}`;
      method = `put`;
    }
    await makeApiRequest({
      uri,
      dataName: `createdCompany`,
      method,
      data: {
        ...applications[0]?.company,
        registeredCompanyName,
        companyRegNumber,
        companyId,
        companyEmailAddress,
        physicalLocation,
        companyWebsiteUrl,
        companyTelephoneNumber,
        companyMobileNumber,
        companyPinNumber,
        companyVatNumber,
        keyContacts,
        applicationComplete: false,
      },
    });
  };

  useEffect(() => {
    // const { applications } = props;
    // console.log{applications});
    // console.logapplications[0]);
    // console.logapplications[0]?.company);
    if (applications[0]?.company) {
      const {
        companyId,
        registeredCompanyName,
        companyEmailAddress,
        physicalLocation,
        companyRegNumber,
        companyWebsiteUrl,
        companyTelephoneNumber,
        companyMobileNumber,
        companyPinNumber,
        companyVatNumber,
        keyContacts,
      } = applications[0]?.company;

      const currentKeyContact = { ...keyContacts[0] };

      setState((prevState) => ({
        ...prevState,
        companyId,
        registeredCompanyName,
        companyEmailAddress,
        physicalLocation,
        companyRegNumber,
        companyWebsiteUrl,
        companyTelephoneNumber,
        companyMobileNumber,
        companyPinNumber,
        companyVatNumber,
        keyContacts,
        currentKeyContact,
      }));
    }
  }, [applications]);

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <Box sx={{ flexGrow: 1, p: 2 }}>
      <Box sx={{ pt: 5, pl: 3 }}>
        <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
          <CircularProgress color="success" />
        </Backdrop>
        <Typography textAlign="left" style={formTitleStyle}>
          PART A
        </Typography>
        <Typography
          sx={{
            pb: 3,
            fontWeight: "bold",
            fontSize: "14px",
            textDecoration: "underline",
          }}
        >
          Note: Fill in both sections to be able to proceed to next step
        </Typography>

        <Typography sx={{ mb: 3, fontWeight: "bold", color: "#E62634" }}>
          Please ensure you use an email address containing your company domain
          name. Register company domain &nbsp;
          <Link
            sx={{
              color: "#E62634",
              cursor: "pointer",
              fontWeight: "bold",
              ml: -1
            }}
            href="https://domains.safaricom.co.ke/cart.php?a=add&domain=register"
            target="_blank"
            rel="noopener"
          >
            here
          </Link>
        </Typography>
        <Accordion
          expanded={currentSection === "A1"}
          onChange={() => handleAccordionChange("A1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography textAlign="left" style={formSubtitleStyle}>
              A1: Company Information
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <TextField
                error={registeredCompanyNameError}
                helperText={registeredCompanyNameHelperText}
                label="Registered Company Name"
                name="registeredCompanyName"
                type="text"
                size="small"
                style={inputStyle}
                onChange={handleInput}
                value={registeredCompanyName}
                fullWidth
                required
              />
              <TextField
                error={companyRegNumberError}
                helperText={companyRegNumberHelperText}
                label="Company Registration Number"
                name="companyRegNumber"
                type="text"
                size="small"
                style={inputStyle}
                onChange={handleInput}
                value={companyRegNumber}
                fullWidth
                required
              />
              <TextField
                error={physicalLocationError}
                helperText={physicalLocationHelperText}
                label="Physical Location"
                name="physicalLocation"
                type="text"
                size="small"
                style={inputStyle}
                onChange={handleInput}
                value={physicalLocation}
                fullWidth
                required
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <TextField
                error={companyEmailAddressError}
                helperText={companyEmailAddressHelperText}
                label="Company Email Address"
                name="companyEmailAddress"
                type="email"
                size="small"
                placeholder="e.g: example@business.com"
                style={inputStyle}
                onChange={handleInput}
                value={companyEmailAddress}
                fullWidth
                required
              />
              <TextField
                error={companyWebsiteUrlError}
                helperText={companyWebsiteUrlHelperText}
                label="Company Website"
                name="companyWebsiteUrl"
                size="small"
                type="text"
                style={inputStyle}
                onChange={handleInput}
                value={companyWebsiteUrl}
                fullWidth
              />
              <TextField
                error={companyTelephoneNumberError}
                helperText={companyTelephoneNumberHelperText}
                label="Company Telephone Number"
                name="companyTelephoneNumber"
                type="tel"
                size="small"
                style={inputStyle}
                onChange={handleInput}
                value={companyTelephoneNumber}
                fullWidth
              />
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              spacing={{ xs: 1, sm: 2, md: 4 }}
            >
              <TextField
                error={companyMobileNumberError}
                helperText={companyMobileNumberHelperText}
                label="Company Mobile Number"
                name="companyMobileNumber"
                type="tel"
                size="small"
                style={inputStyle}
                onChange={handleInput}
                value={companyMobileNumber}
                fullWidth
                required
              />
              <TextField
                error={companyPinNumberError}
                helperText={companyPinNumberHelperText}
                label="Company KRA Pin"
                name="companyPinNumber"
                type="text"
                size="small"
                style={inputStyle}
                onChange={handleInput}
                value={companyPinNumber}
                fullWidth
                required
              />
              <TextField
                error={companyVatNumberError}
                helperText={companyVatNumberHelperText}
                label="Company VAT Number"
                name="companyVatNumber"
                type="text"
                size="small"
                style={inputStyle}
                onChange={handleInput}
                value={companyVatNumber}
                fullWidth
              />
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Box sx={{ mt: 4 }}>
          <Accordion
            expanded={currentSection === "A2"}
            onChange={() => handleAccordionChange("A2")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Box>
                <Stack>
                  <Typography textAlign="left" style={formSubtitleStyle}>
                    A2: Key Contacts
                  </Typography>
                </Stack>
                <Stack>
                  <Typography textAlign="left">
                    Please select and fill in the details for each of the
                    following
                  </Typography>
                </Stack>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <FormGroup>
                <FormControlLabel
                  style={keyContactStyle}
                  control={
                    <Checkbox
                      checked={keyContactCheckbox === "Business Lead"}
                      onChange={() => handleCheckbox("Business Lead")}
                    />
                  }
                  label="CEO/Business Lead"
                />
                <FormControlLabel
                  style={keyContactStyle}
                  control={
                    <Checkbox
                      checked={keyContactCheckbox === "Finance Lead"}
                      onChange={() => handleCheckbox("Finance Lead")}
                    />
                  }
                  label="CFO/Finance Lead"
                />
                <FormControlLabel
                  style={keyContactStyle}
                  control={
                    <Checkbox
                      checked={keyContactCheckbox === "IT Lead"}
                      onChange={() => handleCheckbox("IT Lead")}
                    />
                  }
                  label="IT/Technical Lead"
                />
                <FormControlLabel
                  style={keyContactStyle}
                  control={
                    <Checkbox
                      checked={keyContactCheckbox === "Admin"}
                      onChange={() => handleCheckbox("Admin")}
                    />
                  }
                  label="Admin/Key Contact"
                />
                <Stack
                  direction={{ xs: "column", sm: "row" }}
                  spacing={{ xs: 1, sm: 2, md: 4 }}
                  sx={{ mt: 4 }}
                >
                  <TextField
                    error={!currentKeyContact?.name}
                    name="name"
                    label="Full Name"
                    type="text"
                    size="small"
                    onChange={handleInput}
                    value={currentKeyContact?.name ?? ""}
                    fullWidth
                    // disabled={!canUpdate}
                  />
                  <TextField
                    error={keyContactEmailError}
                    helperText={keyContactEmailHelperText}
                    name="email"
                    label="Email"
                    type="email"
                    size="small"
                    onChange={handleInput}
                    value={currentKeyContact?.email ?? ""}
                    fullWidth
                    // disabled={!canUpdate}
                  />
                  <TextField
                    error={keyContactPhoneNumberError}
                    helperText={keyContactPhoneNumberHelperText}
                    name="mobileNumber"
                    label="Mobile Number"
                    type="tel"
                    size="small"
                    onChange={handleInput}
                    value={currentKeyContact?.mobileNumber ?? ""}
                    fullWidth
                    // disabled={!canUpdate}
                  />
                </Stack>
              </FormGroup>
              <TableContainer sx={{ mt: 3, backgroundColor: "#f9f9f9" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Full Name
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Email Address
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Mobile Number
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {keyContacts?.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          // console.log({row});
                          handleCheckbox(row?.type);
                          const currentKeyContact = { ...row };
                          setState((prevState) => ({
                            ...prevState,
                            currentKeyContact,
                          }));
                        }}
                      >
                        <TableCell
                          component="th"
                          scope="row"
                          sx={{ textDecoration: "underline" }}
                        >
                          {row.type}
                        </TableCell>
                        <TableCell>{row.name}</TableCell>
                        <TableCell>{row.email}</TableCell>
                        <TableCell>{row.mobileNumber}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
          <Button
            color="inherit"
            variant="contained"
            style={secondaryButton}
            disableElevation
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="contained"
            style={isValidData ? primaryButton : disabledButton}
            onClick={() => handleCompany()}
            disableElevation
            disabled={!isValidData}
          >
            {isValidData ? "Save & Continue" : "Save"}
          </Button>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
