import * as React from "react";
import plane from "../../../assets/project-images/paper-plane.png";
import {assetStyle} from "./user-management-styles";

export default function LeftImage() {
    return (
        <>
            <img
                src={plane}
                className="Branding"
                alt="branding-element"
                style={assetStyle}
            />
        </>
    )
}
