import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import {AppBar, Box, IconButton, Toolbar} from "@mui/material";
import customLocalStorage from "../services/local-storage";
import {drawerWidth } from "./theme";
import safLogo from "../../../assets/project-images/safaricom-logo-white.png";
import SearchIcon from "@mui/icons-material/Search";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import { logoStyle, Search, SearchIconWrapper, StyledInputBase } from "./theme";
import Profile from "./profile";
import {Mail, Menu} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
}));

export default function TopNavBar(props) {

  const classes = useStyles();

  const { toggleSideDrawer, isSideDrawerOpen, isSignedIn } = props;

  const user = customLocalStorage.decrypt("cpoUser") || {};

  return (

    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: isSideDrawerOpen,
      })}
      style={{ backgroundColor: "#159F4A" }}
    >
      {isSignedIn && <Toolbar>
        {/* <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={toggleSideDrawer}
          edge="start"
          className={clsx(classes.menuButton, isSideDrawerOpen && classes.hide)}
        >
          <Menu/>
        </IconButton> */}
        <img src={safLogo} style={logoStyle} alt="logo" />

        {/* <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search…"
            inputProps={{ "aria-label": "search" }}
          />
        </Search> */}

        <Box sx={{ flexGrow: 1 }} />
        
        <Box sx={{ display: { xs: "none", md: "flex" } }}>
          {/* <IconButton
            size="large"
            aria-label="show 4 new mails"
            color="inherit"
          >
            <Badge badgeContent={0} color="error">
              <Mail/>
            </Badge>
          </IconButton> */}
          {/* <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
          >
            <Badge badgeContent={0} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          {user && isSignedIn && <Profile />}
        </Box>

        <Box sx={{ display: { xs: "flex", md: "none" } }}>

          <IconButton
            size="large"
            aria-label="show more"
            aria-haspopup="true"
            color="inherit"
          >
            <MoreIcon />
          </IconButton>

        </Box>

      </Toolbar>}
    </AppBar>
  );
}
