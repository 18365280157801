import * as React from "react";
import {
  Alert,
  Box,
  Button,
  Paper,
  Snackbar,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import safLogo from "../../../../assets/project-images/saf-logo-green.png";
import { logoStyle } from "../../user-management/user-management-styles";
import { primaryButton } from "../../../common-styles";
import apiRequest from "../../services/api-request";
import { useState } from "react";
import successImage from "../../../../assets/project-images/application-complete.png";

const imageStyle2 = {
  width: "180px",
  height: "200px",
  marginTop: "2%",
};

export default function AccountsReceivable(props) {
  const {
    toggleAccountsReceivablePage,
    workflowToken,
    isAccountsReceivableTaskCompleted,
  } = props;

  const initialState = {
    comment: "",
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,

    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
    loading: false,
  };

  const [state, setState] = useState(initialState);
  const [isTaskCompleted, setTaskCompleted] = useState(
    isAccountsReceivableTaskCompleted
  );

  const {
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
    loading,
  } = state;

  const handleApiData = (params) => {
    // console.log{params});
    const { data, dataName, severity, message } = params;
    // console.log(message);
    if (
      severity === "success" ||
      (severity === "error" && message.includes("expired"))
    ) {
      if (dataName === "accountsReceivable") {
        // console.log(isTaskCompleted);
        setTaskCompleted(true);
        // console.log(isTaskCompleted);
      }
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleMarkAccountsReceivable = async () => {
    if (
      !window.confirm(
        `Are you sure you wish to mark account as created in Accounts Receivable?`
      )
    ) {
      return false;
    }
    await makeApiRequest({
      uri: `/tickets/complete-task-mark-partner-as-account-receivable?workflowToken=${workflowToken}`,
      dataName: `accountsReceivable`,
      method: `post`,
      data: {
        approved: true,
        comment: "Company has been created in Accounts Receivable",
      },
    });
    // window.location.reload();
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <Box textAlign="center">
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <img src={safLogo} style={logoStyle} alt="saf-logo" />
      {!isTaskCompleted && (
        <Box>
          <Paper
            textAlign="center"
            elevation={2}
            sx={{ p: 3, width: "60%", margin: "0 auto" }}
          >
            {/* <img src={successImage} alt="success" /> */}
            <Typography sx={{ mt: 4, mb: 4 }}>Please complete task</Typography>
            <Button
              variant="contained"
              style={primaryButton}
              disableElevation
              onClick={() => handleMarkAccountsReceivable()}
            >
              MARK ACCOUNT AS CREATED ON ACCOUNTS RECEIVABLE
            </Button>
          </Paper>
          <Typography sx={{ mt: 4, mb: 4 }}>
            Copyright © 2022 Safaricom PLC. All rights reserved.
          </Typography>
        </Box>
      )}

      {isTaskCompleted && (
        <Box>
          <Paper
            textAlign="center"
            elevation={2}
            sx={{ p: 3, width: "60%", margin: "0 auto" }}
          >
            <img src={successImage} style={imageStyle2} alt="success" />
            <Typography sx={{ mt: 2, mb: 4, fontWeight: "bold" }}>
              Task Completed Successfully
            </Typography>
          </Paper>
          <Typography sx={{ mt: 4, mb: 4 }}>
            Copyright © 2022 Safaricom PLC. All rights reserved.
          </Typography>
        </Box>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
