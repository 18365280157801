import React from "react";
import {BottomNavigation, Link, Paper, Typography} from "@mui/material";

const BottomNavBar = () => {
    return (
        <Paper sx={{position: 'fixed', bottom: 0, left: 0, right: 0, height: 35}} elevation={3}>
            <BottomNavigation sx={{backgroundColor: "#159F4A"}}>
                <Typography variant="body2" align="center" sx={{color: "#f9f9f9", p: 1}}>
                    {"Copyright © "}
                    <Link
                        color="inherit"
                        href={process.env.REACT_APP_COMPANY_WEBSITE}
                        target="_blank"
                        rel="noopener"
                    >
                        {process.env.REACT_APP_COMPANY_NAME}
                    </Link>{" "}
                    {new Date().getFullYear()}
                    {"."} <code>v{global.appVersion}</code>
                </Typography>
            </BottomNavigation>
        </Paper>
    );
};

export default BottomNavBar;
