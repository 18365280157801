import SimpleCrypto from "simple-crypto-js";

const encryptData = process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION;

let encryptInit;

if (encryptData === "true") {
  encryptInit = new SimpleCrypto(
    process.env.REACT_APP_LOCAL_STORAGE_ENCRYPTION_KEY
  );
}

export default {
  removeItem: (name) => {
    let tempName = name;
    if (encryptData === "true") {
      try {
        const localStorageItems = Object.entries(localStorage);
        localStorageItems.map((item) => {
          if (encryptInit.decrypt(item[0]) === name) {
            tempName = item[0];
            return;
          }
        });
      } catch (err) {
        const error = { message: err };
        localStorage.setItem("error", JSON.stringify(error));
      }
    }
    localStorage.removeItem(tempName);
  },
  encrypt: (name, data) => {
    let tempName = name;

    if (encryptData === "true") {
      try {
        tempName = encryptInit.encrypt(name);
        data = encryptInit.encrypt(data);
        const localStorageItems = Object.entries(localStorage);
        localStorageItems.map((item) => {
          if (encryptInit.decrypt(item[0]) === name) {
            tempName = item[0];
            return;
          }
        });
      } catch (err) {
        const error = { message: err };
        localStorage.setItem("error", JSON.stringify(error));
      }
    }
    localStorage.setItem(tempName, data);
  },
  decrypt: (name) => {
    let decryptedData = localStorage.getItem(name);
    if (decryptedData) {
      try {
        decryptedData = JSON.parse(decryptedData);
      } catch (err) {
        const error = { message: err };
        localStorage.setItem("error", JSON.stringify(error));
      }
    }

    if (encryptData === "true") {
      try {
        const localStorageItems = Object.entries(localStorage);
        localStorageItems.map((item) => {
          let decryptedKey;
          try {
            decryptedKey = encryptInit.decrypt(item[0]);
          } catch (err) {
            const error = { message: err };
            localStorage.setItem("error", JSON.stringify(error));
          }
          if (decryptedKey === name) {
            let decryptedvalue;
            try {
              decryptedvalue = encryptInit.decrypt(item[1]);
            } catch (err) {
              const error = { message: err };
              localStorage.setItem("error", JSON.stringify(error));
            }
            decryptedData = decryptedvalue;
          }
        });
      } catch (err) {
        const error = { message: err };
        localStorage.setItem("error", JSON.stringify(error));
      }
    }
    return decryptedData;
  },
};
