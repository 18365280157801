import * as React from "react";
import { Box, Typography } from "@mui/material";
import emptyPage from "./../../../assets/project-images/empty-page.png";
import { emptyPageImageStyle } from "../../common-styles";

export default function EmptyTaskPage() {
  return (
    <Box sx={{ margin: "0 auto" }}>
      <img src={emptyPage} style={emptyPageImageStyle}/>
      <Typography sx= {{mt: 2}}>
        There are no tasks yet. You will receive a notification via SMS and email when a task
        is on your table{" "}
      </Typography>
    </Box>
  );
}
