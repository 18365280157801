import { Alert, Box, Button, Paper, Snackbar, Typography } from "@mui/material";
import safLogo from "../../../assets/project-images/saf-logo-green.png";
import successImage from "../../../assets/project-images/success-verify-email.png";
import failImage from "../../../assets/project-images/fail-verify-email.png";
import { logoStyle } from "./user-management-styles";
import * as React from "react";
import { primaryButton } from "../../common-styles";
import apiRequest from "../services/api-request";
import { useState } from "react";

export default function VerifyEmail(props) {
  const { isEmailVerified, toggleVerifyEmail } = props;

  const initialState = {
    userEmail: "",
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,

    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,

    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,

    loading: false,
  };

  const [state, setState] = useState(initialState);

  const {
    userEmail,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
    loading,
  } = state;

  const handleApiData = (params) => {
    // console.log{params});
    const { data, dataName, severity, message } = params;
    if (severity === "success" && data?.operationResult === "SUCCESS") {
      setState((prevState) => ({
        ...prevState,
        snackbarOpen: true,
        snackbarMessage: message,
        snackBarSeverity: severity,
      }));
      alert(`Successfully verified.`);
      window.location.replace(`${process.env.REACT_APP_BASE_URL}`);
    } else {
      setState((prevState) => ({
        ...prevState,
        snackbarOpen: true,
        snackbarMessage: message,
        snackBarSeverity: severity,
      }));
    }
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleRequestEmailToken = async () => {
    await makeApiRequest({
      uri: `/users/request-email-token/${userEmail}`,
      dataName: `requestedEmailToken`,
      method: `get`,
      data: "",
    });
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <Box textAlign="center">
      <img src={safLogo} style={logoStyle} alt="saf-logo" />
      {isEmailVerified && (
        <Box>
          <Paper
            textAlign="center"
            elevation={2}
            sx={{ p: 3, width: "60%", margin: "0 auto" }}
          >
            <img src={successImage} alt="success" />
            <Typography sx={{ mt: 4, mb: 4 }}>
              Your email was verified successfully
            </Typography>
            <Button
              variant="contained"
              style={primaryButton}
              disableElevation
              onClick={() =>
                toggleVerifyEmail({
                  isEmailVerified,
                  isEmailVerifiedPage: false,
                })
              }
            >
              PROCEED TO LOG IN
            </Button>
          </Paper>
          <Typography sx={{ mt: 4, mb: 4 }}>
            Copyright © 2022 Safaricom PLC. All rights reserved.
          </Typography>
        </Box>
      )}

      {!isEmailVerified && (
        <Box>
          <Paper
            textAlign="center"
            elevation={2}
            sx={{ p: 3, width: "60%", margin: "0 auto" }}
          >
            <img src={failImage} alt="success" />
            <Typography sx={{ mt: 4, mb: 4 }}>
              There was a problem verifying your email
            </Typography>
            <Button
              variant="contained"
              style={primaryButton}
              disableElevation
              onClick={() => handleRequestEmailToken()}
            >
              RESEND VERIFICATION LINK
            </Button>
          </Paper>
          <Typography sx={{ mt: 4, mb: 4 }}>
            Copyright © 2022 Safaricom PLC. All rights reserved.
          </Typography>

          <Snackbar
            anchorOrigin={{
              vertical: snackBarVerticalPosition,
              horizontal: snackBarHorizontalPosition,
            }}
            open={snackbarOpen}
            autoHideDuration={snackBarAutoHideDuration}
            onClose={() => snackBarHandle(false)}
          >
            <Alert
              onClose={handleClose}
              severity={snackBarSeverity}
              sx={{ width: "100%" }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      )}
    </Box>
  );
}
