import axios from "axios";
import customLocalStorage from "./local-storage";
import CryptoJS from "crypto-js";
import moment from "moment";
import debounce from "lodash.debounce";

const encrypt = (data) => {
  // console.log({ data });
  return CryptoJS.AES.encrypt(
    data,
    CryptoJS.enc.Utf8.parse(process.env.REACT_APP_PUBLIC_KEY),
    {
      iv: CryptoJS.enc.Utf8.parse(
        process.env.REACT_APP_PUBLIC_KEY.substring(0, 16)
      ),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  ).toString();
};

const decryptData = (data) => {
  // console.log({ data });
  let decryptedData;
  try {
    const decrypted = CryptoJS.AES.decrypt(
      data,
      CryptoJS.enc.Utf8.parse(process.env.REACT_APP_PUBLIC_KEY),
      {
        iv: CryptoJS.enc.Utf8.parse(
          process.env.REACT_APP_PUBLIC_KEY.substring(0, 16)
        ),
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
    decryptedData = decrypted.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    // console.log({ error });
  }
  if (decryptedData) {
    decryptedData = JSON.parse(decryptedData);
  }
  // console.log({ decryptedData });
  return decryptedData;
};

const handleLogOut = debounce((params) => {
  // alert("401");
  // console.log({ params })
  const { url } = params;
  // console.log({ url })
  const otpUrlSearch = url.search("otp");
  const passwordUrlSearch = url.search("password");
  // console.log({ otpUrlSearch })
  // console.log({ passwordUrlSearch })
  const urlException = otpUrlSearch === -1 || passwordUrlSearch === -1;
  // console.log({ urlException })
  if (urlException) {
    return;
  }
  localStorage.clear();
  window.location.reload();
}, 5000);

const apigeeAuthentication = async (params) => {
  const { url } = params;

  let { apigeeAccessToken, expiryTimestamp } =
    customLocalStorage.decrypt("cpoApigee") || {};

  const currentTimestamp = moment().unix();
  const isApigeeTokenExpired = currentTimestamp > expiryTimestamp;

  const getToken = !apigeeAccessToken || isApigeeTokenExpired;

  if (getToken) {
    const config = {
      method: "get",
      url: `${process.env.REACT_APP_API_URL}/login/apigee/token`,
    };
    await axios(config)
      .then(function (response) {
        // console.log({ response });
        if (response?.request?.status == 401) {
          handleLogOut({ url });
          // alert("401");
        }
        const data = response?.data?.data
          ? decryptData(response?.data?.data)
          : response?.data;
        // console.log({ data });
        const { expiresIn, tokenType } = data;
        apigeeAccessToken = `${tokenType} ${data?.accessToken}`;
        const currentTimestamp = moment().unix();
        const expiryTimestamp = currentTimestamp + expiresIn * 60;
        customLocalStorage.encrypt(
          "cpoApigee",
          JSON.stringify({
            apigeeAccessToken,
            expiryTimestamp,
          })
        );
      })
      .catch(function (error) {
        // console.log(error?.response.status);
        if (error?.response?.status == 401) {
          handleLogOut({ url });
          // alert("401")
        }
      });
  }
  return apigeeAccessToken;
};

const apiRequest = async (params) => {
  // console.log({ params });
  const { url, callBack, dataName, method, contentType, headers } = params;
  let { data } = params;

  //Encrypt all data except file upload
  if (url.search("/upload") === -1) {
    const encryptedData = encrypt(JSON.stringify(data));
    // console.log({ encryptedData });
    data = { data: encryptedData };
    // console.log({data})
  }

  let config = {
    method,
    url,
    data,
  };

  const apigeeAccessToken = await apigeeAuthentication({ url });

  // console.log({ apigeeAccessToken });

  if (!apigeeAccessToken) {
    return callBack({
      data,
      dataName,
      severity: "error",
      status: 400,
      message: `There was a network problem. Please refresh your browser`,
    });
  }

  const { accessToken } = customLocalStorage.decrypt("cpoUser") || {};

  config.headers = {
    "Content-Type": contentType ?? "application/json",
    Authorization: apigeeAccessToken,
    ...headers,
  };

  if (
    (url.search("/login/") === -1 || url.search("/workflow_token/") === -1) &&
    accessToken
  ) {
    config.headers.TokenId = `Bearer ${accessToken}`;
  }

  if (
    !(url.search("fileId") === -1) ||
    !(url.search("invoice") === -1) ||
    !(url.search("vpnform") === -1) ||
    !(url.search("fileName") === -1)
  ) {
    config.responseType = "arraybuffer";
    config.headers.Accept = "application/pdf";
  }

  await axios(config)
    .then((res) => {
      // console.log({ res });
      if (res?.request?.status == 401) {
        handleLogOut({ url });
        // alert("401")
      }

      if (res?.data?.error && res?.request?.status >= 400) {
        return callBack({
          data: res?.data?.error,
          dataName,
          severity: "error",
          status: res?.request?.status,
          message: res?.data?.error || res?.data?.operationMessage,
        });
      }

      const decryptedData = res?.data?.data
        ? decryptData(res?.data?.data)
        : res?.data;
      // console.log({decryptedData})

      return callBack({
        data: decryptedData,
        dataName,
        severity: "success",
        headers: res?.headers,
        status: res?.request?.status,
        message: "success",
      });
    })
    .catch(async (err) => {
      // console.log({ err });
      // console.log(err?.response);
      const sentRequest = err?.config;

      // console.log({ sentRequest });
      // console.log(err?.response?.status == undefined)

      if (err?.response?.status == 401 || err?.response?.status == undefined) {
        localStorage.clear();

        await apigeeAuthentication({ url }).then(async (res) => {
          // console.log({res})
          sentRequest.headers.Authorization = res;
          return await axios(sentRequest)
            .then((res) => {
              const decryptedData = res?.data?.data
                ? decryptData(res?.data?.data)
                : res?.data;
              // console.log({decryptedData})

              return callBack({
                data: decryptedData,
                dataName,
                severity: "success",
                headers: res?.headers,
                status: res?.request?.status,
                message: "success",
              });
            })
            .catch((err) => {
              const decryptedData = decryptData(err?.response?.data?.data);

              const responseData =
                decryptedData?.messageDescription ||
                decryptedData?.message ||
                decryptedData?.error_description ||
                decryptedData?.operationMessage ||
                err?.message;
              return callBack({
                data: responseData,
                dataName,
                severity: "error",
                status: err?.response?.status,
                message: responseData,
              });
            });
        });
        // axios(config);
        // handleLogOut({ url });
        // alert("401")
      } else {
        const decryptedData = decryptData(err?.response?.data?.data);

        const responseData =
          decryptedData?.messageDescription ||
          decryptedData?.message ||
          decryptedData?.error_description ||
          decryptedData?.operationMessage ||
          err?.message;
        return callBack({
          data: responseData,
          dataName,
          severity: "error",
          status: err?.response?.status,
          message: responseData,
        });
      }
    });
};

export default apiRequest;
