import React, { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Backdrop,
  Typography,
  CircularProgress,
  Link,
  Button,
  Stack,
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import PartA from "./form-parts/partA";
import PartB from "./form-parts/partB";
import PartC from "./form-parts/partC";
import CspAppointmentInstructions from "./form-parts/csp-appointment-instructions";
import apiRequest from "../../services/api-request";
import customLocalStorage from "../../services/local-storage";
import PartD from "./form-parts/PartD";
import moment from "moment";
import { primaryButton, secondaryButton } from "../../../common-styles";
import ReceiptUpload from "../receipt-upload";
import SdpApplicationFormPage from "../sdp-application-form";
import partnerSuccessImage from "./../../../../assets/project-images/partners.png";
import waitingImage from "./../../../../assets/project-images/approval-task.png";
import applicationSubmissionImage from "./../../../../assets/project-images/application-complete.png";

const imageStyle = {
  width: "220px",
  height: "210px",
};

const imageStyle2 = {
  width: "180px",
  height: "200px",
};

export default function ApplicationFormPage(props) {
  const { toggleLandingPage, handleGetApplications, applications } = props;

  const { userId } = customLocalStorage.decrypt("cpoUser") || {};

  // console.log({ companyId });

  // console.log{userId});

  const initialState = {
    skipped: 0,
    pendingTasks: [],
    additionalApplicationsSelection: "",
    fileData: "",
    loading: false,
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,

    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
  };

  const [state, setState] = useState(initialState);

  const [isPreSubmission, setIsPreSubmission] = useState(
    applications?.length > 0
      ? !applications[0]?.company?.applicationComplete
      : true
  );

  const [currentTask, setCurrentTask] = useState({});

  const [activeStep, setActiveStep] = useState(0);

  const [companyId, setCompanyId] = useState(
    applications?.length > 0 ? applications[0]?.company?.companyId : ""
  );

  // const [application, setApplication] = useState(applications[0]);
  const [company, setCompany] = useState(applications[0]?.company);

  const {
    skipped,
    pendingTasks,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
    loading,
  } = state;

  // const isPostSubmission = applications[0]?.company?.applicationComplete;
  const isPostSubmission = !isPreSubmission;

  const preSubmissionSteps = [
    "Appointment Instructions",
    "General Company Information",
    "Documentation",
    "Additional Applications",
    "Business Value Proposition",
  ];

  const postSubmissionSteps = [
    "Application Review",
    "Contract Signing",
    "Payment",
    "Payment Approval",
    "Account creation",
    "SDP Application",
    "SDP Testing & Integration",
    "Complete",
  ];

  let steps = preSubmissionSteps;

  if (isPostSubmission) {
    steps = postSubmissionSteps;
  }

  const recordUserActivity = () => {
    // console.log(`---recordUserActivity---`);
    const user = customLocalStorage.decrypt("cpoUser");

    if (user) {
      const currentTime = moment();
      // console.log({ currentTime });
      user.lastTimeActive = currentTime;
      customLocalStorage.encrypt("cpoUser", JSON.stringify(user));
    }
  };

  recordUserActivity();

  const handleNext = async () => {
    handleLoader(true);
    setActiveStep(activeStep + 1);
    handleLoader(false);
  };

  const handleBack = () => {
    // setActiveStep((prevActiveStep) => prevActiveStep - 1);
    setActiveStep(activeStep - 1);
    handleCompanyData();
  };

  // const handleReset = () => {
  //   setState((prevState) => ({
  //     ...prevState,
  //     activeStep: 0,
  //   }));
  // };

  const handleApiData = (params) => {
    // console.log{params});

    const { data, dataName, severity, message } = params;

    // console.log({ dataName });

    // console.log({ data });

    const activeTask = data?.filter((task) => task?.wfActive == true)[0];

    setCurrentTask(activeTask);

    // console.log({ activeTask });

    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleGetPendingTasks = async () => {
    handleLoader(true);
    const limit = 500;
    await makeApiRequest({
      uri: `/tickets?limit=${limit}&companyId=${companyId}`,
      dataName: `pendingTasks`,
      method: `get`,
      data: {},
    });
    handleLoader(false);
  };

  const handleSetActiveStep = async () => {
    setActiveStep(isPostSubmission ? 0 : activeStep);
    if (isPostSubmission) {
      getActiveStep();
    }
  };

  useEffect(() => {
    (async () => {
      if (companyId) {
        await handleGetPendingTasks();
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await handleSetActiveStep();
    })();
  }, [currentTask]);

  const getActiveStep = () => {
    switch (currentTask?.wfActiveProcess) {
      case "vas_countercheck_task":
      case "legal_approval_task": {
        setActiveStep(0);
        break;
      }
      case "task_contract_signing_marking": {
        setActiveStep(1);
        break;
      }
      case "task_upload_payment_receipts": {
        setActiveStep(2);
        break;
      }
      case "task_validate_uploaded_receipt": {
        setActiveStep(3);
        break;
      }
      case "task_map_partner_as_account_payable":
      case "task_map_partner_as_ac_receivable": {
        setActiveStep(4);
        break;
      }
      case "task_fill_sdp_details": {
        setActiveStep(5);
        break;
      }
      case "task_validate_sdp_details":
      case "task_attach_sdp_crq":
      case "task_test_sdp_details":
      case "task_validate_sdp_details_by_it":
      case "task_vpn_configuration":
      case "task_complete_sdp": {
        setActiveStep(6);
        break;
      }
      default: {
        setActiveStep(7);
        break;
      }
    }
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  const handleCloseSnackBar = () => {
    setState((prevState) => ({
      ...prevState,
      displaySnackBar: false,
      isLoading: false,
    }));
  };


  const handleCompanyData = () => {
    handleGetApplications();
  };

  const getCompany = async (companyData) => {
    setCompany(companyData);
    setCompanyId(companyData?.companyId);
    await handleGetPendingTasks();
  };

  const handleLastStep = () => {
    setActiveStep(0);
    setIsPreSubmission(false);
  };

  return (
    <>
      <Box>
        <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
          <CircularProgress color="success" />
        </Backdrop>
        <Box sx={{ flexGrow: 1, p: 5, backgroundColor: '#f9f9f9'}}>
          <Grid container direction="row" alignItems="center">
            <IconButton
              onClick={() =>
                toggleLandingPage({
                  currentPage: "landingPage",
                })
              }
            >
              <ArrowBack />
            </IconButton>
            <Typography
              variant="h4"
              textAlign="left"
              sx={{ fontWeight: "bold" }}
            >
              Content Service Provider Application
            </Typography>
            <Typography sx={{ pl: 5, pt: 2 }}>
              NB: Safaricom Reserves the right to accept or reject any
              application, and will communicate this within 7 - 30 days of submission
            </Typography>
            <Divider />
          </Grid>

          {/* <Stepper activeStep={activeStep} sx={{ mt: 5 }}>
            {steps.map((label, index) => {
              const stepProps: { completed?: boolean } = {};
              const labelProps: { optional?: React.ReactNode } = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper> */}

          <Stepper activeStep={activeStep} sx={{ mt: 5 }}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          <Container>
            {isPreSubmission && activeStep === 0 && (
              <CspAppointmentInstructions handleNext={handleNext} />
            )}

            {isPreSubmission && activeStep === 1 && (
              <PartA
                getCompany={getCompany}
                handleCompanyData={handleCompanyData}
                handleNext={handleNext}
                applications={applications}
                handleBack={handleBack}
              />
            )}

            {isPreSubmission && activeStep === 2 && (
              <PartB
                handleCompanyData={handleCompanyData}
                handleNext={handleNext}
                handleBack={handleBack}
                applications={applications}
              />
            )}

            {isPreSubmission && activeStep === 3 && (
              <PartC
                handleNext={handleNext}
                handleBack={handleBack}
                handleCompanyData={handleCompanyData}
                applications={applications}
              />
            )}

            {isPreSubmission && activeStep === 4 && (
              <PartD
                handleLastStep={handleLastStep}
                handleBack={handleBack}
                handleCompanyData={handleCompanyData}
                handleGetPendingTasks={handleGetPendingTasks}
                applications={applications}
              />
            )}

            {isPostSubmission && activeStep === 0 && (
              <>
                <Box textAlign="center" sx={{ mt: 6 }}>
                  <img
                    src={applicationSubmissionImage}
                    style={imageStyle2}
                    alt="success"
                  />
                  <Typography sx={{ mt: 2 }}>
                    Your application has been submitted successfully and is
                    being reviewed. You will be notified if approved.
                  </Typography>
                </Box>
              </>
            )}
            {isPostSubmission && activeStep === 1 && (
              <>
                <Box textAlign="center" sx={{ mt: 6 }}>
                  <img src={waitingImage} style={imageStyle2} alt="success" />
                  <Box sx={{ width: "70%", m: "0 auto" }}>
                    <Typography sx={{ mt: 2 }}>
                      Your application is now in the{" "}
                      <b>Contract Signing stage</b>. You will receive your
                      Contract via email through <b>Adobe Sign</b>. A proforma
                      invoice will be sent to you when this is successfully
                      completed.
                    </Typography>
                  </Box>
                </Box>
              </>
            )}
            {isPostSubmission && activeStep === 2 && (
              <>
                <ReceiptUpload
                  handleNext={handleNext}
                  handleBack={handleBack}
                  handleCompanyData={handleCompanyData}
                  applications={applications}
                />
              </>
            )}
            {isPostSubmission && activeStep === 3 && (
              <>
                <Box textAlign="center" sx={{ mt: 6 }}>
                  <img src={waitingImage} style={imageStyle2} alt="success" />
                  <Typography sx={{ mt: 2 }}>
                    Your payment is being verified. Your application will move
                    to next stage once this is complete
                  </Typography>
                </Box>
              </>
            )}
            {isPostSubmission && activeStep === 4 && (
              <>
                <Box textAlign="center" sx={{ mt: 6 }}>
                  <img src={waitingImage} style={imageStyle2} alt="success" />
                  <Typography sx={{ mt: 2 }}>
                    Your <b>supplier account</b> is being created. Your
                    application will move to next stage once this is complete
                  </Typography>
                </Box>
              </>
            )}
            {isPostSubmission && activeStep === 5 && (
              <>
                <SdpApplicationFormPage
                  handleNext={handleNext}
                  handleBack={handleBack}
                  handleCompanyData={handleCompanyData}
                  applications={applications}
                  currentTask={currentTask}
                />
              </>
            )}
            {isPostSubmission && activeStep === 6 && (
              <>
                <Box textAlign="center" sx={{ mt: 6 }}>
                  <img src={waitingImage} style={imageStyle2} alt="success" />
                  <Typography sx={{ mt: 2 }}>
                    Testing and integration to SDP Portal is underway.
                  </Typography>
                </Box>
              </>
            )}
            {isPostSubmission && activeStep === 7 && (
              <>
                <Box textAlign="center" sx={{ mt: 6 }}>
                  <img
                    src={partnerSuccessImage}
                    style={imageStyle}
                    alt="success"
                  />
                  <Typography sx={{ mt: 2 }}>
                    Your organization has been successfully onboarded as a
                    Content Service Provider with Safaricom. Check your email
                    for further details.
                  </Typography>

                  <Stack direction="row" sx={{ mt: 2 }}>
                    <Box sx={{ m: "0 auto" }}>
                      <Link
                        href={`${process.env.REACT_APP_HOMEPAGE}/files/DIGITAL SDP QUICK USER GUIDE v3.2.pdf`}
                        target="_blank"
                        download
                        sx={{ cursor: "pointer", textDecoration: "none" }}
                      >
                        <Button style={primaryButton} sx={{ mr: 2 }}>
                          DOWNLOAD SERVICE DELIVERY PLATFORM USER GUIDE
                        </Button>
                      </Link>
                      <Link
                        href={`${process.env.REACT_APP_HOMEPAGE}/files/6d_Safaricom_SDP_API_V1.55.pdf`}
                        target="_blank"
                        download
                        sx={{ cursor: "pointer", textDecoration: "none" }}
                      >
                        <Button style={secondaryButton} sx={{ mr: 2 }}>
                          DOWNLOAD SERVICE DELIVERY PLATFORM API DOCUMENT
                        </Button>
                      </Link>
                    </Box>
                  </Stack>
                </Box>
              </>
            )}
          </Container>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: snackBarVerticalPosition,
            horizontal: snackBarHorizontalPosition,
          }}
          open={snackbarOpen}
          autoHideDuration={snackBarAutoHideDuration}
          onClose={() => snackBarHandle(false)}
        >
          <Alert
            onClose={handleClose}
            severity={snackBarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}
