import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import {
  primaryButton,
  secondaryButton,
  disabledButton,
} from "../../../../common-styles";
import { inputStyle } from "../formStyles";
import apiRequest from "../../../services/api-request";
import validator from "validator";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

export default function AddOrUpdateBusinessValueProposition(props) {
  const classes = useStyles();

  const { handleCompanyData, handleCloseDialog, action, applications } = props;

  const { companyId } = applications[0]?.company;

  const initialState = {
    businessArea: "",
    consumerInsight: "",
    year1: 0,
    year2: 0,
    year3: 0,
    marketingStrategies: "",
    targetMarket: "",
    projectionId: "",
  };

  const [state, setState] = useState(initialState);

  const {
    businessArea,
    consumerInsight,
    marketingStrategies,
    targetMarket,
    year1,
    year2,
    year3,
    projectionId,
  } = state;

  // const isValidData = !isEmpty(name);

  const businessAreaError =
    !businessArea ||
    businessArea?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const businessAreaHelperText = businessAreaError ? "Invalid" : "";

  const consumerInsightError =
    !consumerInsight ||
    consumerInsight?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const consumerInsightHelperText = consumerInsightError
    ? "Invalid"
    : "";

  const marketingStrategiesError =
    !marketingStrategies ||
    marketingStrategies?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const marketingStrategiesHelperText = marketingStrategiesError
    ? "Invalid"
    : "";

  const targetMarketError =
    !targetMarket ||
    targetMarket?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const targetMarketHelperText = targetMarketError ? "Invalid" : "";

  const year1Error =
    !year1 ||
    !validator.isNumeric(year1.toString(), { no_symbols: true }) ||
    !(year1 > 0) ||
    year1?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const year1HelperText = year1Error
    ? "Invalid/Should be greater than 0"
    : "";

  const year2Error =
    !year2 ||
    !validator.isNumeric(year2.toString(), { no_symbols: true }) ||
    !(year2 > 0) ||
    year2?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const year2HelperText = year2Error
    ? "Invalid/Should be greater than 0"
    : "";

  const year3Error =
    !year3 ||
    !validator.isNumeric(year3.toString(), { no_symbols: true }) ||
    !(year3 > 0) ||
    year3?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const year3HelperText = year3Error
    ? "Invalid/Should be greater than 0"
    : "";

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (props?.action === "Update") {
      
      const { currentBusinessValueProposition } = props;

      const {
        businessArea,
        consumerInsight,
        marketingStrategies,
        targetMarket,
        financialProjections,
      } = currentBusinessValueProposition ?? {};

      const { projectionId, year1, year2, year3 } = financialProjections ?? {};

      setState((prevState) => ({
        ...prevState,
        businessArea,
        consumerInsight,
        marketingStrategies,
        targetMarket,
        year1,
        year2,
        year3,
        projectionId,
      }));
    }
  }, [props.currentBusinessValueProposition]);

  const isEmpty = (data) => {
    let response = true;
    if (data) {
      response = false;
    }
    return response;
  };

  const isValidData =
    !businessAreaError &&
    !consumerInsightError &&
    !marketingStrategiesError &&
    !consumerInsightError &&
    !year1Error &&
    !year2Error &&
    !year3Error;

  const handleApiData = (params) => {
    // console.log{params});
    const { severity, message } = params;
    if (severity === "success") {
      // const {companyId} = data;
      handleCloseDialog();
      handleCompanyData();
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleSubmitBusinessValueProposition = async () => {
    await makeApiRequest({
      uri: `/kyc/company/${companyId}`,
      dataName: `createdCompany`,
      method: `put`,
      data: {
        ...applications[0]?.company,
        businessValueProposition: [
          ...applications[0]?.company?.businessValueProposition,
          {
            businessArea,
            consumerInsight,
            financialProjections: {
              projectionId,
              year1,
              year2,
              year3,
            },
            marketingStrategies,
            targetMarket,
          },
        ],
      },
    });
  };

  return (
    <Box>
      <Dialog
        open={!(action === "Close")}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
      >
        <DialogContent>
          <Typography sx={{ fontWeight: "bold", mb: 4 }}>
            {action} New Business Value Proposition
          </Typography>
          <TextField
            error={businessAreaError}
            helperText={businessAreaHelperText}
            label="Business Area"
            name="businessArea"
            type="text"
            size="small"
            style={inputStyle}
            onChange={handleInput}
            value={businessArea}
            fullWidth
            required
          />
          <Typography>Financial Projections:</Typography>
          <Stack direction="row" spacing={5} sx={{ mt: 2, mb: 3 }}>
            <TextField
              className={classes.input}
              error={year1Error}
              helperText={year1HelperText}
              name="year1"
              label="Year 1"
              type="number"
              size="small"
              pattern="[0-9]"
              onChange={handleInput}
              value={year1}
              fullWidth
              // disabled={!canUpdate}
            />
            <TextField
              className={classes.input}
              error={year2Error}
              helperText={year2HelperText}
              name="year2"
              label="Year 2"
              type="number"
              size="small"
              pattern="[0-9]"
              onChange={handleInput}
              value={year2}
              fullWidth
              // disabled={!canUpdate}
            />
            <TextField
              className={classes.input}
              error={year3Error}
              helperText={year3HelperText}
              name="year3"
              label="Year 3"
              type="number"
              size="small"
              pattern="[0-9]"
              onChange={handleInput}
              value={year3}
              fullWidth
              // disabled={!canUpdate}
            />
          </Stack>
          <Stack direction="row" spacing={5}>
            <TextField
              error={marketingStrategiesError}
              helperText={marketingStrategiesHelperText}
              label="Marketing Strategies"
              name="marketingStrategies"
              type="text"
              multiline
              onChange={handleInput}
              value={marketingStrategies}
              fullWidth
            />
          </Stack>
          <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
            <TextField
              error={targetMarketError}
              helperText={targetMarketHelperText}
              label="Target Market"
              name="targetMarket"
              type="text"
              multiline
              onChange={handleInput}
              value={targetMarket}
              fullWidth
            />
          </Stack>
          <Stack direction="row" spacing={5} sx={{ mt: 4 }}>
            <TextField
              error={consumerInsightError}
              helperText={consumerInsightHelperText}
              label="Consumer Insight"
              name="consumerInsight"
              type="text"
              multiline
              onChange={handleInput}
              value={consumerInsight}
              fullWidth
            />
          </Stack>

          <Grid container spacing={3} sx={{ mt: 5 }}>
            <Grid item xs>
              <Button
                onClick={() => {
                  handleCloseDialog();
                }}
                color="primary"
                variant="outlined"
                style={secondaryButton}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                variant="contained"
                style={isValidData ? primaryButton: disabledButton}
                onClick={() => {
                  handleSubmitBusinessValueProposition();
                }}
                disableElevation
                disabled={!isValidData}
              >
                {action === "Add" ? "Save" : "Update"}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
