import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import { faker } from "@faker-js/faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "Chart.js Bar Chart",
    },
  },
};

// const labels = ["January", "February", "March", "April", "May", "June", "July"];
const stagesData = [{ January: 1 }, { February: 2 }, { March: 3 }];
const stagesLabels = Object.keys(stagesData).map(
  (key) => Object.keys(stagesData[key])[0]
);
const stagesValues = Object.keys(stagesData).map(
  (key) => Object.values(stagesData[key])[0]
);

export const data = {
  labels: stagesLabels,
  datasets: [
    {
      label: "Number of tasks per stage",
      data: stagesValues,
      // data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    //   {
    //     label: 'Dataset 2',
    //     data: labels.map(() => faker.datatype.number({ min: 0, max: 1000 })),
    //     backgroundColor: 'rgba(53, 162, 235, 0.5)',
    //   },
  ],
};

export default function UtilizationReport() {
  // console.log({ data });
  // console.log({ stagesLabels });
  // console.log({ stagesValues });

  return <Bar options={options} data={data} />;
}
