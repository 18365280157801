import * as React from "react";
import {
  Backdrop,
  Box,
  Button,
  Container,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  TextField,
  Typography,
  Snackbar,
  CircularProgress,
  Alert,
} from "@mui/material";
import {
  disabledButton,
  modalStyle,
  modalStyle2,
  primaryButton,
  approveButton,
  rejectButton,
  secondaryButton,
} from "../../../common-styles";
import { ArrowBack } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { inputStyle } from "../../partner/application-form/formStyles";
import apiRequest from "../../services/api-request";
import { useState } from "react";
import validator from "validator";

const sectionHeadingStyle = {
  fontWeight: "bold",
  color: "#159F4A",
  fontSize: "1.2em",
};

const tableHeaderStyle = {
  fontWeight: "bold",
};

export default function ApplicationDetailsPage(props) {
  const { togglePage, currentTask } = props;

  const { wfId } = currentTask;
  const { companyId } = currentTask?.wfCurrTrans;

  const initialState = {
    approved: false,
    comment: "",
    commentError: false,
    commentHelperText: "",
    crqNo: "",
    crqNoError: false,
    crqNoHelperText: "",
    currentSelection: "",
    currentPaymentApprovalSelection: "",
    submitButtonEnabled: false,
    loading: false,
    additionalApplicationsSelection: "",
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,
    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
  };

  const [state, setState] = useState(initialState);
  // const [isSubmitDisabledButton, setSubmitDisabledButton] = useState(false);

  const {
    comment,
    currentSelection,
    currentPaymentApprovalSelection,
    commentError,
    commentHelperText,
    crqNo,
    submitButtonEnabled,
    loading,
    additionalApplicationsSelection,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarVerticalPosition,
    snackBarHorizontalPosition,
  } = state;

  // const user = 'admin';

  const crqNoError =
    !crqNo ||
    !validator.isAlphanumeric(crqNo, ["en-US"]) ||
    crqNo?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const crqNoHelperText = crqNoError ? "Required/Invalid" : "";

  const handleApiData = async (params) => {
    // console.log{params});
    const { severity, message, dataName, data, headers } = params;

    if (severity === "success") {
      const contentType = headers["content-type"];
      // console.log({ contentType });

      if (dataName === "fetchedFile" || dataName === "fetchedVpnReport") {
        const file = new Blob([data], { type: contentType });

        const fileURL = URL.createObjectURL(file);

        const pdfWindow = window.open();

        pdfWindow.location.href = fileURL;
      }
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const handleSubmitApproval = async () => {
    await makeApiRequest({
      uri: `/tickets/complete-task-vas-validation`,
      dataName: `approvedCompany`,
      method: `post`,
      data: {
        approved: currentSelection == "approveApplication" ? true : false,
        comment,
        wfId,
      },
    });
    handleCloseApprovalModal();
    window.location.reload();
  };

  const handleGetFiles = async (fileId) => {
    await makeApiRequest({
      uri: `/files/file?fileId=${fileId}`,
      dataName: `fetchedFile`,
      method: `get`,
      data: {},
    });
  };

  const handleGetVpnFormReport = async () => {
    await makeApiRequest({
      uri: `/reports/vpnform/${companyId}`,
      dataName: `fetchedVpnReport`,
      method: `get`,
      data: {},
    });
  };

  const handleVerifyPayment = async () => {
    await makeApiRequest({
      uri: `/tickets/complete-task-validate-payment-upload`,
      dataName: `verifiedPayment`,
      method: `post`,
      data: {
        approved:
          currentPaymentApprovalSelection == "approvePayment" ? true : false,
        comment,
        wfId,
      },
    });
    handleCloseModal();
    window.location.reload();
  };

  const handleMarkAccountsPayable = async () => {
    if (
      !window.confirm(
        `Are you sure you wish to mark this account as created on accounts payable?`
      )
    ) {
      return false;
    }
    await makeApiRequest({
      uri: `/tickets/complete-task-mark-partner-as-account-payable`,
      dataName: `accountsPayable`,
      method: `post`,
      data: {
        approved: true,
        comment: "Account marked as created on accounts payable",
        wfId: wfId,
      },
    });
    window.location.reload();
  };

  const handleAddCrqNo = async () => {
    await makeApiRequest({
      uri: `/kyc/company/${companyId}/crq/${crqNo}`,
      dataName: `addedCrq`,
      method: `put`,
      data: {},
    });
    window.location.reload();
  };

  const handleAdditionalApplication = (additionalApplicationsSelection) => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      additionalApplicationsSelection,
    }));
  };

  const handleCloseModal = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      currentSelection: "",
    }));
  };

  const handleApproval = (currentSelection) => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      currentSelection,
    }));
  };

  const handlePaymentApproval = (currentPaymentApprovalSelection) => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      currentPaymentApprovalSelection,
    }));
  };

  const handleCloseApprovalModal = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      currentSelection: "",
    }));
  };

  const handleClosePaymentApprovalModal = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      currentPaymentApprovalSelection: "",
    }));
  };

  const handleCloseAdditionalApplicationsModal = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      additionalApplicationsSelection: "",
    }));
  };

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      commentError: name === "comment" ? isEmpty(value) : commentError,
      commentHelperText:
        name === "comment"
          ? isEmpty(value)
            ? "Required."
            : ""
          : commentHelperText,
    }));
  };

  const isEmpty = (data) => {
    let response = true;
    if (data) {
      response = false;
    }
    return response;
  };

  const isValidData = !isEmpty(comment);
  const isValidCrq = !isEmpty(crqNo);

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <Box sx={{ flexGrow: 1, pb: 5, pt: 4, backgroundColor: "#f9f9f9" }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Grid container direction="row" alignItems="center">
        <IconButton
          onClick={() =>
            togglePage({
              currentPage: "pendingTaskList",
              currentTask,
            })
          }
        >
          <ArrowBack />
        </IconButton>

        <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
          CSP Application #{currentTask?.wfCurrTrans?.companyId}
        </Typography>
      </Grid>

      <Grid container>
        <Grid item xs={9}>
          <Container sx={{ pt: 3 }}>
            <Typography textAlign="left" style={sectionHeadingStyle}>
              Company Information
            </Typography>
            <Container>
              <Grid container sx={{ pt: 3, ml: -4 }}>
                <Grid item xs={6}>
                  <List>
                    {[
                      "Registered Company",
                      "Company Email Address",
                      "Company Website",
                      "Company Mobile Number",
                      "Physical Address",
                    ].map((value) => {
                      const listItemId = `${value}`;
                      return (
                        <ListItem sx={{ p: 1 }}>
                          <Typography
                            id={listItemId}
                            sx={{ fontWeight: "bold" }}
                          >
                            {`${value}`}
                          </Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
                <Grid item xs={6}>
                  <List>
                    <ListItem sx={{}}>
                      <Typography>
                        {currentTask?.wfCurrTrans?.registeredCompanyName}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        {currentTask?.wfCurrTrans?.companyEmailAddress}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        {currentTask?.wfCurrTrans?.companyWebsiteUrl}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        {currentTask?.wfCurrTrans?.companyMobileNumber}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        {currentTask?.wfCurrTrans?.physicalLocation}
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Container>
          </Container>

          <Container sx={{ pt: 4 }}>
            <Typography textAlign="left" style={sectionHeadingStyle}>
              Key Contacts
            </Typography>
            <TableContainer sx={{ ml: -2, mt: 3 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Full Name</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Email Address
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Mobile Number
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentTask?.wfCurrTrans?.keyContacts.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ textDecoration: "underline" }}
                      >
                        {row.type}
                      </TableCell>
                      <TableCell>{row.name}</TableCell>
                      <TableCell>{row.email}</TableCell>
                      <TableCell>{row.mobileNumber}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/*<TableContainer sx={{ml: -2}}>*/}
            {/*    <Table sx={{minWidth: 650}} aria-label="simple table">*/}
            {/*        <TableHead>*/}
            {/*            <TableRow>*/}
            {/*                <TableCell></TableCell>*/}
            {/*                <TableCell style={tableHeaderStyle}>CEO/Business Lead</TableCell>*/}
            {/*                <TableCell style={tableHeaderStyle}>CFO/Finance Lead</TableCell>*/}
            {/*                <TableCell style={tableHeaderStyle}>Technical/IT Lead</TableCell>*/}
            {/*                <TableCell style={tableHeaderStyle}>Admin/Key Contact</TableCell>*/}
            {/*            </TableRow>*/}
            {/*        </TableHead>*/}
            {/*        <TableBody>*/}
            {/*            <TableRow>*/}
            {/*                <TableCell style={tableHeaderStyle}>Name</TableCell>*/}
            {/*                <TableCell>{applicationData.keyContacts.businessLead.name}</TableCell>*/}
            {/*                <TableCell>{applicationData.keyContacts.financeLead.name}</TableCell>*/}
            {/*                <TableCell>{applicationData.keyContacts.itLead.name}</TableCell>*/}
            {/*                <TableCell>{applicationData.keyContacts.admin.name}</TableCell>*/}
            {/*            </TableRow>*/}
            {/*            <TableRow>*/}
            {/*                <TableCell style={tableHeaderStyle}>Email</TableCell>*/}
            {/*                <TableCell*/}
            {/*                    style={tableCellStyle}>*/}
            {/*                    {applicationData.keyContacts.businessLead.emailAddress}</TableCell>*/}
            {/*                <TableCell*/}
            {/*                    style={tableCellStyle}>*/}
            {/*                    {applicationData.keyContacts.financeLead.emailAddress}</TableCell>*/}
            {/*                <TableCell*/}
            {/*                    style={tableCellStyle}>*/}
            {/*                    {applicationData.keyContacts.itLead.emailAddress}</TableCell>*/}
            {/*                <TableCell*/}
            {/*                    style={tableCellStyle}>*/}
            {/*                    {applicationData.keyContacts.admin.emailAddress}</TableCell>*/}
            {/*            </TableRow>*/}
            {/*            <TableRow>*/}
            {/*                <TableCell style={tableHeaderStyle}>Phone Number</TableCell>*/}
            {/*                <TableCell>*/}
            {/*                    {applicationData.keyContacts.businessLead.mobilePhoneNumber}</TableCell>*/}
            {/*                <TableCell>*/}
            {/*                    {applicationData.keyContacts.financeLead.mobilePhoneNumber}</TableCell>*/}
            {/*                <TableCell>*/}
            {/*                    {applicationData.keyContacts.itLead.mobilePhoneNumber}</TableCell>*/}
            {/*                <TableCell>*/}
            {/*                    {applicationData.keyContacts.admin.mobilePhoneNumber}</TableCell>*/}
            {/*            </TableRow>*/}

            {/*        </TableBody>*/}
            {/*    </Table>*/}
            {/*</TableContainer>*/}
          </Container>

          <Container sx={{ pt: 4 }}>
            <Typography textAlign="left" style={sectionHeadingStyle}>
              Documents
            </Typography>
            <TableContainer sx={{ ml: -2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={tableHeaderStyle}>File</TableCell>
                    <TableCell style={tableHeaderStyle}>Type</TableCell>
                    <TableCell style={tableHeaderStyle}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Certificate of Incorporation
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell align="right">
                      <Button
                        style={secondaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() =>
                          handleGetFiles(
                            currentTask?.wfCurrTrans?.certOfIncorpDoc
                          )
                        }
                      >
                        VIEW
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      KRA Pin Certificate
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell align="right">
                      <Button
                        style={secondaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() =>
                          handleGetFiles(
                            currentTask?.wfCurrTrans?.companyPinDoc
                          )
                        }
                      >
                        VIEW
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      VAT Certificate
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell align="right">
                      <Button
                        style={secondaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() =>
                          handleGetFiles(
                            currentTask?.wfCurrTrans?.companyVatDoc
                          )
                        }
                      >
                        VIEW
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Company CR12
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell align="right">
                      <Button
                        style={secondaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() =>
                          handleGetFiles(currentTask?.wfCurrTrans?.cr12Doc)
                        }
                      >
                        VIEW
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      CAK License
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell align="right">
                      <Button
                        style={secondaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() =>
                          handleGetFiles(
                            currentTask?.wfCurrTrans?.cakLicenseDoc
                          )
                        }
                      >
                        VIEW
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Copy of Bank A/C Cheque Leaf
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell align="right">
                      <Button
                        style={secondaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() =>
                          handleGetFiles(
                            currentTask?.wfCurrTrans?.chequeLeafDoc
                          )
                        }
                      >
                        VIEW
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Business Proposal Document
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell align="right">
                      <Button
                        style={secondaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() =>
                          handleGetFiles(
                            currentTask?.wfCurrTrans?.businessProposalDoc
                          )
                        }
                      >
                        VIEW
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Minimum Baseline Security Requirements
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell align="right">
                      <Button
                        style={secondaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() =>
                          handleGetFiles(currentTask?.wfCurrTrans?.securityDoc)
                        }
                      >
                        VIEW
                      </Button>
                    </TableCell>
                  </TableRow>
                  {!(
                    currentTask?.wfActiveProcess == "vas_countercheck_task"
                  ) && (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Signed Contract
                      </TableCell>
                      <TableCell>PDF</TableCell>
                      <TableCell align="right">
                        <Button
                          style={secondaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                          onClick={() =>
                            handleGetFiles(
                              currentTask?.wfCurrTrans?.contractDoc
                            )
                          }
                        >
                          VIEW
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                  {!(
                    currentTask?.wfActiveProcess == "vas_countercheck_task"
                  ) && (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Payment Receipt
                      </TableCell>
                      <TableCell>PDF</TableCell>
                      <TableCell align="right">
                        <Button
                          style={secondaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                          onClick={() =>
                            handleGetFiles(currentTask?.wfCurrTrans?.receiptDoc)
                          }
                        >
                          VIEW
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>

          <Container sx={{ pt: 4 }}>
            <Typography textAlign="left" style={sectionHeadingStyle}>
              Director Details
            </Typography>
            <TableContainer sx={{ ml: -2, mt: 3 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Full Name</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>ID Number</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>KRA Pin</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      ID/Passport Copy
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      KRA Pin Certificate Copy
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentTask?.wfCurrTrans?.directorDetails.map(
                    (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>{row.fullName}</TableCell>
                        <TableCell>{row.kitambulisho}</TableCell>
                        <TableCell>{row.kraPin}</TableCell>
                        <TableCell align="left">
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() => handleGetFiles(row.identityDoc)}
                          >
                            VIEW
                          </Button>
                        </TableCell>
                        {row.pinDoc && (
                          <TableCell align="left">
                            <Button
                              style={secondaryButton}
                              variant="contained"
                              component="span"
                              disableElevation
                              onClick={() => handleGetFiles(row.pinDoc)}
                            >
                              VIEW
                            </Button>
                          </TableCell>
                        )}
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>

          <Container sx={{ pt: 4 }}>
            <Typography textAlign="left" style={sectionHeadingStyle}>
              Signatory Details
            </Typography>
            <TableContainer sx={{ ml: -2, mt: 3 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>Full Name</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Email Address
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>ID Number</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Phone Number
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentTask?.wfCurrTrans?.signatories.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell>{row.signatoryName}</TableCell>
                      <TableCell>{row.signatoryEmail}</TableCell>
                      <TableCell>{row.signatoryIdNumber}</TableCell>
                      <TableCell>{row.signatoryPhone}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>

          <Container sx={{ pt: 4 }}>
            <Typography textAlign="left" style={sectionHeadingStyle}>
              Other Applications
            </Typography>
            <TableContainer sx={{ ml: -2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={tableHeaderStyle}>Application</TableCell>
                    <TableCell style={tableHeaderStyle}>Actions</TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Supplier Creation Details
                    </TableCell>
                    <TableCell>
                      <Button
                        style={secondaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() =>
                          handleAdditionalApplication("supplierCreationDetails")
                        }
                      >
                        VIEW
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Supplier Bank Details
                    </TableCell>
                    <TableCell>
                      <Button
                        style={secondaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() =>
                          handleAdditionalApplication("supplierBankDetails")
                        }
                      >
                        VIEW
                      </Button>
                    </TableCell>
                  </TableRow>
                  {!(
                    currentTask?.wfActiveProcess == "vas_countercheck_task" ||
                    currentTask?.wfActiveProcess ==
                      "task_validate_uploaded_receipt"
                  ) && (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        VPN Configuration Details
                      </TableCell>
                      <TableCell>
                        <Button
                          style={secondaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                          onClick={() =>
                            handleAdditionalApplication(
                              "vpnConfigurationDetails"
                            )
                          }
                        >
                          VIEW
                        </Button>
                        <Button
                          sx={{ ml: 5 }}
                          style={primaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                          onClick={() => handleGetVpnFormReport()}
                        >
                          VIEW VPN DOCUMENT
                        </Button>
                      </TableCell>
                    </TableRow>
                  )}
                </TableHead>
                <TableBody></TableBody>
              </Table>
            </TableContainer>
          </Container>

          <Container sx={{ pt: 4 }}>
            <Typography textAlign="left" style={sectionHeadingStyle}>
              Business Value Proposition
            </Typography>
            <TableContainer sx={{ ml: -2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={tableHeaderStyle}>
                      Business Area
                    </TableCell>
                    <TableCell style={tableHeaderStyle}>
                      Financial Projections
                    </TableCell>
                    <TableCell style={tableHeaderStyle}>
                      Marketing Strategy
                    </TableCell>
                    <TableCell style={tableHeaderStyle}>
                      Target Market
                    </TableCell>
                    <TableCell style={tableHeaderStyle}>
                      Consumer Insight
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {currentTask?.wfCurrTrans?.businessValueProposition.map(
                    (row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell>{row.businessArea}</TableCell>
                        <TableCell>
                          {row.financialProjections?.year1},{" "}
                          {row.financialProjections?.year2},{" "}
                          {row.financialProjections?.year3}
                        </TableCell>
                        <TableCell>{row.marketingStrategies}</TableCell>
                        <TableCell>{row.targetMarket}</TableCell>
                        <TableCell>{row.consumerInsight}</TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Box sx={{ mb: 5 }}>
              <Typography
                textAlign="left"
                sx={{ fontWeight: "bold", mt: 3, mb: 2 }}
              >
                Business Value Propostion Assumptions
              </Typography>
              <Typography textAlign="left">
                {currentTask?.wfCurrTrans?.valuePropositionAssumptions}
              </Typography>
            </Box>
          </Container>
        </Grid>

        <Grid item xs={3}>
          {/* <Box sx={{ mb: 3 }}>
            <Button
              variant="contained"
              style={primaryButton}
              onClick={() => handleApproval("approveApplication")}
            >
              ACCEPT & FORWARD TO LEGAL
            </Button>
          </Box>

          <Button
            variant="contained"
            style={rejectButton}
            onClick={() => handleApproval("rejectApplication")}
          >
            REJECT APPLICATION
          </Button> */}

          <Box sx={{ mb: 3 }}>
            {currentTask?.wfActiveProcess == "vas_countercheck_task" && (
              <Box>
                <Button
                  variant="contained"
                  style={approveButton}
                  sx={{ mb: 3 }}
                  onClick={() => handleApproval("approveApplication")}
                >
                  ACCEPT & FORWARD TO LEGAL{" "}
                </Button>

                <Button
                  variant="contained"
                  style={rejectButton}
                  onClick={() => handleApproval("rejectApplication")}
                >
                  REJECT APPLICATION
                </Button>
              </Box>
            )}

            {currentTask?.wfActiveProcess ==
              "task_validate_uploaded_receipt" && (
              <Box>
                <Button
                  variant="contained"
                  style={approveButton}
                  sx={{ mb: 3 }}
                  onClick={() => handlePaymentApproval("approvePayment")}
                >
                  APPROVE PAYMENT RECEIPT{" "}
                </Button>

                <Button
                  variant="contained"
                  style={rejectButton}
                  onClick={() => handlePaymentApproval("rejectPayment")}
                >
                  REJECT PAYMENT RECEIPT
                </Button>
              </Box>
            )}

            {currentTask?.wfActiveProcess ==
              "task_map_partner_as_account_payable" && (
              <Box>
                <Button
                  variant="contained"
                  style={approveButton}
                  onClick={() => handleMarkAccountsPayable()}
                >
                  MARK ACCOUNT AS CREATED ON ACCOUNTS PAYABLE
                </Button>
              </Box>
            )}
            {currentTask?.wfActiveProcess == "task_attach_sdp_crq" && (
              <Box sx={{ mr: 2 }}>
                <TextField
                  error={crqNoError}
                  helperText={crqNoHelperText}
                  label="CRQ Number"
                  name="crqNo"
                  type="text"
                  size="small"
                  placeholder="e.g. CRQ000007808676"
                  style={inputStyle}
                  onChange={handleInput}
                  value={crqNo}
                  fullWidth
                  required
                />
                <Button
                  sx={{ mt: 3 }}
                  style={isValidCrq ? primaryButton : disabledButton}
                  onClick={() => handleAddCrqNo()}
                  disableElevation
                  disabled={!isValidCrq}
                >
                  ADD CRQ
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="additional-applications-modal"
        aria-describedby="additional-applications-modal-description"
        open={additionalApplicationsSelection}
        onClose={handleCloseAdditionalApplicationsModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={additionalApplicationsSelection !== ""}>
          <Box style={modalStyle2} sx={{ p: 5 }}>
            {additionalApplicationsSelection === "supplierCreationDetails" && (
              <Typography
                id="additional-applications-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                Supplier Creation Details
              </Typography>
            )}
            {additionalApplicationsSelection === "supplierBankDetails" && (
              <Typography
                id="additional-applications-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                Supplier Bank Details
              </Typography>
            )}

            {!(
              currentTask?.wfActiveProcess == "vas_countercheck_task" ||
              currentTask?.wfActiveProcess == "task_validate_uploaded_receipt"
            ) &&
              additionalApplicationsSelection === "vpnConfigurationDetails" && (
                <Typography
                  id="additional-applications-modal"
                  variant="h6"
                  component="h2"
                  sx={{ color: "#159F4A", fontWeight: "bold" }}
                >
                  VPN Configuration Details
                </Typography>
              )}

            {additionalApplicationsSelection === "supplierCreationDetails" && (
              <Box spacing={4}>
                <TableContainer sx={{ ml: -2, mt: 3 }}>
                  <Table sx={{ width: 600 }} aria-label="simple table">
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Category of Products/Service
                      </TableCell>
                      <TableCell>
                        {currentTask?.wfCurrTrans?.categoryOfService.map(
                          (row, index) => (
                            <Typography
                              key={index}
                              sx={{
                                pb: 1,
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              - {row?.categoryName}
                            </Typography>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Terms of Payment
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.termsOfPayment}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Supplier References
                      </TableCell>
                      <TableCell>
                        {currentTask?.wfCurrTrans?.references.map(
                          (row, index) => (
                            <Typography
                              key={index}
                              sx={{
                                pb: 1,
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              - {row?.referenceName}
                            </Typography>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>

                {/* <Grid container sx={{ pt: 2 }}>
                  <Grid item xs={6}>
                    <List>
                      {[
                        "Category of Products/Service",
                        "Terms of Payment",
                        "Supplier References",
                      ].map((value) => {
                        const listItemId = `${value}`;
                        return (
                          <ListItem sx={{ p: 1 }}>
                            <Typography
                              id={listItemId}
                              sx={{ fontWeight: "bold" }}
                            >
                              {`${value}`}
                            </Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <List>
                      <ListItem>
                        <Typography>
                          {/* {
                            currentTask?.wfCurrTrans?.categoryOfService[0]
                              ?.categoryName
                          } */}
                {/* {currentTask?.wfCurrTrans?.categoryOfService.map(
                            (item, index) => (
                              <React.Fragment key={index}>
                                {item.categoryName}, &nbsp;
                              </React.Fragment>
                            )
                          )}
                        </Typography>
                      </ListItem> */}
                {/* <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.transactionCurrency
                              ?.currencyName
                          }
                        </Typography>
                      </ListItem> */}
                {/* <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.termsOfPayment}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.references.map(
                            (item, index) => (
                              <React.Fragment key={index}>
                                {item.referenceName},&nbsp;
                              </React.Fragment>
                            )
                          )}
                        </Typography>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid> */}
              </Box>
            )}

            {additionalApplicationsSelection === "supplierBankDetails" && (
              <Box spacing={5}>
                <TableContainer sx={{ ml: -2, mt: 3 }}>
                  <Table sx={{ width: 500 }} aria-label="simple table">
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Transaction Currency
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.bankDetails
                            ?.transactionCurrency?.currencyName
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Bank Name
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.bankDetails?.bankName}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Bank Branch
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.bankDetails?.bankBranch}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Account Name
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.bankDetails?.accountName}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Bank Account Number
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.bankDetails?.accountNo}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Bank Routing Code
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.bankDetails?.bankRoutingCode}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Bank Routing Method
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.bankDetails
                            ?.bankRoutingMethod
                        }
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Bank Swift Code
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.bankDetails?.swiftCode}
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>

                {/* <Grid container sx={{ pt: 2 }}>
                  <Grid item xs={6}>
                    <List>
                      {[
                        "Bank Name",
                        "Bank Branch",
                        "Account Name",
                        "Bank Account Number",
                        "Bank Routing Code",
                        "Bank Routing Method",
                        "Bank Swift Code",
                      ].map((value) => {
                        const listItemId = `${value}`;
                        return (
                          <ListItem sx={{ p: 1 }}>
                            <Typography
                              id={listItemId}
                              sx={{ fontWeight: "bold" }}
                            >
                              {`${value}`}
                            </Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <List>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.bankDetails?.bankName}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.bankDetails?.bankBranch}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.bankDetails?.accountName}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.bankDetails?.accountNo}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.bankDetails
                              ?.bankRoutingCode
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.bankDetails
                              ?.bankRoutingMethod
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.bankDetails?.swiftCode}
                        </Typography>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid> */}
              </Box>
            )}

            {!(
              currentTask?.wfActiveProcess == "vas_countercheck_task" ||
              currentTask?.wfActiveProcess == "task_validate_uploaded_receipt"
            ) &&
              additionalApplicationsSelection === "vpnConfigurationDetails" && (
                <Box spacing={5}>
                  <TableContainer sx={{ ml: -2, mt: 3 }}>
                    <Table sx={{ width: 500 }} aria-label="simple table">
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Supplier
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.supplier}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.type}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Model</TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.model}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>OS</TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.os}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Peer Address
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.peerAddress
                          }
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Test Peer Address
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.testPeerAddress
                          }
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Proposal Name
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.proposalname
                          }
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Authentication Mode
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationMode
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Pre-shared Key
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.preSharedKey
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Authentication Algorithm
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationAlgorithm
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Encryption Algorithm
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.encryptionAlgorithm
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Diffie-Hellman Group
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.diffieHellmanGroup
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Lifetime Measurement
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeMeasurement
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Lifetime
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.lifetime}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Authentication Algorithm (ESP/SHA)
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationAlgorithmEsp
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Encryption Algorithm (AES 128)
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.encryptionAlgorithmAes
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Encapsulation Mode
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.encapsulationModeEsp
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Perfect Forward Secrecy
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.perfectForwardSecrecy
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Lifetime Measurement
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeMeasurementEsp
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Lifetime
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeEsp
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Network
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.network}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Comment
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.comment}
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>

                  {/* <Grid container sx={{ pt: 2 }}>
                  <Grid item xs={6}>
                    <List>
                      {[
                        "Supplier",
                        "Type",
                        "Model",
                        "OS",
                        "Peer Address",
                        "Test Peer Address",
                        "Proposal Name",
                        "Authentication Mode",
                        "Pre-shared Key",
                        "Authentication Algorithm",
                        "Encryption Algorithm",
                        "Diffie-Hellman Group",
                        "Lifetime Measurement",
                        "Lifetime",
                        "Authentication Algorithm (ESP/SHA)",
                        "Encryption Algorithm (AES 128)",
                        "Encapsulation Mode",
                        "Perfect Forward Secrecy",
                        "Lifetime Measurement",
                        "Lifetime",
                        "Network",
                        "Comment",
                      ].map((value) => {
                        const listItemId = `${value}`;
                        return (
                          <ListItem sx={{ p: 1 }}>
                            <Typography
                              id={listItemId}
                              sx={{ fontWeight: "bold" }}
                            >
                              {`${value}`}
                            </Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <List>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.supplier}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.type}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.model}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.os}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.peerAddress
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.testPeerAddress
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.proposalname
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationMode
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.preSharedKey
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationAlgorithm
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.encryptionAlgorithm
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.diffieHellmanGroup
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeMeasurement
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.lifetime}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationAlgorithmEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.encryptionAlgorithmAes
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.encapsulationModeEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.perfectForwardSecrecy
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeMeasurementEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.network}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.comment}
                        </Typography>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid> */}
                </Box>
              )}
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="approval-modal"
        aria-describedby="approval-modal-description"
        open={currentSelection}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={currentSelection !== ""}>
          <Box style={modalStyle} sx={{ p: 5 }}>
            {currentSelection === "approveApplication" && (
              <Typography
                id="approval-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                Approve Application
              </Typography>
            )}

            {currentSelection === "rejectApplication" && (
              <Typography
                id="approval-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                Reject Application
              </Typography>
            )}

            <Box spacing={5} sx={{ mt: 3 }}>
              <TextField
                label="Add Comment"
                name="comment"
                value={comment}
                onChange={handleInput}
                size="small"
                style={inputStyle}
                fullWidth
                multiline
                required
              />
            </Box>

            <Box>
              <Button
                style={isValidData ? primaryButton : disabledButton}
                onClick={() => handleSubmitApproval()}
                disabled={!isValidData}
              >
                CONFIRM
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="payment-verification-modal"
        aria-describedby="payment-verification-modal-description"
        open={currentPaymentApprovalSelection}
        onClose={handleClosePaymentApprovalModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={currentPaymentApprovalSelection !== ""}>
          <Box style={modalStyle} sx={{ p: 5 }}>
            {currentPaymentApprovalSelection === "approvePayment" && (
              <Typography
                id="payment-verification-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                Approve Payment
              </Typography>
            )}

            {currentPaymentApprovalSelection === "rejectPayment" && (
              <Typography
                id="payment-verification-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                Reject Payment
              </Typography>
            )}

            <Box spacing={5} sx={{ mt: 4 }}>
              <TextField
                label="Add Comment"
                name="comment"
                value={comment}
                onChange={handleInput}
                size="small"
                style={inputStyle}
                fullWidth
                multiline
                required
              />
            </Box>

            <Box>
              <Button
                style={isValidData ? primaryButton : disabledButton}
                onClick={() => handleVerifyPayment()}
                disabled={!isValidData}
              >
                CONFIRM
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      {/* {fileData && <DocumentViewer fileData={fileData} />} */}

      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
