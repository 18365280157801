import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Grid,
} from "@mui/material";
import {
  primaryButton,
  secondaryButton,
  disabledButton,
} from "../../../../common-styles";
import { Delete, Edit } from "@mui/icons-material";
import {
  inputStyle,
  formTitleStyle,
  formSubtitleStyle,
  statusSuccessStyle,
  statusFailStyle,
} from "../formStyles";
import apiRequest from "../../../services/api-request";
import validator from "validator";
import * as Email from "email-validator";

export default function PartB(props) {
  const { handleBack, handleNext, handleCompanyData } = props;

  let { applications } = props;

  delete applications[0]?.company?.application;

  const { companyId } = applications.length > 0 ? applications[0]?.company : "";

  const initialState = {
    currentSection: "B1",
    companyId: "",
    vpnConfigurationForm: {},
    currentDirectorDetails: {},
    currentSignatoryDetails: {},
    directorDetails: [],
    directorFullNameError: false,
    directorFullNameHelperText: "",
    directorIdNumberError: false,
    directorIdNumberHelperText: "",
    directorPinNumberError: false,
    directorPinNumberHelperText: "",
    signatories: [],
    signatoryNameError: false,
    signatoryNameHelperText: "",
    signatoryEmailError: false,
    signatoryEmailHelperText: "",
    signatoryPhoneNumberError: false,
    signatoryPhoneNumberHelperText: "",
    signatoryIdNumberError: false,
    signatoryIdNumberHelperText: "",
    certOfIncorpDoc: "",
    companyPinDoc: "",
    companyVatDoc: "",
    cr12Doc: "",
    cakLicenseDoc: "",
    chequeLeafDoc: "",
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,
    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
    loading: false,
  };

  const [state, setState] = useState(initialState);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isUpdatingSignatory, setIsUpdatingSignatory] = useState(false);

  // const [value, setValue] = useState<Date | null>(null);

  const {
    currentSection,
    directorDetails,
    currentDirectorDetails,
    currentSignatoryDetails,
    signatories,
    certOfIncorpDoc,
    companyPinDoc,
    companyVatDoc,
    cr12Doc,
    cakLicenseDoc,
    chequeLeafDoc,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
    loading,
  } = state;

  const { fullName, kitambulisho, kraPin, pinDoc, identityDoc } =
    currentDirectorDetails;

  const { signatoryName, signatoryEmail, signatoryPhone, signatoryIdNumber } =
    currentSignatoryDetails;

  const directorFullNameError =
    !fullName ||
    !validator.isAlpha(fullName, ["en-US"], { ignore: " -'" }) ||
    fullName?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const directorFullNameHelperText = directorFullNameError
    ? "Required/Invalid"
    : "";

  const directorPinNumberError =
    !kraPin ||
    !validator.isAlphanumeric(kraPin) ||
    kraPin?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const directorPinNumberHelperText = directorPinNumberError ? "Invalid" : "";

  const directorIdNumberError =
    !kitambulisho ||
    !validator.isAlphanumeric(kitambulisho) ||
    kitambulisho?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TEN;
  const directorIdNumberHelperText = directorIdNumberError
    ? "Required/Invalid"
    : "";

  const directorIdDocError = !identityDoc;
  const directorIdDocHelperText = directorIdDocError ? "Required" : "";

  const directorPinDocError = !pinDoc;
  const directorPinDocHelperText = directorPinDocError ? "Required" : "";

  const signatoryNameError =
    !signatoryName ||
    !validator.isAlpha(signatoryName, ["en-US"], { ignore: " -'" }) ||
    signatoryName?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const signatoryNameHelperText = signatoryNameError ? "Required/Invalid" : "";

  const signatoryEmailError =
    !signatoryEmail || !Email.validate(signatoryEmail);
  const signatoryEmailHelperText = signatoryEmailError
    ? "Required/Should contain company domain name/"
    : "";

  const signatoryPhoneNumberError =
    !signatoryPhone ||
    !(signatoryPhone > 0) ||
    !validator.isNumeric(signatoryPhone.toString(), {
      no_symbols: true,
    }) ||
    signatoryPhone?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTEEN;
  const signatoryPhoneNumberHelperText = signatoryPhoneNumberError
    ? "Required/Invalid/Numbers only"
    : "";

  const signatoryIdNumberError =
    !signatoryIdNumber ||
    !validator.isAlphanumeric(signatoryIdNumber) ||
    signatoryIdNumber?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const signatoryIdNumberHelperText = signatoryIdNumberError
    ? "Required/Invalid"
    : "";

  const isValidDirectorDetails = () => {
    let isValid = false;

    for (let index = 0; index < directorDetails.length; index++) {
      const director = directorDetails[index];

      const directorFullNameError =
        !director?.fullName ||
        !validator.isAlpha(director?.fullName, ["en-US"], { ignore: " -'" }) ||
        director?.fullName?.length >
          process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;

      const directorIdNumberError =
        !director?.kitambulisho ||
        !validator.isAlphanumeric(director?.kitambulisho) ||
        director?.kitambulisho?.length >
          process.env.REACT_APP_MAX_INPUT_LENGTH_TEN;

      const directorPinNumberError =
        !director?.kraPin ||
        !validator.isAlphanumeric(director?.kraPin) ||
        director?.kraPin?.length >
          process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;

      const directorIdDocError = !director?.identityDoc;
      const directorPinDocError = !director?.pinDoc;

      isValid =
        !directorFullNameError && !directorIdNumberError && !directorIdDocError;

      if (!isValid) {
        break;
      }
    }
    return isValid;
  };

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.logname, value);

    let { currentDirectorDetails, currentSignatoryDetails } = state;

    if (currentSection === "B2") {
      currentDirectorDetails = { ...currentDirectorDetails, [name]: value };
    }

    if (currentSection === "B3") {
      currentSignatoryDetails = { ...currentSignatoryDetails, [name]: value };
    }

    setState((prevState) => ({
      ...prevState,
      currentDirectorDetails,
      currentSignatoryDetails,
    }));
  };

  const isEmpty = (data) => {
    let response = true;
    if (data) {
      response = false;
    }
    return response;
  };

  const isValidData =
    isValidDirectorDetails() &&
    // !isEmpty(directorDetails[0]?.fullName) &&
    !isEmpty(signatories[0]?.signatoryName) &&
    !isEmpty(certOfIncorpDoc) &&
    !isEmpty(companyPinDoc) &&
    !isEmpty(companyVatDoc) &&
    !isEmpty(cr12Doc) &&
    !isEmpty(cakLicenseDoc) &&
    !isEmpty(chequeLeafDoc);

  const handleAccordionChange = (currentSection) => {
    setState((prevState) => ({
      ...prevState,
      currentSection,
    }));
  };

  // const removeDuplicateDirectorsFromArrayOfObjects = (objArray) => {
  //   if (objArray?.length < 1) return [];

  //   let uniqueObjArray = [
  //     ...new Map(objArray.map((item) => [item["kitambulisho"], item])).values(),
  //   ];

  //   // if (uniqueObjArray?.length < 1) return [];

  //   // uniqueObjArray = [
  //   //   ...new Map(uniqueObjArray.map((item) => [item["kraPin"], item])).values(),
  //   // ];

  //   return uniqueObjArray;
  // };

  const removeDuplicateSignatoriesFromArrayOfObjects = (objArray) => {
    if (objArray?.length < 1) return [];

    let uniqueObjArray = [
      ...new Map(
        objArray.map((item) => [item["signatoryEmail"], item])
      ).values(),
    ];

    if (uniqueObjArray?.length < 1) return [];

    uniqueObjArray = [
      ...new Map(
        uniqueObjArray.map((item) => [item["signatoryPhone"], item])
      ).values(),
    ];

    if (uniqueObjArray?.length < 1) return [];

    uniqueObjArray = [
      ...new Map(
        uniqueObjArray.map((item) => [item["signatoryIdNumber"], item])
      ).values(),
    ];

    return uniqueObjArray;
  };

  const removeDuplicateDirectorsFromArrayOfObjects = (objArray) => {
    if (objArray?.length < 1) return [];

    let uniqueObjArray = [
      ...new Map(objArray.map((item) => [item["kitambulisho"], item])).values(),
    ];

    return uniqueObjArray;
  };

  const handleAddDirectorDetails = () => {
    let { directorDetails, currentDirectorDetails } = state;

    if (isUpdating) {
      const indexOfDirector = directorDetails.findIndex((director) => {
        return director?.directorId === currentDirectorDetails?.directorId;
      });
      directorDetails[indexOfDirector] = currentDirectorDetails;
      setIsUpdating(false);
    } else {
      directorDetails.push(currentDirectorDetails);
    }
    directorDetails =
      removeDuplicateDirectorsFromArrayOfObjects(directorDetails);
    setState((prevState) => ({
      ...prevState,
      directorDetails,
      currentDirectorDetails: {},
    }));
  };

  const handleSelectDirector = (director) => {
    setIsUpdating(true);
    setState((prevState) => ({
      ...prevState,
      currentDirectorDetails: director,
    }));
  };

  const handleAddSignatoryDetails = () => {
    let { signatories, currentSignatoryDetails } = state;

    if (isUpdatingSignatory) {
      const indexOfSignatory = signatories.findIndex((signatory) => {
        return signatory?.signatoryId === currentSignatoryDetails?.signatoryId;
      });
      signatories[indexOfSignatory] = currentSignatoryDetails;
      setIsUpdatingSignatory(false);
    } else {
      signatories.push(currentSignatoryDetails);
    }
    signatories = removeDuplicateSignatoriesFromArrayOfObjects(signatories);
    setState((prevState) => ({
      ...prevState,
      signatories,
      currentSignatoryDetails: {},
    }));
  };

  const handleSelectSignatory = (signatory) => {
    setIsUpdatingSignatory(true);
    setState((prevState) => ({
      ...prevState,
      currentSignatoryDetails: signatory,
    }));
  };

  // const handleAddDirectorDetails = () => {
  //   let { directorDetails, currentDirectorDetails } = state;
  //   console.log({currentDirectorDetails})

  //   if (currentDirectorDetails?.directorId) {
  //     const indexOfDirector = directorDetails.findIndex((director) => {
  //       return director?.directorId == currentDirectorDetails?.directorId;
  //     });
  //     console.log({indexOfDirector})
  //     directorDetails[indexOfDirector] = currentDirectorDetails;
  //   } else {
  //     directorDetails = [{ ...currentDirectorDetails }, ...directorDetails];
  //   }
  //   directorDetails =
  //     removeDuplicateDirectorsFromArrayOfObjects(directorDetails);
  //   console.log({ directorDetails });

  //   setState((prevState) => ({
  //     ...prevState,
  //     directorDetails,
  //     currentDirectorDetails: {},
  //   }));
  //   console.log({currentDirectorDetails})
  // };

  // const handleAddSignatoryDetails = () => {
  //   let { signatories, currentSignatoryDetails } = state;
  //   if (currentSignatoryDetails?.signatoryId) {
  //     const indexOfSignatory = signatories.findIndex((signatory) => {
  //       return signatory?.signatoryId == currentSignatoryDetails?.signatoryId;
  //     });
  //     signatories[indexOfSignatory] = currentSignatoryDetails;
  //   } else {
  //     signatories = [{ ...currentSignatoryDetails }, ...signatories];
  //   }
  //   signatories = removeDuplicateSignatoriesFromArrayOfObjects(signatories);
  //   setState((prevState) => ({
  //     ...prevState,
  //     signatories,
  //     currentSignatoryDetails: {},
  //   }));
  // };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  useEffect(() => {
    if (!applications.length > 0) {
      (async () => {
        applications = await handleCompanyData();
      })();
    }
    // console.log({applications})
    if (applications[0]?.company) {
      const {
        companyId,
        directorDetails,
        signatories,
        certOfIncorpDoc,
        companyPinDoc,
        companyVatDoc,
        cr12Doc,
        cakLicenseDoc,
        chequeLeafDoc,
      } = applications[0]?.company;
      setState((prevState) => ({
        ...prevState,
        companyId,
        directorDetails,
        signatories,
        certOfIncorpDoc,
        companyPinDoc,
        companyVatDoc,
        cr12Doc,
        cakLicenseDoc,
        chequeLeafDoc,
      }));
    }
  }, []);

  const handleApiData = async (params) => {
    // console.log{params});
    const { data, dataName, severity, message } = params;
    // console.log({params});

    if (severity === "success") {
      if (
        dataName === "certOfIncorpDoc" ||
        dataName === "companyPinDoc" ||
        dataName === "companyVatDoc" ||
        dataName === "cr12Doc" ||
        dataName === "cakLicenseDoc" ||
        dataName === "chequeLeafDoc"
      ) {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.docInfo?.sysDocid,
        }));
      }

      if (dataName === "identityDoc" || dataName === "pinDoc") {
        setState((prevState) => ({
          ...prevState,
          currentDirectorDetails: {
            ...currentDirectorDetails,
            [dataName]: data?.docInfo?.sysDocid,
          },
        }));
      }

      if (dataName === "deletedDirector" || dataName === "deletedSignatory") {
        handleCompanyData();
      }

      if (dataName === "updateCompany") {
        handleCompanyData();

        if (isValidData) {
          await handleNext();
        }
      }
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleFileUpload = async (params) => {
    const { event, docType } = params;
    if (event?.target?.files) {
      const file = event.target.files[0];
      const fileExtension = file.type;
      const allowedFiles = process.env.REACT_APP_ALLOWED_UPLOAD_FILE_TYPES;

      // console.log({ fileExtension });
      if (
        fileExtension &&
        allowedFiles
          .toLocaleLowerCase()
          .includes(fileExtension.toLocaleLowerCase())
      ) {
        const data = new FormData();
        data.append("file", file);
        data.append("companyId", companyId);
        data.append("docNo", "0");
        data.append("docType", docType);
        data.append("module", "companyDocs");
        await makeApiRequest({
          uri: `/upload`,
          dataName: docType,
          method: `post`,
          data,
        });
      } else {
        alert(`${fileExtension} Not allowed. You can only upload a .pdf file `);
        return;
      }
    }
  };

  const handleUpdateCompany = async () => {
    await makeApiRequest({
      uri: `/kyc/company/${companyId}`,
      dataName: `updateCompany`,
      method: `put`,
      data: {
        ...applications[0]?.company,
        directorDetails,
        signatories,
        certOfIncorpDoc,
        companyPinDoc,
        companyVatDoc,
        cr12Doc,
        cakLicenseDoc,
        chequeLeafDoc,
        applicationComplete: false,
      },
    });
  };

  const handleDeleteDirector = async (directorId) => {
    await makeApiRequest({
      uri: `/kyc/company/${companyId}/directorDetails/${directorId}`,
      dataName: `deletedDirector`,
      method: `delete`,
      data: {},
    });
  };

  const handleDeleteSignatory = async (signatoryId) => {
    await makeApiRequest({
      uri: `/kyc/company/${companyId}/signatories/${signatoryId}`,
      dataName: `deletedSignatory`,
      method: `delete`,
      data: {},
    });
  };

  return (
    <>
      <Box sx={{ pt: 5, pl: 3, pb: 10 }}>
        <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
          <CircularProgress color="success" />
        </Backdrop>

        <Typography textAlign="left" style={formTitleStyle} sx={{ pt: 5 }}>
          PART B
        </Typography>
        <Typography
          sx={{
            pb: 3,
            fontWeight: "bold",
            fontSize: "14px",
            textDecoration: "underline",
          }}
        >
          Note: Fill in all 3 sections to be able to proceed to next step
        </Typography>

        <Accordion
          expanded={currentSection === "B1"}
          onChange={() => handleAccordionChange("B1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography textAlign="left" style={formSubtitleStyle}>
              B1: Upload Mandatory Documents
            </Typography>
          </AccordionSummary>
          <Typography textAlign="left" sx={{ ml: 2 }}>
            NB: ALL copies must be certified by your lawyer
          </Typography>
          <AccordionDetails>
            <TableContainer sx={{ mt: 3 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>File</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                    <TableCell align="right"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Certificate of Incorporation/Certificate of Registration
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell>
                      <Typography
                        style={
                          certOfIncorpDoc ? statusSuccessStyle : statusFailStyle
                        }
                      >
                        {certOfIncorpDoc
                          ? "Uploaded successfully"
                          : "Not uploaded"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <label htmlFor="certOfIncorpDoc">
                        <input
                          type="file"
                          id="certOfIncorpDoc"
                          accept="application/pdf"
                          style={{ display: "none" }}
                          onChange={(event) =>
                            handleFileUpload({
                              event,
                              docType: `certOfIncorpDoc`,
                            })
                          }
                        />
                        <Button
                          style={secondaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                        >
                          UPLOAD
                        </Button>
                      </label>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Company PIN Certificate
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell>
                      <Typography
                        style={
                          companyPinDoc ? statusSuccessStyle : statusFailStyle
                        }
                      >
                        {companyPinDoc
                          ? "Uploaded successfully"
                          : "Not uploaded"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <label htmlFor="pin-certificate">
                        <input
                          type="file"
                          id="pin-certificate"
                          accept="application/pdf"
                          style={{ display: "none" }}
                          onChange={(event) =>
                            handleFileUpload({
                              event,
                              docType: `companyPinDoc`,
                            })
                          }
                        />
                        <Button
                          style={secondaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                        >
                          UPLOAD
                        </Button>
                      </label>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Company VAT Certificate/Tax Compliance Certificate
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell>
                      <Typography
                        style={
                          companyVatDoc ? statusSuccessStyle : statusFailStyle
                        }
                      >
                        {companyVatDoc
                          ? "Uploaded successfully"
                          : "Not uploaded"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <label htmlFor="vat-certificate">
                        <input
                          type="file"
                          id="vat-certificate"
                          accept="application/pdf"
                          style={{ display: "none" }}
                          onChange={(event) =>
                            handleFileUpload({
                              event,
                              docType: `companyVatDoc`,
                            })
                          }
                        />
                        <Button
                          style={secondaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                        >
                          UPLOAD
                        </Button>
                      </label>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Company cr12Doc (Last 3 months)
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell>
                      <Typography
                        style={cr12Doc ? statusSuccessStyle : statusFailStyle}
                      >
                        {cr12Doc ? "Uploaded successfully" : "Not uploaded"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <label htmlFor="cr12">
                        <input
                          type="file"
                          id="cr12"
                          accept="application/pdf"
                          style={{ display: "none" }}
                          onChange={(event) =>
                            handleFileUpload({
                              event,
                              docType: `cr12Doc`,
                            })
                          }
                        />
                        <Button
                          style={secondaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                        >
                          UPLOAD
                        </Button>
                      </label>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      CAK License
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell>
                      <Typography
                        style={
                          cakLicenseDoc ? statusSuccessStyle : statusFailStyle
                        }
                      >
                        {cakLicenseDoc
                          ? "Uploaded successfully"
                          : "Not uploaded"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <label htmlFor="cakLicenseDoc">
                        <input
                          type="file"
                          id="cakLicenseDoc"
                          accept="application/pdf"
                          style={{ display: "none" }}
                          onChange={(event) =>
                            handleFileUpload({
                              event,
                              docType: `cakLicenseDoc`,
                            })
                          }
                        />
                        <Button
                          style={secondaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                        >
                          UPLOAD
                        </Button>
                      </label>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Copy of Bank A/C cheque leaf/Bank Letter
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell>
                      <Typography
                        style={
                          chequeLeafDoc ? statusSuccessStyle : statusFailStyle
                        }
                      >
                        {chequeLeafDoc
                          ? "Uploaded successfully"
                          : "Not uploaded"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <label htmlFor="chequeLeafDoc">
                        <input
                          type="file"
                          id="chequeLeafDoc"
                          accept="application/pdf"
                          style={{ display: "none" }}
                          onChange={(event) =>
                            handleFileUpload({
                              event,
                              docType: `chequeLeafDoc`,
                            })
                          }
                        />
                        <Button
                          style={secondaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                        >
                          UPLOAD
                        </Button>
                      </label>
                    </TableCell>
                  </TableRow>
                  {/* <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Detailed Business Plan
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell>
                      <Typography
                        style={
                          businessProposalDoc
                            ? statusSuccessStyle
                            : statusFailStyle
                        }
                      >
                        {businessProposalDoc
                          ? "Uploaded successfully"
                          : "Not uploaded"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Link
                        href={`${process.env.REACT_APP_HOMEPAGE}/files/BusinessPlanTemplate.docx`}
                        target="_blank"
                        download
                        sx={{ cursor: "pointer" }}
                      >
                        <Button style={primaryButton} sx={{ mr: 2 }}>
                          DOWNLOAD TEMPLATE
                        </Button>
                      </Link>
                      <label htmlFor="businessProposalDoc">
                        <input
                          type="file"
                          id="businessProposalDoc"
                          accept="application/pdf"
                          style={{ display: "none" }}
                          onChange={(event) =>
                            handleFileUpload({
                              event,
                              docType: `businessProposalDoc`,
                            })
                          }
                        />
                        <Button
                          style={secondaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                        >
                          UPLOAD
                        </Button>
                      </label>
                    </TableCell>
                  </TableRow> */}
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Box sx={{ mt: 4 }}>
          <Accordion
            expanded={currentSection === "B2"}
            onChange={() => handleAccordionChange("B2")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography textAlign="left" style={formSubtitleStyle}>
                B2: Director Details (As listed in the company CR12 document)
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ pl: 4, pr: 4, pb: 4 }}>
              <Stack direction="row" spacing={5}>
                <TextField
                  error={directorFullNameError}
                  helperText={directorFullNameHelperText}
                  label="Full Name"
                  name="fullName"
                  type="text"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  value={currentDirectorDetails?.fullName ?? ""}
                  fullWidth
                  required
                />
                <TextField
                  error={directorIdNumberError}
                  helperText={directorIdNumberHelperText}
                  label="ID/Passport Number"
                  name="kitambulisho"
                  type="text"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  value={currentDirectorDetails?.kitambulisho ?? ""}
                  fullWidth
                  required
                />
                <TextField
                  error={directorPinNumberError}
                  helperText={directorPinNumberHelperText}
                  label="KRA Pin (Not mandatory for international directors)"
                  name="kraPin"
                  type="text"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  value={currentDirectorDetails?.kraPin ?? ""}
                  fullWidth
                  required
                />
              </Stack>
              <Box>
                {/* <Grid item xs={6}> */}
                <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                  Upload Copy of ID/Passport
                </Typography>
                <TableContainer sx={{ mt: 3 }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>File</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Status
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          ID/Passport Copy
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell>
                          <Typography
                            style={
                              identityDoc ? statusSuccessStyle : statusFailStyle
                            }
                          >
                            {identityDoc
                              ? "Uploaded successfully"
                              : "Not uploaded"}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <label htmlFor="identityDoc">
                            <input
                              type="file"
                              id="identityDoc"
                              accept="application/pdf"
                              style={{ display: "none" }}
                              onChange={(event) =>
                                handleFileUpload({
                                  event,
                                  docType: `identityDoc`,
                                })
                              }
                            />
                            <Button
                              style={secondaryButton}
                              variant="contained"
                              component="span"
                              disableElevation
                            >
                              UPLOAD
                            </Button>
                          </label>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* </Grid> */}
                {/* <Grid item xs={6}> */}

                <Typography
                  sx={{ fontWeight: "bold", fontSize: "14px", mt: 4 }}
                >
                  Upload Copy of KRA Pin Certificate (Not mandatory for
                  international directors)
                </Typography>

                <TableContainer sx={{ mt: 3 }}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>File</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Status
                        </TableCell>
                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          KRA Pin Certificate
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell>
                          <Typography
                            style={
                              pinDoc ? statusSuccessStyle : statusFailStyle
                            }
                          >
                            {pinDoc ? "Uploaded successfully" : "Not uploaded"}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <label htmlFor="pinDoc">
                            <input
                              type="file"
                              id="pinDoc"
                              accept="application/pdf"
                              style={{ display: "none" }}
                              onChange={(event) =>
                                handleFileUpload({
                                  event,
                                  docType: `pinDoc`,
                                })
                              }
                            />
                            <Button
                              style={secondaryButton}
                              variant="contained"
                              component="span"
                              disableElevation
                            >
                              UPLOAD
                            </Button>
                          </label>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* </Grid> */}
              </Box>

              <Stack>
                <Box sx={{ mt: 2 }}>
                  <Button
                    style={
                      directorFullNameError ||
                      directorIdNumberError ||
                      directorIdDocError
                        ? disabledButton
                        : primaryButton
                    }
                    onClick={() => handleAddDirectorDetails()}
                    disabled={
                      directorFullNameError ||
                      directorIdNumberError ||
                      directorIdDocError
                    }
                  >
                    {isUpdating ? "UPDATE" : "ADD"}
                  </Button>
                </Box>
              </Stack>

              <TableContainer sx={{ mt: 5, backgroundColor: "#f9f9f9" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Full Name
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        ID Number
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>KRA Pin</TableCell>
                      <TableCell align="right">Edit Director</TableCell>
                      <TableCell align="right">Delete Director</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {directorDetails?.map((director, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography>{director?.fullName}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{director?.kitambulisho}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography style={{ color: "#159F4A" }}>
                            {director?.kraPin}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="edit"
                            size="large"
                            onClick={() => {
                              handleSelectDirector(director);
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="delete"
                            size="large"
                            sx={{ color: "#EA2337" }}
                            onClick={() => {
                              handleDeleteDirector(director?.directorId);

                              const { directorDetails } = state;

                              const indexOfDirector = directorDetails.findIndex(
                                (currentDirector) => {
                                  return (
                                    currentDirector?.directorId ==
                                    director?.directorId
                                  );
                                }
                              );

                              directorDetails.splice(indexOfDirector, 1);

                              setState((prevState) => ({
                                ...prevState,
                                directorDetails,
                              }));
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box sx={{ mt: 4 }}>
          <Accordion
            expanded={currentSection === "B3"}
            onChange={() => handleAccordionChange("B3")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography textAlign="left" style={formSubtitleStyle}>
                B3: Signatories' Details (One or 2 representatives to sign
                contract between Safaricom and partner)
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Stack direction="row" spacing={5}>
                <TextField
                  error={signatoryNameError}
                  helperText={signatoryNameHelperText}
                  label="Full Name"
                  name="signatoryName"
                  type="text"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  fullWidth
                  required
                  value={signatoryName ?? ""}
                />
                <TextField
                  error={signatoryEmailError}
                  helperText={signatoryEmailHelperText}
                  label="Email Address"
                  name="signatoryEmail"
                  type="email"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  fullWidth
                  required
                  value={signatoryEmail ?? ""}
                />
                <TextField
                  error={signatoryPhoneNumberError}
                  helperText={signatoryPhoneNumberHelperText}
                  label="Phone Number"
                  name="signatoryPhone"
                  type="tel"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  fullWidth
                  required
                  value={signatoryPhone ?? ""}
                />
                <TextField
                  error={signatoryIdNumberError}
                  helperText={signatoryIdNumberHelperText}
                  label="ID/Passport Number"
                  name="signatoryIdNumber"
                  type="text"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  fullWidth
                  required
                  value={signatoryIdNumber ?? ""}
                />
                <Box>
                  <Button
                    style={
                      signatoryIdNumberError ||
                      signatoryPhoneNumberError ||
                      signatoryEmailError ||
                      signatoryNameError
                        ? disabledButton
                        : primaryButton
                    }
                    disabled={
                      signatoryIdNumberError ||
                      signatoryPhoneNumberError ||
                      signatoryEmailError ||
                      signatoryNameError
                    }
                    onClick={() => handleAddSignatoryDetails()}
                  >
                    {isUpdatingSignatory ? "UPDATE" : "ADD"}
                  </Button>
                </Box>
              </Stack>

              <TableContainer sx={{ backgroundColor: "#f9f9f9" }}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Full Name
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Email Address
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Phone Number
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        ID Number
                      </TableCell>
                      <TableCell align="right">Edit Signatory</TableCell>
                      <TableCell align="right">Delete Signatory</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {signatories?.map((signatory, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <TableCell component="th" scope="row">
                          <Typography>{signatory.signatoryName}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography>{signatory.signatoryEmail}</Typography>
                        </TableCell>
                        <TableCell>
                          <Typography style={{ color: "#159F4A" }}>
                            {signatory.signatoryPhone}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography style={{ color: "#159F4A" }}>
                            {signatory.signatoryIdNumber}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="edit"
                            size="large"
                            onClick={() => {
                              handleSelectSignatory(signatory);
                              // setState((prevState) => ({
                              //   ...prevState,
                              //   currentSignatoryDetails: signatory,
                              // }));
                            }}
                          >
                            <Edit />
                          </IconButton>
                        </TableCell>
                        <TableCell align="right">
                          <IconButton
                            aria-label="delete"
                            size="large"
                            sx={{ color: "#EA2337" }}
                            onClick={() => {
                              handleDeleteSignatory(signatory?.signatoryId);

                              const { signatories } = state;
                              const indexOfSignatory = signatories.findIndex(
                                (currentSignatory) => {
                                  return (
                                    currentSignatory?.signatoryId ==
                                    signatory?.signatoryId
                                  );
                                }
                              );

                              signatories.splice(indexOfSignatory, 1);

                              setState((prevState) => ({
                                ...prevState,
                                signatories,
                              }));
                            }}
                          >
                            <Delete />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </AccordionDetails>
          </Accordion>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
          <Button
            color="inherit"
            variant="contained"
            style={secondaryButton}
            disableElevation
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button>

          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="contained"
            style={primaryButton}
            onClick={() => handleUpdateCompany()}
            disableElevation
          >
            {isValidData ? "Save & Continue" : "Save"}
          </Button>
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
