import * as React from "react";
import {useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Grid,
  CircularProgress,
  Typography,
  Snackbar,
  Alert,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  List,
  ListItem,
  Tabs,
  Tab,
} from "@mui/material";
import {
  statusSuccessStyle,
  statusFailStyle,
} from "../partner/application-form/formStyles";
import apiRequest from "../services/api-request";
import {
  primaryButton,
  secondaryButton,
  disabledButton,
} from "../../common-styles";
import PropTypes from "prop-types";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const bankDetailsList = {
  fontSize: "14px",
};

export default function ReceiptUpload(props) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { handleNext, applications } = props;

  const { companyId } = applications[0]?.company;

  // const { userId } = customLocalStorage.decrypt("cpoUser") || {};

  const initialState = {
    loading: false,
    fileData: "",
    receiptDoc: "",
    receiptId: "",
    submitButtonEnabled: false,
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,

    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
  };

  const [state, setState] = useState(initialState);

  const {
    loading,
    receiptDoc,
    receiptId,
    fileData,
    submitButtonEnabled,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarVerticalPosition,
    snackBarHorizontalPosition,
  } = state;

  const isEmpty = (data) => {
    let response = true;
    if (data) {
      response = false;
    }
    return response;
  };

  // const isValidData = !isEmpty(receiptId);

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const handleApiData = async (params) => {
    // console.log{params});
    const { severity, message, dataName, data } = params;

    // console.log(typeof data);

    let stateData = {
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    };

    if (severity === "success") {
      if (dataName === "fetchedInvoice") {
        const file = new Blob([data], { type: "application/pdf" });

        const fileURL = URL.createObjectURL(file);

        const pdfWindow = window.open();

        pdfWindow.location.href = fileURL;
      }

      if (dataName === "receiptDoc") {
        setState((prevState) => ({
          ...prevState,
          submitButtonEnabled: true,
        }));
      }
      if (dataName === "uploadedReceipt") {
        setState((prevState) => ({
          ...prevState,
          submitButtonEnabled: false,
        }));
        handleNext();
      }
    }
    setState((prevState) => ({
      ...prevState,
      [dataName]: data,
      receiptId: data?.docInfo?.sysDocid,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  // console.log({receiptId})

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  // useEffect(() => {
  //   handleGetInvoice();
  // }, []);

  const handleGetInvoice = async () => {
    await makeApiRequest({
      uri: `/reports/invoice/${companyId}`,
      dataName: `fetchedInvoice`,
      method: `get`,
      data: {},
    });
  };

  const handleFileUpload = async (params) => {
    const { event, docType } = params;
    if (event?.target?.files) {
      const file = event.target.files[0];
      const fileExtension = file.type;
      const allowedFiles =
        process.env.REACT_APP_ALLOWED_UPLOAD_FILE_TYPES_RECEIPT;
      if (
        fileExtension &&
        allowedFiles
          .toLocaleLowerCase()
          .includes(fileExtension.toLocaleLowerCase())
      ) {
        const data = new FormData();
        data.append("file", file);
        data.append("companyId", companyId);
        data.append("docNo", "0");
        data.append("docType", docType);
        data.append("module", "companyDocs");
        await makeApiRequest({
          uri: `/upload`,
          dataName: docType,
          method: `post`,
          data,
        });
      } else {
        alert(
          `${fileExtension} Not allowed. You can only upload a .pdf, .jpg, .jpeg or .png file `
        );
        return;
      }
    }
  };

  const handleUploadReceipt = async () => {
    await makeApiRequest({
      uri: `/kyc/company/${companyId}/receipt/${receiptId}`,
      dataName: `uploadedReceipt`,
      method: `put`,
      data: {},
    });
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Box sx={{ flexGrow: 1, pb: 5, pt: 10 }}>
        <Grid container direction="row" alignItems="center">
          {/* <IconButton onClick={() => handleGoBack()}>
            <ArrowBack />
          </IconButton> */}

          <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
            Make Payment & Upload Receipt
          </Typography>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={7} sx={{ mb: 4, pr: 4 }}>
            <Box sx={{ pt: 3 }}>
              <Box sx={{ width: "500px" }}>
                <Typography textAlign="left">
                  Kindly make payment for your application to be processed
                </Typography>

                <Typography textAlign="left" sx={{ mt: 4 }}>
                  Proforma Invoice
                </Typography>

                <Button
                  style={primaryButton}
                  variant="contained"
                  component="span"
                  disableElevation
                  onClick={() => handleGetInvoice()}
                >
                  VIEW INVOICE
                </Button>
              </Box>

              <Box sx={{ mt: 10, ml: -2 }}>
                <TableContainer sx={{ mt: 5, mb: 4 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>File</TableCell>

                        <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>

                        <TableCell sx={{ fontWeight: "bold" }}>
                          Status
                        </TableCell>

                        <TableCell align="right"></TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Payment Receipt{" "}
                        </TableCell>
                        <TableCell>PDF/PNG/JPG/JPEG</TableCell>
                        <TableCell>
                          <Typography
                            style={
                              receiptDoc ? statusSuccessStyle : statusFailStyle
                            }
                          >
                            {receiptDoc
                              ? "Uploaded successfully"
                              : "Not uploaded"}
                          </Typography>
                        </TableCell>

                        <TableCell align="right">
                          <label htmlFor="receiptDoc">
                            <input
                              type="file"
                              id="receiptDoc"
                              accept="application/pdf, image/png, image/jpg, image/jpeg"
                              style={{ display: "none" }}
                              onChange={(event) =>
                                handleFileUpload({
                                  event,
                                  docType: `receiptDoc`,
                                })
                              }
                            />

                            <Button
                              style={secondaryButton}
                              variant="contained"
                              component="span"
                              disableElevation
                            >
                              UPLOAD
                            </Button>
                          </label>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={5} sx={{ pt: 2, backgroundColor: "#f9f9f9" }}>
            <Box sx={{ ml: -3 }}>
              <Typography sx={{ mb: 2, fontWeight: "bold" }}>
                Payment Instructions
              </Typography>

              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="MPESA" {...a11yProps(0)} />
                  <Tab label="KES Bank Deposit" {...a11yProps(1)} />
                </Tabs>
              </Box>

              <TabPanel value={value} index={0}>
                <Box>
                  <Typography sx={{ mb: 2, fontWeight: "bold" }}>
                    How to Deposit Money to Standard Chartered Kenya via Mpesa
                  </Typography>
                  <List>
                    <ListItem>
                      <Typography>
                        I. Go to M-Pesa menu on your phone, select “Lipa na
                        M-Pesa”{" "}
                      </Typography>
                    </ListItem>
                    <ListItem>II. Select “Paybill” </ListItem>
                    <ListItem>
                      III. Select “Enter business no” and key in the SCB paybill
                      329329{" "}
                    </ListItem>
                    <ListItem>
                      IV. Enter the Safaricom Standard Chartered account number
                      0106094643702 and press “OK”{" "}
                    </ListItem>
                    <ListItem>
                      V. Enter amount i.e. amount you want to deposit and press
                      “OK”{" "}
                    </ListItem>
                    <ListItem>
                      VI. Enter your M-PESA PIN and press “OK”{" "}
                    </ListItem>
                    <ListItem>
                      VII. Upload screenshot of transaction message
                    </ListItem>
                  </List>
                </Box>
              </TabPanel>

              <TabPanel value={value} index={1}>
                <Box>
                  <Typography sx={{ mb: 2, fontWeight: "bold" }}>
                    Use the following bank details for EFT payments. Scan and
                    upload clear copy of receipt
                  </Typography>
                  <Grid container sx={{ pt: 3 }}>
                    <Grid item xs={4}>
                      <List>
                        {[
                          "Account Name:",
                          "Bank:",
                          "Branch:",
                          "Bank Code:",
                          "Branch Code:",
                          "Branch Address:",
                          "Physical Address:",
                          "Account No:",
                          "Swift Code:",
                          "Currency:",
                        ].map((value) => {
                          const listItemId = `${value}`;
                          return (
                            <ListItem sx={{ p: 1 }}>
                              <Typography
                                id={listItemId}
                                sx={{ fontWeight: "bold" }}
                                style={bankDetailsList}
                              >
                                {`${value}`}
                              </Typography>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                    <Grid item xs={8}>
                      <List>
                        <ListItem sx={{}}>
                          <Typography style={bankDetailsList}>
                            Safaricom Limited
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography style={bankDetailsList}>
                            Standard Chartered Bank Kenya Limited
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography style={bankDetailsList}>
                            Westlands
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography style={bankDetailsList}>02</Typography>
                        </ListItem>
                        <ListItem>
                          <Typography style={bankDetailsList}>015</Typography>
                        </ListItem>
                        <ListItem>
                          <Typography style={bankDetailsList}>
                            P.O Box 14438-00800, Nairobi Kenya
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography style={bankDetailsList}>
                            Woodvale Groove
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography style={bankDetailsList}>
                            0106094643702
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography style={bankDetailsList}>
                            SCBLKENX
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography style={bankDetailsList}>Ksh </Typography>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Box>
              </TabPanel>
            </Box>
          </Grid>
        </Grid>

        <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
          {/* <Button
            color="inherit"
            variant="contained"
            style={secondaryButton}
            disableElevation
            onClick={handleBack}
            sx={{ mr: 1 }}
          >
            Back
          </Button> */}
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="contained"
            style={submitButtonEnabled ? primaryButton : disabledButton}
            onClick={() => {
              handleUploadReceipt();
            }}
            disableElevation
            disabled={!submitButtonEnabled}
          >
            Submit
          </Button>
        </Box>
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </>
  );
}
