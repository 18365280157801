import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Link,
} from "@mui/material";
import { primaryButton, secondaryButton } from "../../../../common-styles";
import React, { useEffect, useState } from "react";
import SupplierCreationForm from "./supplier-creation-form";
import SupplierBankDetailsForm from "./supplier-bank-details-form";
import apiRequest from "../../../services/api-request";
import {
  formTitleStyle,
  statusSuccessStyle,
  statusFailStyle,
} from "../formStyles";

export default function PartC(props) {
  const { handleBack, applications, handleCompanyData, handleNext } = props;

  delete applications[0]?.company?.application;

  const { companyId } = applications[0]?.company;

  const initialState = {
    currentSelection: "PartC",
    loading: false,
    supplierCreationFormStatus: "Not Started",
    bankDetailsFormStatus: "Not Started",
    securityDoc: "",
  };

  //Not Started, In Progress, Complete

  const [state, setState] = useState(initialState);

  const {
    currentSelection,
    loading,
    supplierCreationFormStatus,
    bankDetailsFormStatus,
    securityDoc,
  } = state;

  const handleOpen = (currentSelection) => {
    setState((prevState) => ({
      ...prevState,
      currentSelection,
    }));
  };

  const handleGoBack = () => {
    setState((prevState) => ({
      ...prevState,
      currentSelection: "PartC",
    }));
  };

  const isEmpty = (data) => {
    let response = true;
    if (data) {
      response = false;
    }
    return response;
  };

  const isValidData =
    supplierCreationFormStatus === "Complete" &&
    bankDetailsFormStatus === "Complete" &&
    !isEmpty(securityDoc);

  const statusStyle = (status) => {
    return status === "Not Started"
      ? { color: "red" }
      : status === "In Progress"
      ? { color: "#FFCC00" }
      : status === "Complete"
      ? { color: "#159F4A" }
      : { color: "#121212" };
  };

  useEffect(() => {
    const { applications } = props;
    if (applications[0]?.company) {
      const { companyId, supplierCreationFormStatus, bankDetails } =
        applications[0]?.company;
      setState((prevState) => ({
        ...prevState,
        companyId,
        supplierCreationFormStatus: supplierCreationFormStatus ?? "Not Started",
        bankDetailsFormStatus:
          bankDetails?.bankDetailsFormStatus ?? "Not Started",
      }));
    }
  }, [applications]);

  useEffect(() => {
    const { applications } = props;
    if (applications[0]?.company) {
      const { securityDoc } = applications[0]?.company;
      setState((prevState) => ({
        ...prevState,
        securityDoc,
      }));
    }
  }, []);

  const handleApiData = async (params) => {
    // console.log{params});
    const { data, dataName, severity, message } = params;
    if (severity === "success") {
      if (dataName === "securityDoc") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.docInfo?.sysDocid,
        }));
      }
      // const {companyId} = data;
      handleCompanyData();
      if (isValidData) {
        await handleNext();
      }
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleFileUpload = async (params) => {
    const { event, docType } = params;
    if (event?.target?.files) {
      const file = event.target.files[0];
      const fileExtension = file.type;
      const allowedFiles = process.env.REACT_APP_ALLOWED_UPLOAD_FILE_TYPES;

      // console.log({ fileExtension });
      if (
        fileExtension &&
        allowedFiles
          .toLocaleLowerCase()
          .includes(fileExtension.toLocaleLowerCase())
      ) {
        const data = new FormData();
        data.append("file", file);
        data.append("companyId", companyId);
        data.append("docNo", "0");
        data.append("docType", docType);
        data.append("module", "companyDocs");
        await makeApiRequest({
          uri: `/upload`,
          dataName: docType,
          method: `post`,
          data,
        });
      } else {
        alert(`${fileExtension} Not allowed. You can only upload a .pdf file `);
        return;
      }
    }
  };

  const handleUpdateCompany = async () => {
    await makeApiRequest({
      uri: `/kyc/company/${companyId}`,
      dataName: `createdCompany`,
      method: `put`,
      data: {
        ...applications[0]?.company,
        securityDoc,
      },
    });
  };

  return (
    <>
      {currentSelection === "PartC" && (
        <Box>
          <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
            <CircularProgress color="success" />
          </Backdrop>

          <Typography
            textAlign="left"
            style={formTitleStyle}
            sx={{ pb: 3, pt: 5 }}
          >
            PART C
          </Typography>

          <Paper sx={{ p: 3 }}>
            <TableContainer sx={{ mt: 2 }}>
              <Typography textAlign="left" sx={{ pb: 3 }}>
                Kindly fill in the details in all the forms below
              </Typography>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Application
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>Status</TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Supplier Request Form
                    </TableCell>
                    <TableCell>FORM</TableCell>
                    <TableCell>
                      <Typography
                        style={statusStyle(supplierCreationFormStatus)}
                      >
                        {supplierCreationFormStatus}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        style={primaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() => handleOpen("supplierCreation")}
                      >
                        APPLY
                      </Button>
                    </TableCell>
                  </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Supplier Bank Details Form
                    </TableCell>
                    <TableCell>FORM</TableCell>
                    <TableCell>
                      <Typography style={statusStyle(bankDetailsFormStatus)}>
                        {bankDetailsFormStatus}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Button
                        style={primaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() => handleOpen("supplierBankDetails")}
                      >
                        APPLY
                      </Button>
                    </TableCell>
                  </TableRow>

                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Minimum Baseline Security Requirements
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell>
                      <Typography
                        style={
                          securityDoc ? statusSuccessStyle : statusFailStyle
                        }
                      >
                        {securityDoc ? "Uploaded successfully" : "Not uploaded"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Link
                        href={`${process.env.REACT_APP_HOMEPAGE}/files/SafaricomIntegratorsMinimumBaselineSecurityRequirements.xlsx`}
                        target="_blank"
                        download
                        sx={{ cursor: "pointer" }}
                      >
                        <Button style={primaryButton} sx={{ mr: 2 }}>
                          DOWNLOAD FORM
                        </Button>
                      </Link>
                      <label htmlFor="securityDoc">
                        <input
                          type="file"
                          id="securityDoc"
                          accept="application/pdf"
                          style={{ display: "none" }}
                          onChange={(event) =>
                            handleFileUpload({
                              event,
                              docType: `securityDoc`,
                            })
                          }
                        />
                        <Button
                          style={secondaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                        >
                          UPLOAD
                        </Button>
                      </label>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
            <Button
              color="inherit"
              variant="contained"
              style={secondaryButton}
              disableElevation
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
              variant="contained"
              style={primaryButton}
              onClick={() => handleUpdateCompany()}
              disableElevation
            >
              {isValidData ? "Save & Continue" : "Save"}
            </Button>
          </Box>
        </Box>
      )}

      {currentSelection === "supplierCreation" && (
        <SupplierCreationForm
          handleGoBack={handleGoBack}
          handleCompanyData={handleCompanyData}
          applications={applications}
        />
      )}
      {currentSelection === "supplierBankDetails" && (
        <SupplierBankDetailsForm
          handleGoBack={handleGoBack}
          handleCompanyData={handleCompanyData}
          applications={applications}
        />
      )}
      {/* {currentSelection === "vpnConfiguration" && (
        <VpnConfigurationForm
          handleGoBack={handleGoBack}
          handleCompanyData={handleCompanyData}
          applications={applications}
        />
      )} */}
    </>
  );
}
