import * as React from "react";
import { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  Button,
  Grid,
  IconButton,
  CircularProgress,
  Typography,
  Divider,
  Stack,
  TextField,
  Snackbar,
  Alert,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Delete, ArrowBack } from "@mui/icons-material";
import apiRequest from "../../../services/api-request";
import { primaryButton, disabledButton } from "../../../../common-styles";
import { inputStyle, formSubtitleStyle, keyContactStyle } from "../formStyles";
import validator from "validator";
import * as Email from "email-validator";

export default function SdpDetailsForm(props) {
  const { handleGoBack, currentTask } = props;

  let [sdpVpn, setSdpVpn] = useState(props?.sdpVpn);

  //   console.log({ sdpVpn });
  //  const { wfId } = currentTask;

  const { companyId } = currentTask?.wfCurrTrans;

  const initialState = {
    currentSection: "A1",
    serviceDeliveryPlatformServices: [],
    currentWhitelistIp: {},
    currentBusinessPhoneNumber: {},
    currentSelection: "SDPForm",
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,
    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
    loading: false,
  };

  const [state, setState] = useState(initialState);

  let [sdpDetails, setSdpDetails] = useState(sdpVpn?.sdp ?? {});

  // let [sdpServices, setSdpServices] = useState([]);

  const {
    currentSection,
    serviceDeliveryPlatformServices,
    currentWhitelistIp,
    currentBusinessPhoneNumber,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
    loading,
  } = state;

  const { phoneNo } = currentBusinessPhoneNumber;
  const { whiteListIp } = currentWhitelistIp;

  const {
    technicalEmail,
    skypeId,
    phoneNumber,
    applicationSourceIp,
    businessEmail,
  } = sdpDetails;

  // let { sdpServices } = sdpDetails;

  const technicalEmailAddressError =
    !technicalEmail || !Email.validate(technicalEmail);
  const technicalEmailAddressHelperText = technicalEmailAddressError
    ? "Required/Should contain company domain name/"
    : "";

  const skypeIdError =
    !skypeId ||
    !validator.isAlpha(skypeId, ["en-US"], { ignore: " -_.'" }) ||
    skypeId?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const skypeIdHelperText = skypeIdError ? "Required/Invalid" : "";

  const phoneNumberError =
    !phoneNumber || !validator.isMobilePhone(phoneNumber, ["en-KE"]);
  const phoneNumberHelperText = phoneNumberError
    ? "Required/Invalid/Mobile Number - 0xxxxxxxx"
    : "";

  const applicationSourceIpError =
    !applicationSourceIp ||
    applicationSourceIp?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const applicationSourceIpHelperText = applicationSourceIpError
    ? "Required/Invalid"
    : "";

  const businessEmailError = !businessEmail || !Email.validate(businessEmail);
  const businessEmailHelperText = businessEmailError
    ? "Required/Should contain company domain name/"
    : "";

  const businessPhoneNumberError =
    !phoneNo ||
    !(phoneNo > 0) ||
    phoneNo?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TEN;
  const businessPhoneNumberHelperText = businessPhoneNumberError
    ? "Required/Invalid"
    : "";

  const whiteListIpError =
    !whiteListIp ||
    whiteListIp?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const whiteListIpHelperText = whiteListIpError ? "Required/Invalid" : "";

  const isValidBusinessPhoneNumbers = () => {
    let isValid = false;

    // console.log({sdpDetails})

    for (let index = 0; index < sdpDetails?.businessPhones?.length; index++) {
      const businessPhone = sdpDetails?.businessPhones[index];
      // console.log({businessPhone})

      const businessPhoneNumberError =
        !businessPhone?.phoneNo ||
        !(businessPhone?.phoneNo > 0) ||
        businessPhone?.phoneNo?.length >
          process.env.REACT_APP_MAX_INPUT_LENGTH_TEN;
      const businessPhoneNumberHelperText = businessPhoneNumberError
        ? "Required/Invalid"
        : "";

      isValid = !businessPhoneNumberError;

      if (!isValid) {
        break;
      }
    }
    return isValid;
  };

  const isValidProductionWhitelistIps = () => {
    let isValid = false;

    for (let index = 0; index < sdpDetails?.whitelistIps.length; index++) {
      const whitelistIp = sdpDetails?.whitelistIps[index];

      const whiteListIpError =
        !whitelistIp?.whiteListIp ||
        whitelistIp?.whiteListIp?.length >
          process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
      const whiteListIpHelperText = whiteListIpError ? "Required/Invalid" : "";

      isValid = !whiteListIpError;

      if (!isValid) {
        break;
      }
    }
    return isValid;
  };

  const removeDuplicateBusinessPhoneNumbersromArrayOfObjects = (objArray) => {
    if (objArray?.length < 1) return [];

    let uniqueObjArray = [
      ...new Map(objArray.map((item) => [item["phoneNo"], item])).values(),
    ];

    if (uniqueObjArray?.length < 1) return [];

    uniqueObjArray = [
      ...new Map(
        uniqueObjArray.map((item) => [item["phoneNo"], item])
      ).values(),
    ];

    return uniqueObjArray;
  };

  const removeDuplicateProductionWhitelistIpsFromArrayOfObjects = (
    objArray
  ) => {
    if (objArray?.length < 1) return [];

    let uniqueObjArray = [
      ...new Map(objArray.map((item) => [item["whiteListIp"], item])).values(),
    ];

    if (uniqueObjArray?.length < 1) return [];

    uniqueObjArray = [
      ...new Map(
        uniqueObjArray.map((item) => [item["whiteListIp"], item])
      ).values(),
    ];

    return uniqueObjArray;
  };

  const handleAddProductionWhitelistIps = () => {
    let { currentWhitelistIp } = state;
    let { whitelistIps } = sdpDetails;

    if (currentWhitelistIp?.whiteListIpId) {
      const indexOfIp = whitelistIps?.findIndex((ip) => {
        return ip?.whiteListIpId == currentWhitelistIp.whiteListIpId;
      });

      //   console.log({ indexOfIp });

      whitelistIps[indexOfIp] = currentWhitelistIp;

      //   console.log({ currentWhitelistIp });
    } else {
      whitelistIps =
        whitelistIps === undefined
          ? [{ ...currentWhitelistIp }]
          : [{ ...currentWhitelistIp }, ...whitelistIps];
    }
    // console.log({ whitelistIps });
    whitelistIps =
      removeDuplicateProductionWhitelistIpsFromArrayOfObjects(whitelistIps);
    // console.log({ whitelistIps });

    setSdpDetails({ ...sdpDetails, whitelistIps, currentWhitelistIp: {} });
  };

  const handleAddBusinessPhoneNumbers = () => {
    // const { businessPhones } = sdpDetails;
    let { currentBusinessPhoneNumber } = state;

    let { businessPhones } = sdpDetails;

    if (currentBusinessPhoneNumber?.phoneId) {
      const indexOfBusinessPhone = businessPhones?.findIndex((phoneNumber) => {
        return phoneNumber?.phoneId == currentBusinessPhoneNumber?.phoneId;
      });
      // console.log({ indexOfBusinessPhone });
      businessPhones[indexOfBusinessPhone] = currentBusinessPhoneNumber;
    } else {
      businessPhones =
        businessPhones === undefined
          ? [{ ...currentBusinessPhoneNumber }]
          : [{ ...currentBusinessPhoneNumber }, ...businessPhones];
      //   businessPhones = [{ ...currentBusinessPhoneNumber }, ...businessPhones];
    }
    businessPhones =
      removeDuplicateBusinessPhoneNumbersromArrayOfObjects(businessPhones);
    // console.log({ businessPhones });

    setSdpDetails({
      ...sdpDetails,
      businessPhones,
      currentBusinessPhoneNumber: {},
    });
  };

  const isValidData =
    isValidBusinessPhoneNumbers() &&
    isValidProductionWhitelistIps() &&
    !technicalEmailAddressError &&
    !businessEmailError &&
    !skypeIdError &&
    !phoneNumberError;

  const handleAccordionChange = (currentSection) => {
    setState((prevState) => ({
      ...prevState,
      currentSection,
    }));
  };

  const handleInput = (event) => {
    const name = event?.target?.name;
    let value = event?.target?.value;

    let { currentWhitelistIp, currentBusinessPhoneNumber } = state;

    if (currentSection === "A2") {
      if (name === "whiteListIp") {
        currentWhitelistIp = { ...currentWhitelistIp, [name]: value };
        setState((prevState) => ({
          ...prevState,
          currentWhitelistIp,
        }));
      }
      if (name === "phoneNo") {
        currentBusinessPhoneNumber = {
          ...currentBusinessPhoneNumber,
          [name]: value,
        };
        setState((prevState) => ({
          ...prevState,
          currentBusinessPhoneNumber,
        }));
      }
    }

    if (currentSection === "A1") {
      setSdpDetails({
        ...sdpDetails,
        [name]: value,
        sdpDetailsFormStatus: isValidData ? "Complete" : "In Progress",
      });
    }
  };

  const handleApiData = (params) => {
    // console.log{params});
    const { data, dataName, severity, message } = params;

    // console.log({ data });
    // console.log({ dataName });

    if (severity === "success") {
      if (dataName === "serviceDeliveryPlatformServices") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data,
        }));
      }
      if (dataName === "sdpDetails") {
        setSdpDetails(data);
        if (isValidData) {
          handleGoBack("", data);
        }
      }
    }
    setState((prevState) => ({
      ...prevState,
      sdpDetailsFormStatus: isValidData ? "Complete" : "In Progress",
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);

    const { uri, dataName, method, data } = params;

    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };

    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleGetSdpServices = async () => {
    const pageNumber = 1;
    const limit = 500;
    await makeApiRequest({
      uri: `/application/sdpServices?limit=${limit}`,
      dataName: `serviceDeliveryPlatformServices`,
      method: `get`,
      data: "",
    });
  };

  useEffect(() => {
    (async () => {
      await handleGetSdpServices();
    })();
  }, []);

  useEffect(() => {
    if (sdpDetails) {
      setSdpDetails(sdpDetails);
      setSdpVpn({ ...sdpVpn, sdp: sdpDetails });
    }
    // console.log({ sdpDetails });
  }, [sdpDetails]);

  const handleAddSdpDetails = async () => {
    // console.log({ sdpDetails });
    await makeApiRequest({
      uri: `/kyc/company/sdpDetails/${companyId}`,
      dataName: `sdpDetails`,
      method: `post`,
      data: {
        ...sdpVpn,
        companyId,
        sdp: sdpDetails,
      },
    });
    // console.log({ sdpVpn });
  };

  const handleDeleteBusinessPhoneNumbers = async (businessPhoneId) => {
    await makeApiRequest({
      uri: `/kyc/company/businessPhone/${businessPhoneId}`,
      dataName: `deletedBusinessPhoneNumber`,
      method: `delete`,
      data: {},
    });
  };

  const handleDeleteWhitelistIps = async (whiteListIpId) => {
    await makeApiRequest({
      uri: `/kyc/company/whiteListIp/${whiteListIpId}`,
      dataName: `deleteWhitelistedIp`,
      method: `delete`,
      data: {},
    });
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const handleClose = () => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <Box sx={{ flexGrow: 1, p: 2, mt: 5, backgroundColor: "#f9f9f9" }}>
        <Grid container direction="row" alignItems="center">
          <Stack direction="row" spacing={1}>
            <IconButton
              onClick={() => handleGoBack("", sdpDetails)}
              sx={{ mb: 4, mt: -0.5 }}
            >
              <ArrowBack />
            </IconButton>
            <Box>
              <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                SDP Details Form{" "}
              </Typography>
            </Box>
          </Stack>

          <Divider />
        </Grid>

        <Box sx={{ mt: 2 }}>
          <Accordion
            expanded={currentSection === "A1"}
            onChange={() => handleAccordionChange("A1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography textAlign="left" style={formSubtitleStyle}>
                1: Testing Details
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <TextField
                  error={technicalEmailAddressError}
                  helperText={technicalEmailAddressHelperText}
                  label="Technical Email Address"
                  name="technicalEmail"
                  type="text"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  value={technicalEmail ?? ""}
                  // value={sdpVpn.sdpDetails.technicalEmail ?? ""}
                  fullWidth
                  required
                />
                <TextField
                  error={businessEmailError}
                  helperText={businessEmailHelperText}
                  label="Business Email Address"
                  name="businessEmail"
                  type="text"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  value={businessEmail ?? ""}
                  // value={sdpVpn.sdpDetails.businessEmail ?? ""}
                  fullWidth
                  required
                />
                <TextField
                  error={skypeIdError}
                  helperText={skypeIdHelperText}
                  label="Skype ID"
                  name="skypeId"
                  type="text"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  value={skypeId ?? ""}
                  // value={sdpVpn.sdpDetails.skypeId ?? ""}
                  fullWidth
                  required
                />
              </Stack>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <TextField
                  error={applicationSourceIpError}
                  helperText={applicationSourceIpHelperText}
                  label="Application Source IP Address"
                  name="applicationSourceIp"
                  type="text"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  value={applicationSourceIp ?? ""}
                  // value={sdpVpn.sdpDetails.applicationSourceIp ?? ""}
                  fullWidth
                  required
                />

                <TextField
                  error={phoneNumberError}
                  helperText={phoneNumberHelperText}
                  label="Phone Number"
                  name="phoneNumber"
                  type="text"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  value={phoneNumber ?? ""}
                  // value={sdpVpn.sdpDetails.phoneNumber ?? ""}
                  fullWidth
                  required
                />
              </Stack>

              <Typography sx={{ fontWeight: "bold", mb: 1 }}>
                Services to be tested:
              </Typography>

              <FormGroup>
                {serviceDeliveryPlatformServices?.map((sdpService, index) => {
                  // console.log({ sdpService });
                  // console.log({ sdpServices });
                  return (
                    <FormControlLabel
                      key={index}
                      style={keyContactStyle}
                      control={
                        <Checkbox
                          key={sdpService?.sdpServiceId}
                          checked={
                            sdpDetails?.sdpServices?.filter(
                              (currentService) =>
                                currentService?.sdpServiceId ==
                                sdpService?.sdpServiceId
                            )?.length > 0
                          }
                          onChange={() => {
                            const indexOfService =
                              sdpDetails?.sdpServices?.findIndex(
                                (currentService) =>
                                  currentService?.sdpServiceId ==
                                  sdpService?.sdpServiceId
                              );

                            // console.log({ indexOfService });
                            // console.log(!indexOfService);
                            // console.log(indexOfService == undefined);
                            // console.log(indexOfService === undefined);

                            if (
                              indexOfService === undefined ||
                              indexOfService == -1
                            ) {
                              if (!indexOfService) {
                                setSdpDetails((prevState) => {
                                  return {
                                    ...prevState,
                                    sdpServices: [
                                      {
                                        sdpServiceId: sdpService?.sdpServiceId,
                                        sdpServiceName:
                                          sdpService?.sdpServiceName,
                                      },
                                    ],
                                  };
                                });
                              } else {
                                setSdpDetails((prevState) => ({
                                  ...prevState,
                                  sdpServices: [
                                    ...prevState.sdpServices,
                                    {
                                      sdpServiceId: sdpService?.sdpServiceId,
                                      sdpServiceName:
                                        sdpService?.sdpServiceName,
                                    },
                                  ],
                                }));
                              }
                            } else {
                              setSdpDetails((prevState) => {
                                const updatedSdpServices = [
                                  ...prevState.sdpServices,
                                ];
                                updatedSdpServices.splice(indexOfService, 1);
                                return {
                                  ...prevState,
                                  sdpServices: updatedSdpServices,
                                };
                              });
                            }
                          }}

                          // onChange={() => {
                          //   // let { sdpServices } = state;
                          //   console.log({ sdpServices });

                          //   const indexOfService = sdpServices?.findIndex(
                          //     (currentService) =>
                          //       currentService?.sdpServiceId ==
                          //       sdpService?.sdpServiceId
                          //   );

                          //   console.log({ indexOfService });

                          //   if (indexOfService == -1) {
                          //     console.log({ sdpService });
                          //     setSdpServices((prevSdpServices) => [
                          //       ...prevSdpServices,
                          //       {
                          //         sdpServiceId: sdpService?.sdpServiceId,
                          //         sdpServiceName: sdpService?.sdpServiceName,
                          //       },
                          //     ]);
                          //     // setSdpServices(...sdpServices, {
                          //     //   sdpServiceId: sdpService?.sdpServiceId,
                          //     //   sdpServiceName: sdpService?.sdpServiceName,
                          //     // });
                          //   } else {
                          //     sdpServices?.splice(indexOfService, 1);
                          //   }
                          //   console.log({ sdpServices });
                          //   setState((prevState) => ({
                          //     ...prevState,
                          //     sdpDetails: { ...sdpDetails, sdpServices },
                          //   }));
                          // }}
                        />
                      }
                      label={sdpService?.sdpServiceName}
                    />
                  );
                })}
                {/* {serviceDeliveryPlatformServices?.map((sdpService, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      style={keyContactStyle}
                      control={
                        <Checkbox
                          key={sdpService?.sdpServiceId}
                          checked={sdpDetails?.sdpServices?.includes(
                            sdpService?.sdpServiceId
                          )}
                          onChange={() => {
                            const indexOfService =
                              sdpDetails?.sdpServices?.findIndex(
                                (currentService) =>
                                  currentService?.sdpServiceId ===
                                  sdpService?.sdpServiceId
                              );

                            setState((prevState) => ({
                              ...prevState,
                              sdpDetails: {
                                ...prevState.sdpDetails,
                                sdpServices:
                                  indexOfService === undefined
                                    ? setState((prevState) => ({
                                        ...prevState,
                                        sdpDetails: {
                                          ...prevState.sdpDetails,
                                          sdpServices: [
                                            {
                                              sdpServiceId:
                                                sdpService?.sdpServiceId,
                                              sdpServiceName:
                                                sdpService?.sdpServiceName,
                                            },
                                          ],
                                        },
                                      }))
                                    : indexOfService === -1
                                    ? prevState.sdpDetails.sdpServices.concat({
                                        sdpServiceId: sdpService?.sdpServiceId,
                                        sdpServiceName:
                                          sdpService?.sdpServiceName,
                                      })
                                    : prevState.sdpDetails.sdpServices.filter(
                                        (currentService) =>
                                          currentService?.sdpServiceId !==
                                          sdpService?.sdpServiceId
                                      ),
                              },
                            }));
                          }}
                        />
                      }
                      label={sdpService?.sdpServiceName}
                    />
                  );
                })} */}
              </FormGroup>
            </AccordionDetails>
          </Accordion>

          <Accordion
            expanded={currentSection === "A2"}
            onChange={() => handleAccordionChange("A2")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography textAlign="left" style={formSubtitleStyle}>
                2: Production Details
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Stack direction="row" spacing={5} textAlign="left">
                <TextField
                  error={whiteListIpError}
                  helperText={whiteListIpHelperText}
                  label="Application Production IPs to be whitelisted"
                  name="whiteListIp"
                  id="whitelistIp"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  value={currentWhitelistIp?.whiteListIp ?? ""}
                  fullWidth
                />
                <Box>
                  <Button
                    style={whiteListIpError ? disabledButton : primaryButton}
                    disabled={whiteListIpError}
                    onClick={() => handleAddProductionWhitelistIps()}
                  >
                    {currentWhitelistIp?.whiteListIpId ? "UPDATE" : "ADD"}
                  </Button>
                </Box>
              </Stack>

              <Box sx={{ backgroundColor: "#f9f9f9", mb: 5 }}>
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Production Ips to be Whitelisted
                        </TableCell>

                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {sdpDetails?.whitelistIps?.map((ip, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setState((prevState) => ({
                              ...prevState,
                              currentWhitelistIp: ip,
                            }));
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Typography>{ip?.whiteListIp}</Typography>
                          </TableCell>

                          <TableCell align="right">
                            <IconButton
                              aria-label="delete"
                              size="large"
                              onClick={() => {
                                handleDeleteWhitelistIps(ip?.whitelistIpId);
                                let { whitelistIps } = sdpDetails;
                                const indexOfWhitelistIps =
                                  sdpDetails?.whitelistIps.findIndex(
                                    (currentIp) => {
                                      return (
                                        currentIp?.whitelistIpId ==
                                        ip?.whitelistIpId
                                      );
                                    }
                                  );

                                whitelistIps.splice(indexOfWhitelistIps, 1);

                                setState((prevState) => ({
                                  ...prevState,
                                  sdpDetails: { ...sdpDetails, whitelistIps },
                                }));
                              }}
                            >
                              <Delete />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                {/*               
                <List sx={{ pt: 2 }}>
                  {whitelistIps?.map((ip) => (
                    <Box>
                      <Stack direction="row">
                        <ListItem>
                          <ListItemText primary={ip?.whiteListIp} />
                        </ListItem>
                        <IconButton
                          aria-label="delete"
                          size="large"
                          onClick={() => {
                            handleDeleteWhitelistIps(ip?.whitelistIpId);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Stack>
                      <Divider />
                    </Box>
                  ))}
                </List> */}
              </Box>
              <Stack direction="row" spacing={5} textAlign="left">
                <TextField
                  error={businessPhoneNumberError}
                  helperText={businessPhoneNumberHelperText}
                  label="Business Phone Numbers"
                  id="businessPhoneNo"
                  name="phoneNo"
                  size="small"
                  style={inputStyle}
                  value={currentBusinessPhoneNumber?.phoneNo ?? ""}
                  onChange={handleInput}
                  fullWidth
                />
                <Box>
                  <Button
                    style={
                      businessPhoneNumberError ? disabledButton : primaryButton
                    }
                    disabled={businessPhoneNumberError}
                    onClick={() => handleAddBusinessPhoneNumbers()}
                  >
                    {currentBusinessPhoneNumber?.phoneId ? "UPDATE" : "ADD"}
                  </Button>
                </Box>
              </Stack>

              <Box sx={{ backgroundColor: "#f9f9f9", p: 2, mb: 5 }}>
                <Box sx={{ backgroundColor: "#f9f9f9", mb: 5 }}>
                  <TableContainer>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: "bold" }}>
                            Business Phone Numbers
                          </TableCell>

                          <TableCell></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {sdpDetails?.businessPhones?.map(
                          (phoneNumber, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setState((prevState) => ({
                                  ...prevState,
                                  currentBusinessPhoneNumber: phoneNumber,
                                }));
                              }}
                            >
                              <TableCell component="th" scope="row">
                                <Typography>{phoneNumber?.phoneNo}</Typography>
                              </TableCell>

                              <TableCell align="right">
                                <IconButton
                                  aria-label="delete"
                                  size="large"
                                  onClick={() => {
                                    handleDeleteBusinessPhoneNumbers(
                                      phoneNumber?.phoneId
                                    );
                                    let { businessPhones } = sdpDetails;
                                    const indexOfBusinessPhone =
                                      sdpDetails?.businessPhones.findIndex(
                                        (currentBusinessPhoneNumber) => {
                                          return (
                                            currentBusinessPhoneNumber?.phoneId ==
                                            phoneNumber?.phoneId
                                          );
                                        }
                                      );

                                    businessPhones.splice(
                                      indexOfBusinessPhone,
                                      1
                                    );

                                    setState((prevState) => ({
                                      ...prevState,
                                      sdpDetails: {
                                        ...sdpDetails,
                                        businessPhones,
                                      },
                                    }));
                                  }}
                                >
                                  <Delete />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Box>
            </AccordionDetails>
          </Accordion>

          <Button
            variant="contained"
            style={primaryButton}
            onClick={() => {
              handleAddSdpDetails();
            }}
            disableElevation
          >
            {isValidData ? "Save & Go Back" : "Save"}
          </Button>
        </Box>

        <Snackbar
          anchorOrigin={{
            vertical: snackBarVerticalPosition,
            horizontal: snackBarHorizontalPosition,
          }}
          open={snackbarOpen}
          autoHideDuration={snackBarAutoHideDuration}
          onClose={() => snackBarHandle(false)}
        >
          <Alert
            onClose={handleClose}
            severity={snackBarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </>
  );
}
