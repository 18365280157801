import {
  Box,
  Typography,
  Stack,
  Button,
  Tabs,
  Tab,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import * as React from "react";
import MUIDataTable from "mui-datatables";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/styles";
import customLocalStorage from "../../services/local-storage";
import apiRequest from "../../services/api-request";
import { primaryButton, numberContainer } from "../../../common-styles";
import ApplicationDetailsPage from "./application-details";
import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from "../../navigation/theme";
import PropTypes from "prop-types";
import AddTaskAssignee from "./../../super-admin/add-task-assignee";
import EmptyTaskPage from "../empty-page";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function VasAccountManager() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // const [checked, setChecked] = React.useState([0]);
  //
  // const handleToggle = (value: number) => () => {
  //     const currentIndex = checked.indexOf(value);
  //     const newChecked = [...checked];
  //
  //     if (currentIndex === -1) {
  //         newChecked.push(value);
  //     } else {
  //         newChecked.splice(currentIndex, 1);
  //     }
  //
  //     setChecked(newChecked);
  // };

  const classes = useStyles();

  const { userId, firstName, lastName } =
    customLocalStorage.decrypt("cpoUser") || {};

  const initialState = {
    pendingTasks: [],
    allTasks: [],
    currentTask: {},
    currentAssignedTask: {},
    currentPage: "pendingTaskList",
    loading: false,
  };

  const [state, setState] = useState(initialState);

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const {
    currentTask,
    allTasks,
    pendingTasks,
    currentPage,
    currentAssignedTask,
    loading,
  } = state;

  const togglePage = (params) => {
    const { currentPage, currentTask } = params;
    setState((prevState) => ({
      ...prevState,
      currentPage,
      currentTask,
    }));
  };

  const toggleTaskPage = (params) => {
    const { currentPage, currentAssignedTask } = params;
    setState((prevState) => ({
      ...prevState,
      currentPage,
      currentAssignedTask,
    }));
  };

  const handleApiData = (params) => {
    // console.log{params});
    const { severity, message, data, dataName } = params;
    setState((prevState) => ({
      ...prevState,
      [dataName]: data,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      handleLoader,
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  useEffect(() => {
    const page = 1;
    const limit = 500;
    makeApiRequest({
      uri: `/tickets?limit=${limit}&userId=${userId}`,
      dataName: `pendingTasks`,
      method: `get`,
      data: {},
    });
    makeApiRequest({
      uri: `/tickets?page=${page}&limit=${limit}`,
      dataName: `allTasks`,
      method: `get`,
      data: {},
    });
  }, []);

  const pendingTasksCount = pendingTasks?.length;
  const allTasksCount = allTasks?.length;

  const columns = [
    {
      name: "wfActiveProcess",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                  {value}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Business Name",
      options: {
        download: false,
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                  {value?.registeredCompanyName}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Company Email Address",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.companyEmailAddress}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Company Registration Number",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.companyRegNumber}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Phone Number",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.companyMobileNumber}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Physical Location",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.physicalLocation}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfId",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          const currentTask = pendingTasks.filter(
            (task) => task?.wfId == value
          )[0];
          return (
            <>
              {currentTask?.wfActiveProcess == "vas_countercheck_task" && (
                <Button
                  style={primaryButton}
                  onClick={() =>
                    togglePage({
                      currentPage: "applicationDetails",
                      currentTask,
                    })
                  }
                >
                  VIEW APPLICATION
                </Button>
              )}
              {currentTask?.wfActiveProcess ==
                "task_validate_uploaded_receipt" && (
                <Button
                  style={primaryButton}
                  onClick={() =>
                    togglePage({
                      currentPage: "applicationDetails",
                      currentTask,
                    })
                  }
                >
                  VERIFY PAYMENT
                </Button>
              )}
              {currentTask?.wfActiveProcess ==
                "task_map_partner_as_account_payable" && (
                <Button
                  style={primaryButton}
                  onClick={() =>
                    togglePage({
                      currentPage: "applicationDetails",
                      currentTask,
                    })
                  }
                >
                  MARK CREATED ON ACCOUNTS PAYABLE
                </Button>
              )}
              {currentTask?.wfActiveProcess == "task_attach_sdp_crq" && (
                <Button
                  style={primaryButton}
                  onClick={() =>
                    togglePage({
                      currentPage: "applicationDetails",
                      currentTask,
                    })
                  }
                >
                  ADD CRQ
                </Button>
              )}
            </>
          );
        },
      },
    },
  ];

  const taskColumns = [
    {
      name: "wfId",
      label: "Task Id",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "wfCurrTrans",
      label: "Company ID",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.companyId}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Company Registration Number",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.companyRegNumber}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Registered Company Name",
      options: {
        download: false,
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.registeredCompanyName}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfTaskName",
      label: "Task Name",
      options: {
        filter: true,
        sort: false,
      },
    },

    {
      name: "wfActiveProcess",
      label: "Active Stage",
      options: {
        filter: true,
        sort: false,
      },
    },
    {
      name: "wfCreatedDate",
      label: "Date Created",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {new Date(value).toLocaleDateString()}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfId",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          const currentAssignedTask = allTasks?.filter(
            (task) => task?.wfId == value
          )[0];
          return (
            <>
              {!(
                currentAssignedTask?.wfActiveProcess == "self_onboarding_task"
              ) &&
                !(
                  currentAssignedTask?.wfActiveProcess ==
                  "task_upload_payment_receipts"
                ) &&
                !(
                  currentAssignedTask?.wfActiveProcess ==
                  "task_fill_sdp_details"
                ) && (
                  <Button
                    style={primaryButton}
                    onClick={() =>
                      toggleTaskPage({
                        currentPage: "taskDetails",
                        currentAssignedTask: currentAssignedTask,
                      })
                    }
                  >
                    REASSIGN TASK
                  </Button>
                )}
            </>
          );
        },
      },
    },
  ];

  // const handleOnDownload = (buildHead, buildBody, columns, data) =>  {
  //     let csvContent = buildHead(columns) + buildBody(data);
  //     const a = document.createElement('a');
  //     const textFileAsBlob = new Blob(["\ufeff", csvContent], {
  //         type: 'text/csv;charset=UTF-8',
  //         encoding: "UTF-8"
  //       });
  //     a.href = window.URL.createObjectURL(textFileAsBlob);
  //     a.download = 'taskList.csv';
  //     a.click();
  //     return false;
  // }

  const options = {
    selectableRows: "none",
    download: false,
    pagination: true,
    rowsPerPage: 10
    // downloadOptions: {filterOptions : {useDisplayedRowsOnly : true}},
    // onDownload: (buildHead, buildBody, columns, data) => { handleOnDownload(buildHead, buildBody, columns, data) },
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
          <CircularProgress color="success" />
        </Backdrop>

        <Typography
          variant="h4"
          textAlign="left"
          sx={{ m: 3, fontWeight: "bold" }}
        >
          Content Service Provider Applications
        </Typography>
        <Typography textAlign="left" sx={{ m: 3 }}>
          Welcome {firstName} {lastName}
        </Typography>

        <Box sx={{ m: 3 }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Pending Tasks" {...a11yProps(0)} />
              <Tab label="Task Assignment" {...a11yProps(1)} />
            </Tabs>
          </Box>

          <TabPanel value={value} index={0}>
            <Box sx={{ mt: 1 }}>
              <Typography
                variant="h6"
                textAlign="left"
                sx={{ fontWeight: "bold" }}
              >
                <span>Pending Approvals</span>
                <span style={numberContainer}>{pendingTasksCount}</span>
              </Typography>
              <Typography sx={{ mt: 2, fontSize: "14px" }}>
                View and action on tasks assigned to you here
              </Typography>
            </Box>

            <Box sx={{ mt: 3 }}>
              <Box sx={{ textAlign: "center", mt: 3 }}>
                {currentPage == "pendingTaskList" &&
                  pendingTasks?.length < 1 && <EmptyTaskPage />}
              </Box>
              {currentPage == "pendingTaskList" && pendingTasks?.length > 0 && (
                <MUIDataTable
                  title="Task List"
                  data={pendingTasks}
                  columns={columns}
                  options={options}
                />
              )}
              {currentPage == "applicationDetails" && (
                <ApplicationDetailsPage
                  currentTask={currentTask}
                  togglePage={togglePage}
                />
              )}
            </Box>
          </TabPanel>

          <TabPanel value={value} index={1}>
            <Box sx={{ mt: 1 }}>
              <Typography
                variant="h6"
                textAlign="left"
                sx={{ fontWeight: "bold" }}
              >
                <span>Tasks</span>
                <span style={numberContainer}>{allTasksCount}</span>
              </Typography>
            </Box>

            <Typography sx={{ mt: 2, fontSize: "14px" }}>
              Track the status of all applications here
            </Typography>

            <Box sx={{ textAlign: "center", mt: 3 }}>
              {currentPage == "pendingTaskList" && allTasks?.length < 1 && (
                <EmptyTaskPage />
              )}
            </Box>

            {currentPage == "pendingTaskList" && allTasks?.length > 0 && (
              <MUIDataTable
                title="Task List"
                data={allTasks}
                columns={taskColumns}
                options={options}
              />
            )}
            {currentPage == "taskDetails" && (
              <AddTaskAssignee
                currentAssignedTask={currentAssignedTask}
                toggleTaskPage={toggleTaskPage}
              />
            )}
          </TabPanel>
        </Box>
      </Box>
      {/*<List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>*/}
      {/*    {[0, 1, 2, 3].map((value) => {*/}
      {/*        const labelId = `checkbox-list-label-${value}`;*/}
      {/*        return (*/}
      {/*            <ListItem sx={{p: 1, mb: -2}}>*/}
      {/*                <ListItemButton role={undefined} onClick={handleToggle(value)} dense>*/}
      {/*                    <ListItemIcon>*/}
      {/*                        <Checkbox*/}
      {/*                            edge="start"*/}
      {/*                            checked={checked.indexOf(value) !== -1}*/}
      {/*                            tabIndex={-1}*/}
      {/*                            disableRipple*/}
      {/*                            inputProps={{'aria-labelledby': labelId}}*/}
      {/*                            sx={{*/}
      {/*                                color: "#159F4A",*/}
      {/*                                '&.Mui-checked': {*/}
      {/*                                    color: "#159F4A",*/}
      {/*                                },*/}
      {/*                            }}*/}
      {/*                        />*/}
      {/*                    </ListItemIcon>*/}
      {/*                    <ListItemText id={labelId} primary={`Line item ${value + 1}`}/>*/}
      {/*                </ListItemButton>*/}
      {/*            </ListItem>*/}
      {/*        );*/}
      {/*    })}*/}
      {/*</List>*/}
    </ThemeProvider>
  );
}
