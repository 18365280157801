import Box from "@mui/material/Box";
import { formStyle, inputStyle } from "./user-management-styles";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Link,
  Snackbar,
  TextField,
} from "@mui/material";
import { primaryButton } from "../../common-styles";
import * as React from "react";
import { useState } from "react";
import apiRequest from "../services/api-request";
import LoginAppBar from "./login-app-bar";
import LeftImage from "./left-image";

export default function PasswordResetRequest(props) {
  const { togglePasswordResetRequest } = props;

  const initialState = {
    email: "",
    open: false,
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,

    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
    loading: false,
  };

  const [state, setState] = useState(initialState);

  const {
    email,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
    loading,
  } = state;

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.logname, value);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  const handleApiData = (params) => {
    // console.log{params});
    const { data, dataName, severity, message, status } = params;
    if (status === 200) {
      alert(`Successful, please check your ${email} inbox`);
      togglePasswordResetRequest();
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };
  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handlePasswordResetLink = async (event) => {
    event.preventDefault();
    await makeApiRequest({
      uri: `/users/password-reset-request`,
      dataName: `requestedResetLink`,
      method: `post`,
      data: { email },
    });
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  return (
    <Box>
      <Backdrop open={loading}>
        <CircularProgress color="success" />{" "}
      </Backdrop>
      <LoginAppBar />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <LeftImage />
        </Grid>
        <Grid item xs={12} sm={6} style={formStyle}>
          <form noValidate onSubmit={handlePasswordResetLink}>
            <>
              <Box>
                <Typography sx={{ fontSize: 23, fontWeight: "bold", mb: 3 }}>
                  Reset Your Password
                </Typography>
                <Typography sx={{ mb: 3 }}>
                  Kindly enter your email so we can send you a reset link
                </Typography>
                <Box>
                  <TextField
                    label="Email Address"
                    name="email"
                    id="outlined-size-small"
                    size="small"
                    style={inputStyle}
                    onChange={handleInput}
                    fullWidth
                    required
                  />
                </Box>
              </Box>
              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  style={primaryButton}
                  disableElevation
                >
                  SEND PASSWORD RESET LINK
                </Button>
              </Box>
            </>
          </form>
          <Box sx={{ mt: 4 }}>
            <Typography>
              <Link
                sx={{ color: "#159F4A", cursor: "pointer", fontWeight: "bold" }}
                onClick={() => togglePasswordResetRequest()}
              >
                Go Back to Login
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
