export const primaryButton = {
    background: "#159F4A",
    color: '#fff',
    width: 'auto',
    borderRadius: '20px',
    marginTop: '1%',
    fontWeight: 'bold',
    "&:hover": {
        background: "#000"
    }
}

export const disabledButton = {
    background: '#e5e5e5',
    borderRadius: '20px',
}

export const secondaryButton = {
    background: "#fff",
    color: "#159F4A",
    width: 'auto',
    height: '35px',
    border: '2px solid #159F4A',
    borderRadius: '20px',
    marginTop: '1%',
    fontWeight: 'bold',
    elevation: 0
}

export const approveButton = {
    background: "#159F4A",
    color: '#fff',
    width: '235px',
    borderRadius: '20px',
    marginTop: '1%',
    fontWeight: 'bold',
    "&:hover": {
        background: "#000"
    }
}

export const rejectButton = {
    background: "#fff",
    color: "red",
    width: '235px',
    height: '35px',
    border: '2px solid red',
    borderRadius: '20px',
    marginTop: '1%',
    fontWeight: 'bold',
    elevation: 0,
}

export const numberContainer = {
    backgroundColor: "#159F4A",
    color: "#fff",
    borderRadius: "5px",
    padding: 4,
    fontSize: "14px",
    marginLeft: "15px",
    width: "auto",
  };

export const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    backgroundColor: '#fff',
    border: 'none',
    elevation: 3,
    boxShadow: 24,
    p: 4,
};

export const modalStyle2 = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflow: 'scroll',
    height: '60%',
    width: 700,
    backgroundColor: '#fff',
    border: 'none',
    elevation: 3,
    boxShadow: 24,
    p: 4,
};

export const emptyPageImageStyle = {
    width: '210px',
    height: '200px'
}