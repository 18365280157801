import React, { useEffect, useState } from "react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  IconButton,
  Snackbar,
  Stack,
  TextField,
  FormControl,
  FormHelperText,
  Typography,
} from "@mui/material";
import { inputStyle } from "../formStyles";
import { ArrowBack } from "@mui/icons-material";
import { Autocomplete } from "@mui/lab";
import apiRequest from "../../../services/api-request";
import { disabledButton, primaryButton } from "../../../../common-styles";
import validator from "validator";

export default function SupplierBankDetailsForm(props) {
  const { handleGoBack, applications, handleCompanyData } = props;

  const initialState = {
    companyId: applications[0]?.company?.companyId,
    bankId: "",
    accountName: "",
    bankBranch: "",
    bankName: "",
    currencies: [],
    transactionCurrency: {},
    bankRoutingCode: "",
    bankRoutingMethod: "",
    accountNo: "",
    swiftCode: "",
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,
    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
    loading: false,
  };

  const [state, setState] = useState(initialState);
  const [isSubmitDisabledButton, setSubmitDisabledButton] = useState(false);

  const {
    companyId,
    bankId,
    accountName,
    bankBranch,
    bankName,
    bankRoutingCode,
    bankRoutingMethod,
    accountNo,
    swiftCode,
    transactionCurrency,
    currencies,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
    loading,
  } = state;

  const bankNameError =
    !bankName ||
    !validator.isAlpha(bankName, ["en-US"], { ignore: " -" }) ||
    bankName?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const bankNameHelperText = bankNameError ? "Required/Invalid" : "";

  const bankBranchError =
    !bankBranch ||
    !validator.isAlpha(bankBranch, ["en-US"], { ignore: " -" }) ||
    bankBranch?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const bankBranchHelperText = bankBranchError ? "Required/Invalid" : "";

  const accountNameError =
    !accountName ||
    !validator.isAlphanumeric(accountName, ["en-US"], { ignore: " -" }) ||
    accountName?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const accountNameHelperText = accountNameError ? "Required/Invalid" : "";

  const accountNoError =
    !accountNo ||
    !validator.isNumeric(accountNo, { no_symbols: true }) ||
    accountNo?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const accountNoHelperText = accountNoError
    ? "Required/Invalid/Should contain only numbers"
    : "";

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.logname, value);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isEmpty = (data) => {
    let response = true;
    if (data) {
      response = false;
    }
    return response;
  };

  const isValidData =
    !bankBranchError &&
    !bankNameError &&
    !isEmpty(transactionCurrency?.currencyShtCode) &&
    !accountNameError &&
    !accountNoError;

  useEffect(() => {
    (async () => {
      await handleGetCurrencies();

      if (applications[0]?.company?.bankDetails) {
        const {
          bankId,
          accountName,
          bankBranch,
          bankName,
          transactionCurrency,
          bankRoutingCode,
          bankRoutingMethod,
          accountNo,
          swiftCode,
        } = applications[0]?.company?.bankDetails ?? {};
        setState((prevState) => ({
          ...prevState,
          companyId,
          bankId,
          accountName,
          bankBranch,
          bankName,
          transactionCurrency,
          bankRoutingCode,
          bankRoutingMethod,
          accountNo,
          swiftCode,
        }));
      }
    })();
  }, [applications]);

  const handleApiData = async (params) => {
    // console.log{params});
    const { data, dataName, severity, message } = params;
    if (severity === "success" && dataName === `updatedCompany`) {
      await handleCompanyData();
      if (isValidData) {
        handleGoBack();
      }
    }

    if (severity === "error" && dataName === `currencies`) {
      alert(`Failed..`);
      window.location.reload();
    }

    setState((prevState) => ({
      ...prevState,
      [dataName]: data,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleGetCurrencies = async () => {
    const pageNumber = 1;
    const limit = 10;
    await makeApiRequest({
      uri: `/application/currencies?limit=${limit}&page=${pageNumber}`,
      dataName: `currencies`,
      method: `get`,
      data: "",
    });
  };

  const handleCompany = async () => {
    setSubmitDisabledButton(true);
    await makeApiRequest({
      uri: `/kyc/company/${companyId}`,
      dataName: `updatedCompany`,
      method: `put`,
      data: {
        ...applications[0]?.company,
        bankDetails: {
          ...applications[0]?.company?.bankDetails,
          bankId,
          accountName,
          bankBranch,
          bankName,
          bankRoutingCode,
          bankRoutingMethod,
          accountNo,
          transactionCurrency,
          swiftCode,
          bankDetailsFormStatus: isValidData ? "Complete" : "In Progress",
        },
      },
    });
    setSubmitDisabledButton(false);
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  // const [value, setValue] = useState<Date | null>(null);

  return (
    <Box sx={{ mt: 10 }} textAlign="left">
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <Stack direction="row" spacing={1}>
        <IconButton onClick={() => handleGoBack()} sx={{ mb: 4, mt: -0.5 }}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Supplier Bank Details Form{" "}
        </Typography>
      </Stack>
      <Stack direction="row" spacing={5}>
        <TextField
          error={bankNameError}
          helperText={bankNameHelperText}
          label="Bank Name"
          name="bankName"
          size="small"
          onChange={handleInput}
          value={bankName}
          style={inputStyle}
          fullWidth
        />
        <TextField
          error={bankBranchError}
          helperText={bankBranchHelperText}
          label="Bank Branch"
          name="bankBranch"
          size="small"
          onChange={handleInput}
          value={bankBranch}
          style={inputStyle}
          fullWidth
        />
        <TextField
          error={accountNameError}
          helperText={accountNameHelperText}
          label="Account Name"
          name="accountName"
          size="small"
          onChange={handleInput}
          value={accountName}
          style={inputStyle}
          fullWidth
        />
      </Stack>
      <Stack direction="row" spacing={5}>
        <FormControl
          fullWidth
          error={isEmpty(transactionCurrency?.currencyShtCode)}
        >
          <Autocomplete
            options={currencies}
            value={transactionCurrency}
            getOptionLabel={(option) => option?.currencyName ?? ""}
            getOptionSelected={(option, value) =>
              option?.currencyShtCode === value.currencyShtCode
            }
            // style={{ width: 200 }}
            onChange={(event, newValue) => {
              if (newValue) {
                const transactionCurrency = newValue;
                setState((prevState) => ({
                  ...prevState,
                  transactionCurrency,
                }));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Transaction Currency"
                variant="outlined"
              />
            )}
          />
          {isEmpty(transactionCurrency?.currencyShtCode) && (
            <FormHelperText>Required.</FormHelperText>
          )}
        </FormControl>

        <TextField
          error={accountNoError}
          helperText={accountNoHelperText}
          label="Bank Account Number"
          name="accountNo"
          size="small"
          onChange={handleInput}
          value={accountNo}
          style={inputStyle}
          fullWidth
        />

        <TextField
          label="Bank Routing Code"
          name="bankRoutingCode"
          size="small"
          onChange={handleInput}
          value={bankRoutingCode}
          style={inputStyle}
          fullWidth
        />
      </Stack>
      <Stack direction="row" spacing={5} sx={{ width: "31%" }}>
        <TextField
          label="Bank Routing Method"
          name="bankRoutingMethod"
          size="small"
          onChange={handleInput}
          value={bankRoutingMethod}
          style={inputStyle}
          fullWidth
        />
        <TextField
          label="Bank Swift Code"
          name="swiftCode"
          size="small"
          onChange={handleInput}
          value={swiftCode}
          style={inputStyle}
          fullWidth
        />
      </Stack>

      {/*<Button*/}
      {/*  variant="contained"*/}
      {/*  style={isValidData ? primaryButton : disabledButton}*/}
      {/*  onClick={() => handleCompany()}*/}
      {/*  disableElevation*/}
      {/*  disabled={!isValidData}*/}
      {/*>*/}
      {/*  SAVE*/}
      {/*</Button>*/}
      <Button
        variant="contained"
        style={isSubmitDisabledButton ? disabledButton : primaryButton}
        onClick={() => handleCompany()}
        disabled={isSubmitDisabledButton}
        disableElevation
      >
        {isValidData ? "Save & Go Back" : "Save"}
      </Button>

      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
