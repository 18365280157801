import apiRequest from "../services/api-request";

// const handleVerifyRegistration = async () => {
//     await makeApiRequest({
//         uri: `/users/email-verification?token=${email_verification_token}`,
//         dataName: `registeredUser`,
//         method: `get`,
//         data: ''
//     });
// }

const verifyRegistration = async (params) => {
  const { email_verification_token, toggleVerifyEmail } = params;

  const handleApiData = (params) => {
    const { data, dataName, severity } = params;
    if (severity === "success" && data?.operationResult === "SUCCESS") {
      toggleVerifyEmail({ isEmailVerified: true, isEmailVerifiedPage: true });
      // alert("Your email was successfully verified")
      // window.location.reload();
    } else {
      toggleVerifyEmail({ isEmailVerified: false, isEmailVerifiedPage: true });
    }
  };

  const makeApiRequest = async (params) => {
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
  };

  await makeApiRequest({
    uri: `/users/email-verification?token=${email_verification_token}`,
    dataName: `registeredUser`,
    method: `get`,
    data: "",
  });
};

export default verifyRegistration;
