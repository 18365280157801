import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Backdrop,
  CircularProgress,
  Link,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { formTitleStyle, formSubtitleStyle } from "../formStyles";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { AddCircle, ContactPageSharp, Delete, Edit } from "@mui/icons-material";
import AddOrUpdateBusinessValueProposition from "./add-business-value-proposition";
import {
  primaryButton,
  secondaryButton,
  disabledButton,
} from "../../../../common-styles";
import { statusSuccessStyle, statusFailStyle } from "../formStyles";
import { inputStyle } from "../formStyles";
import apiRequest from "../../../services/api-request";
import validator from "validator";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  input: {
    "& input[type=number]": {
      "-moz-appearance": "textfield",
    },
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
});

export default function PartD(props) {
  const classes = useStyles();

  const { handleBack, applications, handleCompanyData, handleNext } = props;

  delete applications[0]?.company?.application;

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const { companyId } = applications[0]?.company;

  const initialState = {
    currentSection: "D1",
    businessProposalDoc: applications[0]?.company?.businessProposalDoc ?? "",
    businessValueProposition: [],
    currentBusinessValueProposition: {},
    valuePropositionAssumptions: "",
    loading: false,
  };

  const [state, setState] = useState(initialState);

  const [isUpdating, setIsUpdating] = useState(false);

  const {
    currentSection,
    action,
    loading,
    businessProposalDoc,
    businessValueProposition,
    currentBusinessValueProposition,
    valuePropositionAssumptions,
  } = state;

  const {
    businessArea = "",
    consumerInsight = "",
    targetMarket = "",
    marketingStrategies = "",
    financialProjections,
  } = currentBusinessValueProposition ?? {};

  const year1 = financialProjections?.year1 ?? 0;
  const year2 = financialProjections?.year2 ?? 0;
  const year3 = financialProjections?.year3 ?? 0;

  const businessAreaError =
    !businessArea ||
    businessArea?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const businessAreaHelperText = businessAreaError ? "Invalid" : "";

  const consumerInsightError =
    !consumerInsight ||
    consumerInsight?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const consumerInsightHelperText = consumerInsightError ? "Invalid" : "";

  const marketingStrategiesError =
    !marketingStrategies ||
    marketingStrategies?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const marketingStrategiesHelperText = marketingStrategiesError
    ? "Invalid"
    : "";

  const targetMarketError =
    !targetMarket ||
    targetMarket?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const targetMarketHelperText = targetMarketError ? "Invalid" : "";

  const year1Error =
    !year1 ||
    !validator.isNumeric(year1.toString(), { no_symbols: true }) ||
    !(year1 > 0) ||
    year1?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const year1HelperText = year1Error ? "Invalid/Should be greater than 0" : "";

  const year2Error =
    !year2 ||
    !validator.isNumeric(year2.toString(), { no_symbols: true }) ||
    !(year2 > 0) ||
    year2?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const year2HelperText = year2Error ? "Invalid/Should be greater than 0" : "";

  const year3Error =
    !year3 ||
    !validator.isNumeric(year3.toString(), { no_symbols: true }) ||
    !(year3 > 0) ||
    year3?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_TWENTY;
  const year3HelperText = year3Error ? "Invalid/Should be greater than 0" : "";

  // const columns = [
  //   {
  //     name: "businessArea",
  //     label: "Business Area",
  //     options: {
  //       filter: true,
  //       sort: true,
  //     },
  //   },
  //   {
  //     name: "financialProjections",
  //     label: "Financial Projections",
  //     options: {
  //       filter: true,
  //       sort: false,
  //       customBodyRender: (value) => {
  //         return (
  //           <>
  //             <Stack direction="row" spacing={3}>
  //               <Typography sx={{ fontSize: 15 }}>{value?.year1}</Typography>
  //               <Typography sx={{ fontSize: 15 }}>{value?.year2}</Typography>
  //               <Typography sx={{ fontSize: 15 }}>{value?.year3}</Typography>
  //             </Stack>
  //           </>
  //         );
  //       },
  //     },
  //   },
  //   {
  //     name: "marketingStrategies",
  //     label: "Marketing Strategies",
  //     options: {
  //       filter: true,
  //       sort: false,
  //     },
  //   },
  //   {
  //     name: "targetMarket",
  //     label: "Target Market",
  //     options: {
  //       filter: true,
  //       sort: false,
  //     },
  //   },
  //   {
  //     name: "consumerInsight",
  //     label: "Consumer Insight",
  //     options: {
  //       filter: true,
  //       sort: false,
  //     },
  //   },
  //   {
  //     name: "propositionId",
  //     label: "Action",
  //     options: {
  //       download: false,
  //       filter: true,
  //       sort: false,
  //       customBodyRender: (value) => {
  //         const currentBusinessValueProposition =
  //           businessValueProposition.filter(
  //             (proposition) => proposition?.propositionId == value
  //           )[0];
  //         return (
  //           <IconButton
  //             aria-label="delete"
  //             size="large"
  //             onClick={() => {
  //               handleDeleteBusinessValueProposition(
  //                 currentBusinessValueProposition?.propositionId
  //               );
  //             }}
  //           >
  //             <Delete />
  //           </IconButton>
  //         );
  //       },
  //     },
  //   },
  // ];

  // const options = {
  //   selectableRows: "none",
  //   download: false,
  //   jumpToPage: true,
  //   onRowClick: (_rowData, rowMeta) => {
  //     // console.log({ _rowData });
  //     // console.log({ rowMeta });
  //     if (rowMeta?.dataIndex >= 0) {
  //       const currentBusinessValueProposition = {
  //         ...businessValueProposition[rowMeta?.dataIndex],
  //       };
  //       setState((prevState) => ({
  //         ...prevState,
  //         currentBusinessValueProposition,
  //         action: "Update",
  //       }));
  //     }
  //   },
  // };

  const handleAccordionChange = (currentSection) => {
    setState((prevState) => ({
      ...prevState,
      currentSection,
    }));
  };
  const handleAddProposition = () => {
    setState((prevState) => ({
      ...prevState,
      action: "Add",
    }));
  };

  const handleDeleteBusinessValueProposition = async (propositionId) => {
    await makeApiRequest({
      uri: `/kyc/company/${companyId}/businessValueProposition/${propositionId}`,
      dataName: `deletedProposition`,
      method: `delete`,
      data: {},
    });
  };

  const handleCloseDialog = () => {
    setState((prevState) => ({
      ...prevState,
      action: "Close",
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    const { currentBusinessValueProposition } = state;

    if (currentSection === "D2") {
      if (name === "year1" || name === "year2" || name === "year3") {
        const financialProjections = {
          ...currentBusinessValueProposition.financialProjections,
          [name]: value,
        };

        // console.log({financialProjections})

        const updatedBusinessValueProposition = {
          ...currentBusinessValueProposition,
          financialProjections,
        };

        setState((prevState) => ({
          ...prevState,
          currentBusinessValueProposition: updatedBusinessValueProposition,
        }));
      } else {
        const updatedBusinessValueProposition = {
          ...currentBusinessValueProposition,
          [name]: value,
        };
        setState((prevState) => ({
          ...prevState,
          currentBusinessValueProposition: updatedBusinessValueProposition,
        }));
      }
    } else {
      setState((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleApiData = async (params) => {
    // console.log{params});
    const { severity, data, dataName, message } = params;
    if (severity === "success") {
      if (dataName === "businessProposalDoc") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.docInfo?.sysDocid,
        }));
      }

      if (dataName === "deletedProposition") {
        handleCompanyData();
      }

      if (dataName === "addedProposition") {
        handleCompanyData();
      }

      // const {companyId} = data;
      if (dataName === "updatedCompany") {
        props.handleLastStep();
      }

      setState((prevState) => ({
        ...prevState,
        snackbarOpen: true,
        snackbarMessage: message,
        snackBarSeverity: severity,
      }));
    }
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  useEffect(() => {
    const { applications } = props;

    if (applications[0]?.company) {
      const {
        companyId,
        businessProposalDoc,
        valuePropositionAssumptions,
        businessValueProposition,
      } = applications[0]?.company;

      setState((prevState) => ({
        ...prevState,
        companyId,
        businessProposalDoc,
        valuePropositionAssumptions,
        businessValueProposition,
      }));
    }
  }, []);

  useEffect(() => {
    const { currentBusinessValueProposition } = state;

    // console.log({ currentBusinessValueProposition });

    if (isUpdating && currentBusinessValueProposition) {
      const {
        businessArea = "",
        consumerInsight = "",
        targetMarket = "",
        marketingStrategies = "",
        financialProjections,
      } = currentBusinessValueProposition ?? {};

      const projectionId = financialProjections?.projectionId ?? "";
      const year1 = financialProjections?.year1 ?? 0;
      const year2 = financialProjections?.year2 ?? 0;
      const year3 = financialProjections?.year3 ?? 0;

      setState((prevState) => ({
        ...prevState,
        businessArea: businessArea ?? "",
        consumerInsight: consumerInsight ?? "",
        marketingStrategies: marketingStrategies ?? "",
        targetMarket: targetMarket ?? "",
        year1: year1 ?? 0,
        year2: year2 ?? 0,
        year3: year3 ?? 0,
        projectionId: projectionId ?? "",
      }));
    }
  }, [currentBusinessValueProposition]);

  const handleFileUpload = async (params) => {
    const { event, docType } = params;
    if (event?.target?.files) {
      const file = event.target.files[0];
      const fileExtension = file.type;
      const allowedFiles = process.env.REACT_APP_ALLOWED_UPLOAD_FILE_TYPES;
      if (
        !allowedFiles
          .toLocaleLowerCase()
          .includes(fileExtension.toLocaleLowerCase())
      ) {
        alert(`${fileExtension} not allowed`);
        return;
      }
      const data = new FormData();
      data.append("file", file);
      data.append("companyId", companyId);
      data.append("docNo", "0");
      data.append("docType", docType);
      data.append("module", "companyDocs");
      await makeApiRequest({
        uri: `/upload`,
        dataName: docType,
        method: `post`,
        data,
      });
    }
  };

  const isEmpty = (data) => {
    let response = true;
    if (data) {
      response = false;
    }
    return response;
  };

  const isValidData = !isEmpty(businessProposalDoc);

  const handleSelectValueProposition = (proposition) => {
    setIsUpdating(true);
    setState((prevState) => ({
      ...prevState,
      currentBusinessValueProposition: proposition,
    }));
  };

  const removeDuplicatePropositionsromArrayOfObjects = (objArray) => {
    if (objArray?.length < 1) return [];

    let uniqueObjArray = [
      ...new Map(objArray.map((item) => [item["businessArea"], item])).values(),
    ];

    return uniqueObjArray;
  };

  const handleAddBusinessValueProposition = () => {
    let { businessValueProposition, currentBusinessValueProposition } = state;

    if (isUpdating) {
      const indexOfValueProposition = businessValueProposition.findIndex(
        (proposition) => {
          return (
            proposition?.propositionId ==
            currentBusinessValueProposition?.propositionId
          );
        }
      );

      businessValueProposition.splice(
        indexOfValueProposition,
        1,
        currentBusinessValueProposition
      );

      setIsUpdating(false);
    } else {
      businessValueProposition?.push(currentBusinessValueProposition);
    }

    businessValueProposition = removeDuplicatePropositionsromArrayOfObjects(
      businessValueProposition
    );

    setState((prevState) => ({
      ...prevState,
      businessValueProposition,
      currentBusinessValueProposition: {},
    }));

    // console.log({currentBusinessValueProposition})

    handleSubmitBusinessValueProposition( businessValueProposition, currentBusinessValueProposition);
  };


  const handleSubmitBusinessValueProposition = async (
    businessValueProposition,
    currentBusinessValueProposition
  ) => {
    const updatedBusinessValueProposition = isUpdating
      ? businessValueProposition?.map((proposition) =>
          proposition.propositionId === currentBusinessValueProposition.propositionId
            ? currentBusinessValueProposition
            : proposition
        )
      : [...businessValueProposition, currentBusinessValueProposition];

    await makeApiRequest({
      uri: `/kyc/company/${companyId}`,
      dataName: `addedProposition`,
      method: `put`,
      data: {
        ...applications[0]?.company,
        businessValueProposition: updatedBusinessValueProposition,
      },
    });
  };


  // const handleSubmitBusinessValueProposition = async (
  //   currentBusinessValueProposition
  // ) => {
  //   await makeApiRequest({
  //     uri: `/kyc/company/${companyId}`,
  //     dataName: `addedProposition`,
  //     method: `put`,
  //     data: {
  //       ...applications[0]?.company,
  //       businessValueProposition: [
  //         ...applications[0]?.company?.businessValueProposition,
  //         {
  //           ...currentBusinessValueProposition,
  //         },
  //       ],
  //     },
  //   });
  // };

  const handleCompany = async () => {
    await makeApiRequest({
      uri: `/kyc/company/${companyId}`,
      dataName: `updatedCompany`,
      method: `put`,
      data: {
        ...applications[0]?.company,
        businessProposalDoc,
        valuePropositionAssumptions,
        applicationComplete: true,
      },
    });
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1, p: 2 }}>
        <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
          <CircularProgress color="success" />
        </Backdrop>

        <Box sx={{ pt: 5, pb: 6 }}>
          <Typography textAlign="left" style={formTitleStyle}>
            PART D
          </Typography>

          <Typography textAlign="left" style={formSubtitleStyle} sx={{ pt: 2 }}>
            Business Plan/Business Value Proposition
          </Typography>
        </Box>

        <Accordion
          expanded={currentSection === "D1"}
          onChange={() => handleAccordionChange("D1")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography textAlign="left" style={formSubtitleStyle}>
              D1: Upload Detailed Business Plan
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TableContainer sx={{ mt: -3 }}>
              <Table>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      Detailed Business Plan
                    </TableCell>
                    <TableCell>PDF</TableCell>
                    <TableCell>
                      <Typography
                        style={
                          businessProposalDoc
                            ? statusSuccessStyle
                            : statusFailStyle
                        }
                      >
                        {businessProposalDoc
                          ? "Uploaded successfully"
                          : "Not uploaded"}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Link
                        href={`${process.env.REACT_APP_HOMEPAGE}/files/BusinessPlanTemplate.docx`}
                        target="_blank"
                        download
                        sx={{ cursor: "pointer" }}
                      >
                        <Button style={primaryButton} sx={{ mr: 2 }}>
                          DOWNLOAD TEMPLATE
                        </Button>
                      </Link>
                      <label htmlFor="businessProposalDoc">
                        <input
                          type="file"
                          id="businessProposalDoc"
                          accept="application/pdf"
                          style={{ display: "none" }}
                          onChange={(event) =>
                            handleFileUpload({
                              event,
                              docType: `businessProposalDoc`,
                            })
                          }
                        />
                        <Button
                          style={secondaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                        >
                          UPLOAD
                        </Button>
                      </label>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </AccordionDetails>
        </Accordion>

        <Accordion
          expanded={currentSection === "D2"}
          onChange={() => handleAccordionChange("D2")}
        >
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Box>
              <Typography textAlign="left" style={formSubtitleStyle}>
                D2: Add Business Value Proposition Summary
              </Typography>
              <Typography textAlign="left" sx={{ fontSize: "14px", mb: 2 }}>
                <b>NOTE:</b> Be as specific as possible on the business area as
                well as the consumer insight indicating the need the product
                will fulfill within the market. <br />
                <b>HINT: </b>This section is not mandatory but the items below
                should be clear in your business plan document
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ pl: 4, pr: 4, pb: 4 }}>
            <TextField
              error={businessAreaError}
              helperText={businessAreaHelperText}
              label="Business Area"
              name="businessArea"
              type="text"
              size="small"
              style={inputStyle}
              onChange={handleInput}
              value={businessArea}
              fullWidth
              required
            />

            <Stack direction="row" spacing={5}>
              <TextField
                error={marketingStrategiesError}
                helperText={marketingStrategiesHelperText}
                label="Marketing Strategies"
                name="marketingStrategies"
                type="text"
                multiline
                onChange={handleInput}
                value={marketingStrategies}
                fullWidth
              />
              <TextField
                error={targetMarketError}
                helperText={targetMarketHelperText}
                label="Target Market"
                name="targetMarket"
                type="text"
                multiline
                onChange={handleInput}
                value={targetMarket}
                fullWidth
              />
              <TextField
                error={consumerInsightError}
                helperText={consumerInsightHelperText}
                label="Consumer Insight"
                name="consumerInsight"
                type="text"
                multiline
                onChange={handleInput}
                value={consumerInsight}
                fullWidth
              />
            </Stack>

            <Stack direction="row" spacing={5} sx={{ mt: 4 }}></Stack>

            <Typography>Financial Projections:</Typography>

            <Stack direction="row" spacing={5} sx={{ mt: 2, mb: 3 }}>
              <TextField
                className={classes.input}
                error={year1Error}
                helperText={year1HelperText}
                name="year1"
                label="Year 1"
                type="number"
                size="small"
                pattern="[0-9]"
                onChange={handleInput}
                value={year1}
                fullWidth
                // disabled={!canUpdate}
              />
              <TextField
                className={classes.input}
                error={year2Error}
                helperText={year2HelperText}
                name="year2"
                label="Year 2"
                type="number"
                size="small"
                pattern="[0-9]"
                onChange={handleInput}
                value={year2}
                fullWidth
                // disabled={!canUpdate}
              />
              <TextField
                className={classes.input}
                error={year3Error}
                helperText={year3HelperText}
                name="year3"
                label="Year 3"
                type="number"
                size="small"
                pattern="[0-9]"
                onChange={handleInput}
                value={year3}
                fullWidth
                // disabled={!canUpdate}
              />
            </Stack>

            <Stack>
              <Box sx={{ mt: 2 }}>
                <Button
                  style={
                    businessAreaError ||
                    year1Error ||
                    year2Error ||
                    year3Error ||
                    marketingStrategiesError ||
                    targetMarketError ||
                    consumerInsightError
                      ? disabledButton
                      : primaryButton
                  }
                  onClick={() => handleAddBusinessValueProposition()}
                  disabled={
                    businessAreaError ||
                    year1Error ||
                    year2Error ||
                    year3Error ||
                    marketingStrategiesError ||
                    targetMarketError ||
                    consumerInsightError
                  }
                >
                  {isUpdating ? "UPDATE" : "ADD"}
                </Button>
              </Box>
            </Stack>

            <TableContainer sx={{ mt: 5, backgroundColor: "#f9f9f9" }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Business Area
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Marketing Strategies
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Target Market
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Consumer Insight
                    </TableCell>
                    <TableCell sx={{ fontWeight: "bold" }}>
                      Financial Projections
                    </TableCell>

                    <TableCell align="right">Edit Proposition </TableCell>
                    <TableCell align="right">Delete Proposition</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {businessValueProposition?.map((valueProposition, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <TableCell component="th" scope="row">
                        <Typography>
                          {valueProposition?.businessArea}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {valueProposition?.marketingStrategies}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {valueProposition?.targetMarket}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography>
                          {valueProposition?.consumerInsight}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography style={{ color: "#159F4A" }}>
                          {valueProposition?.financialProjections?.year1},{" "}
                          {valueProposition?.financialProjections?.year2},{" "}
                          {valueProposition?.financialProjections?.year3}
                        </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="edit"
                          size="large"
                          onClick={() => {
                            handleSelectValueProposition(valueProposition);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          aria-label="delete"
                          size="large"
                          sx={{ color: "#EA2337" }}
                          onClick={() => {
                            handleDeleteBusinessValueProposition(
                              valueProposition?.propositionId
                            );

                            const { businessValueProposition } = state;

                            const indexOfValueProposition =
                              businessValueProposition.findIndex(
                                (currentValueProposition) => {
                                  return (
                                    currentValueProposition?.propositionId ==
                                    valueProposition?.propositionId
                                  );
                                }
                              );

                            businessValueProposition.splice(
                              indexOfValueProposition,
                              1
                            );

                            setState((prevState) => ({
                              ...prevState,
                              businessValueProposition,
                            }));
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            {/* <Box sx={{ mt: 5 }}>
              <Button
                variant="contained"
                style={isValidData ? primaryButton : disabledButton}
                onClick={() => {
                  handleSubmitBusinessValueProposition();
                }}
                disableElevation
                disabled={!isValidData}
              >
                {action === "Add" ? "Save" : "Update"}
              </Button>
            </Box> */}
          </AccordionDetails>
        </Accordion>

        <Box>
          {/* {(action === "Add" || action === "Update") && (
            <AddOrUpdateBusinessValueProposition
              handleCompanyData={handleCompanyData}
              action={action}
              applications={applications}
              handleCloseDialog={handleCloseDialog}
              currentBusinessValueProposition={currentBusinessValueProposition}
            />
          )} */}

          {/* <MUIDataTable
            title={
              <Button
                style={primaryButton}
                onClick={() => {
                  handleAddProposition(`Add`);
                }}
                startIcon={<AddCircle />}
              >
                New Business Value Proposition
              </Button>
            }
            data={businessValueProposition}
            columns={columns}
            options={options}
          /> */}

          <Box sx={{ mt: 6 }}>
            <TextField
              label="Assumptions (If any)"
              name="valuePropositionAssumptions"
              onChange={handleInput}
              value={valuePropositionAssumptions}
              multiline
              fullWidth
            />
          </Box>

          <Box sx={{ display: "flex", flexDirection: "row", pt: 5 }}>
            <Button
              color="inherit"
              variant="contained"
              style={secondaryButton}
              disableElevation
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {/* <Button
              variant="contained"
              style={
                businessValueProposition?.length > 0
                  ? primaryButton
                  : disabledButton
              }
              onClick={() => {
                handleCompany();
              }}
              disableElevation
              disabled={businessValueProposition?.length < 1}
            >
              Submit Application
            </Button> */}
            <Button
              variant="contained"
              style={isValidData ? primaryButton : disabledButton}
              onClick={() => {
                handleCompany();
              }}
              disableElevation
              disabled={!isValidData}
            >
              Submit Application
            </Button>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
