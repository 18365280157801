import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  TextField,
} from "@mui/material";
import { primaryButton, disabledButton } from "../../common-styles";
import { formStyle, inputStyle } from "./user-management-styles";
import { useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import apiRequest from "../services/api-request";
import LoginAppBar from "./login-app-bar";
import LeftImage from "./left-image";
import * as Email from "email-validator";

export default function LoginPage(props) {
  const { handleSignUpToggle, togglePasswordResetRequest, handleSignedInUser } =
    props;

  // const classes = useStyles();

  const initialState = {
    emailHelperText: "",
    passwordHelperText: "",
    otpHelperText: "Otp Required",
    invalidEmail: true,
    invalidPassword: true,
    username: "",
    password: "",
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    tfa_code: "",
    tfa_token: "",
    isOtpPage: false,
    loading: false,
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,

    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
  };

  const [state, setState] = useState(initialState);

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const {
    username,
    password,
    client_id,
    client_secret,
    tfa_code,
    tfa_token,
    isOtpPage,
    emailHelperText,
    passwordHelperText,
    otpHelperText,
    invalidEmail,
    invalidPassword,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
    loading,
  } = state;

  const handleInput = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    let {
      emailHelperText,
      passwordHelperText,
      otpHelperText,
      invalidEmail,
      invalidPassword,
    } = state;

    // Validate Email
    if (inputName === "username") {
      invalidEmail = true;
      emailHelperText = "Invalid email";
      if (Email.validate(inputValue)) {
        invalidEmail = false;
        emailHelperText = "";
      }
    }

    // Validate Password
    if (inputName === "password") {
      invalidPassword = true;
      passwordHelperText = "Invalid password";
      if (inputValue.length > 0) {
        invalidPassword = false;
        passwordHelperText = "";
      }
    }

    // Validate Otp
    if (inputName === "tfa_code") {
      otpHelperText = "Invalid OTP";
      if (inputValue.length == process.env.REACT_APP_OTP_MIN_CHARACTERS) {
        otpHelperText = "";
      }
    }

    setState((prevState) => ({
      ...prevState,
      [inputName]: inputValue,
      invalidEmail,
      emailHelperText,
      invalidPassword,
      passwordHelperText,
      otpHelperText,
    }));
  };

  const toggleOtpPage = (tfa_token) => {
    setState((prevState) => ({
      ...prevState,
      isOtpPage: true,
      tfa_token,
    }));
  };

  const handleApiData = (params) => {
    // console.log{params});
    const { data, dataName, severity, message, messageDescription } = params;
    if (data?.tfaToken) {
      toggleOtpPage(data?.tfaToken);
    }
    if (data?.accessToken) {
      handleSignedInUser(data);
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleSignIn = async (event) => {
    event?.preventDefault();
    await makeApiRequest({
      uri: `/login/request/otp`,
      dataName: `signedInUser`,
      method: `post`,
      data: {
        grantType: process.env.REACT_APP_SIGN_IN_GRANT_TYPE,
        clientSecret: client_secret,
        clientId: client_id,
        username,
        password,
      },
    });
  };

  const handleOtpVerification = async (event) => {
    event?.preventDefault();
    await makeApiRequest({
      uri: `/login/validate/otp`,
      dataName: `signedInUser`,
      method: `post`,
      data: {
        grantType: process.env.REACT_APP_TFA_GRANT_TYPE,
        clientSecret: client_secret,
        clientId: client_id,
        tfaToken: tfa_token,
        tfaCode: tfa_code,
      },
    });
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <Box>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      <LoginAppBar />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <LeftImage />
        </Grid>

        <Grid item xs={12} sm={6} style={formStyle}>
          {!isOtpPage && (
            <>
              <form noValidate onSubmit={handleSignIn}>
                <>
                  <Box>
                    <Typography
                      sx={{ fontSize: 23, fontWeight: "bold", mb: 3 }}
                    >
                      Welcome Back
                    </Typography>
                    <Box>
                      <TextField
                        value={username}
                        error={invalidEmail}
                        helperText={emailHelperText}
                        label="Email Address"
                        name="username"
                        size="small"
                        style={inputStyle}
                        onChange={handleInput}
                        fullWidth
                        required
                      />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <TextField
                        value={password}
                        error={invalidPassword}
                        helperText={passwordHelperText}
                        label="Password"
                        name="password"
                        type={showPassword ? "text" : "password"}
                        size="small"
                        style={inputStyle}
                        onChange={handleInput}
                        fullWidth
                        required
                        autoComplete="current-password"
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                              >
                                {showPassword ? (
                                  <Visibility />
                                ) : (
                                  <VisibilityOff />
                                )}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ mb: 5, mt: 2 }}>
                    <Button
                      type="submit"
                      variant="contained"
                      style={
                        invalidEmail || invalidPassword
                          ? disabledButton
                          : primaryButton
                      }
                      disableElevation
                      disabled={invalidEmail || invalidPassword}
                    >
                      SIGN IN
                    </Button>
                  </Box>
                </>
              </form>

              <Box sx={{ mb: 3 }}>
                <Typography sx={{ fontSize: 15 }}>
                  Forgot your password?{" "}
                  <span
                    style={{
                      color: "#159F4A",
                      textDecoration: "underline",
                      fontWeight: "bolder",
                    }}
                  >
                    <Link
                      sx={{ color: "#159F4A", cursor: "pointer" }}
                      onClick={() => togglePasswordResetRequest()}
                    >
                      Reset
                    </Link>
                  </span>
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontSize: 15 }}>
                  Don't have an account?
                  <span
                    style={{
                      color: "#159F4A",
                      textDecoration: "underline",
                      fontWeight: "bolder",
                    }}
                  >
                    <Link
                      sx={{ color: "#159F4A", cursor: "pointer" }}
                      onClick={() => handleSignUpToggle()}
                    >
                      {" "}
                      Sign Up
                    </Link>
                  </span>
                </Typography>
              </Box>
              <Box sx={{ mt: 4 }}>
                <Typography>
                  <Link
                    sx={{
                      color: "#159F4A",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                    href="https://domains.safaricom.co.ke/cart.php?a=add&domain=register"
                    target="_blank"
                    rel="noopener"
                  >
                    Click here to register company domain name in case you do
                    not have one
                  </Link>
                </Typography>
              </Box>
              <Box sx={{ mt: 3, mb: 3 }}>
                <Typography>
                  <Link
                    sx={{
                      color: "#159F4A",
                      cursor: "pointer",
                      fontWeight: "bold",
                      fontSize: 14,
                    }}
                    href="https://www.safaricom.co.ke/dataprivacystatement/"
                    target="_blank"
                    rel="noopener"
                  >
                    Click here to read the Safaricom Data Privacy Statement
                  </Link>
                </Typography>
              </Box>
            </>
          )}

          {isOtpPage && (
            <form noValidate onSubmit={handleOtpVerification}>
              <Box>
                <h2>Welcome Back</h2>
                <Box>
                  <TextField
                    value={tfa_code}
                    error={otpHelperText}
                    helperText={otpHelperText}
                    label="OTP"
                    name="tfa_code"
                    size="small"
                    style={inputStyle}
                    onChange={handleInput}
                    fullWidth
                    required
                  />
                </Box>
              </Box>
              <Box>
                <Button
                  variant="contained"
                  style={otpHelperText ? disabledButton : primaryButton}
                  disableElevation
                  onClick={handleOtpVerification}
                  disabled={otpHelperText}
                >
                  VERIFY OTP
                </Button>
              </Box>
              <Box sx={{ mt: 4 }}>
                <Typography>
                  <Link
                    sx={{
                      color: "#159F4A",
                      cursor: "pointer",
                      fontWeight: "bold",
                    }}
                    onClick={() => handleSignIn()}
                  >
                    Resend OTP
                  </Link>
                </Typography>
              </Box>
            </form>
          )}
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
