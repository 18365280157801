import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Container,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  TextField,
  Typography,
  Snackbar,
  CircularProgress,
} from "@mui/material";
import { Alert } from "@mui/lab";
import {
  approveButton,
  disabledButton,
  modalStyle,
  modalStyle2,
  primaryButton,
  rejectButton,
  secondaryButton,
} from "../../../../common-styles";
import { ArrowBack } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { inputStyle } from "../../../partner/application-form/formStyles";
import apiRequest from "../../../services/api-request";

const sectionHeadingStyle = {
  fontWeight: "bold",
  color: "#159F4A",
  fontSize: "1.2em",
};

const tableHeaderStyle = {
  fontWeight: "bold",
};

export default function ApplicationDetailsVasPlanningPage(props) {
  const { togglePage, currentTask } = props;

  const { wfId } = currentTask;

  const { companyId } = currentTask?.wfCurrTrans;

  const initialState = {
    approved: false,
    comment: "",
    commentError: false,
    commentHelperText: "",
    currentSelection: "",
    sdpDetails: {},
    loading: false,
    additionalApplicationsSelection: "",
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,

    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
  };

  const [state, setState] = useState(initialState);

  const {
    comment,
    currentSelection,
    commentError,
    commentHelperText,
    loading,
    sdpDetails,
    additionalApplicationsSelection,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarVerticalPosition,
    snackBarHorizontalPosition,
  } = state;

  // const user = 'admin';

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const handleApiData = async (params) => {
    // console.log{params});
    const { severity, message, dataName, data, headers } = params;

    if (severity === "success") {
      const contentType = headers["content-type"];
      // console.log({ contentType });

      if (dataName === "fetchedFile") {
        const file = new Blob([data], { type: contentType });

        const fileURL = URL.createObjectURL(file);

        const pdfWindow = window.open();

        pdfWindow.location.href = fileURL;
      }

      if (dataName === "sdpDetails") {
        setState((prevState) => ({
          ...prevState,
          [dataName]: data,
        }));
      }
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  useEffect(() => {
    makeApiRequest({
      uri: `/kyc/company/sdpDetails/${companyId}`,
      dataName: `sdpDetails`,
      method: `get`,
      data: {},
    });
  }, []);

  const handleTaskValidateSdpApplication = async () => {
    await makeApiRequest({
      uri: `/tickets/complete-task-validate-sdp-application`,
      dataName: `approvedCompany`,
      method: `post`,
      data: {
        approved: currentSelection == "approveApplication" ? true : false,
        comment,
        wfId,
      },
    });
    handleCloseApprovalModal();
    window.location.reload();
  };

  // const handleCompleteTaskTestSdpApplication = async () => {
  //   await makeApiRequest({
  //     uri: `/tickets/complete-task-test-sdp-application`,
  //     dataName: `approvedCompany`,
  //     method: `post`,
  //     data: {
  //       approved: currentSelection == "approveApplication" ? true : false,
  //       comment,
  //       wfId,
  //     },
  //   });
  //   handleCloseModal();
  //   window.location.reload();
  // };

  const handleCompleteTaskTestSdpApplication = async () => {
    if (!window.confirm(`Are you sure you wish to complete SPD Testing task`)) {
      return false;
    }
    await makeApiRequest({
      uri: `/tickets/complete-task-test-sdp-application`,
      dataName: `sdpTesting`,
      method: `post`,
      data: {
        approved: true,
        comment: "SDP Testing Completed Successfully",
        wfId: wfId,
      },
    });
    window.location.reload();
  };

  const handleGetFiles = async (fileId) => {
    await makeApiRequest({
      uri: `/files/file?fileId=${fileId}`,
      dataName: `fetchedFile`,
      method: `get`,
      data: {},
    });
  };

  const handleApproval = (currentSelection) => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      currentSelection,
    }));
  };

  const handleAdditionalApplication = (additionalApplicationsSelection) => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      additionalApplicationsSelection,
    }));
  };

  const handleCloseApprovalModal = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      currentSelection: "",
    }));
  };

  const handleCloseModal = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      currentSelection: "",
    }));
  };

  const handleCloseAdditionalApplicationsModal = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      additionalApplicationsSelection: "",
    }));
  };

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      commentError: name === "comment" ? isEmpty(value) : commentError,
      commentHelperText:
        name === "comment"
          ? isEmpty(value)
            ? "Required."
            : ""
          : commentHelperText,
    }));
  };

  const isEmpty = (data) => {
    let response = true;
    if (data) {
      response = false;
    }
    return response;
  };

  const isValidData = !isEmpty(comment);

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <Box sx={{ flexGrow: 1, pb: 5, pt: 4, backgroundColor: "#f9f9f9" }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>
      {/* <PDFViewerDisplay fileData={fileData} /> */}
      <Grid container direction="row" alignItems="center">
        <IconButton
          onClick={() =>
            togglePage({
              currentPage: "pendingTaskList",
              currentTask,
            })
          }
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
          CSP Application #{currentTask?.wfCurrTrans?.companyId}
        </Typography>
      </Grid>

      <Grid container>
        <Grid item xs={9}>
          <Container sx={{ pt: 3 }}>
            <Typography textAlign="left" style={sectionHeadingStyle}>
              Company Information
            </Typography>
            <Container>
              <Grid container sx={{ pt: 3, ml: -4 }}>
                <Grid item xs={6}>
                  <List>
                    {[
                      "Registered Company",
                      "Company Email Address",
                      "Company Website",
                      "Company Mobile Number",
                      "Physical Address",
                    ].map((value) => {
                      const listItemId = `${value}`;
                      return (
                        <ListItem sx={{ p: 1 }}>
                          <Typography
                            id={listItemId}
                            sx={{ fontWeight: "bold" }}
                          >
                            {`${value}`}
                          </Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
                <Grid item xs={6}>
                  <List>
                    <ListItem sx={{}}>
                      <Typography>
                        {sdpDetails?.companyDetails?.registeredCompanyName}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        {sdpDetails?.companyDetails?.companyEmailAddress}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        {sdpDetails?.companyDetails?.companyWebsiteUrl}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        {sdpDetails?.companyDetails?.companyMobileNumber}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        {sdpDetails?.companyDetails?.physicalLocation}
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Container>
          </Container>
          <Container sx={{ pt: 3 }}>
            <Typography textAlign="left" style={sectionHeadingStyle}>
              Testing Details
            </Typography>
            <Container>
              <Grid container sx={{ pt: 3, ml: -4 }}>
                <Grid item xs={6}>
                  <List>
                    {[
                      "Technical Email Address",
                      "Business Email Address",
                      "Skype ID",
                      "Application Source IP Address",
                      "Phone Number",
                      "Services Intended to do Tests",
                    ].map((value) => {
                      const listItemId = `${value}`;
                      return (
                        <ListItem sx={{ p: 1 }}>
                          <Typography
                            id={listItemId}
                            sx={{ fontWeight: "bold" }}
                          >
                            {`${value}`}
                          </Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
                <Grid item xs={6}>
                  <List>
                    <ListItem sx={{}}>
                      <Typography>{sdpDetails?.technicalEmail}</Typography>
                    </ListItem>
                    <ListItem sx={{}}>
                      <Typography>{sdpDetails?.businessEmail}</Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>{sdpDetails?.skypeId}</Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>{sdpDetails?.applicationSourceIp}</Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>{sdpDetails?.phoneNumber}</Typography>
                    </ListItem>
                    <ListItem>
                      <List>
                        {sdpDetails?.sdpServices?.map((sdpService, index) => (
                          <Box
                            key={index}
                            sx={{ mt: -1 }}
                            style={{ cursor: "pointer" }}
                          >
                            <Typography>
                              {sdpService.sdpServiceName},{" "}
                            </Typography>
                          </Box>
                        ))}
                      </List>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Container>
          </Container>

          <Container sx={{ pt: 4 }}>
            <Typography textAlign="left" style={sectionHeadingStyle}>
              Other
            </Typography>
            <TableContainer sx={{ ml: -2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={tableHeaderStyle}>Application</TableCell>
                    <TableCell style={tableHeaderStyle}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        Signed Contract
                      </TableCell>
                      <TableCell>PDF</TableCell>
                      <TableCell align="right">
                        <Button
                          style={secondaryButton}
                          variant="contained"
                          component="span"
                          disableElevation
                          onClick={() =>
                            handleGetFiles(
                              currentTask?.wfCurrTrans?.contractDoc
                            )
                          }
                        >
                          VIEW
                        </Button>
                      </TableCell>
                    </TableRow>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      VPN Configuration Details
                    </TableCell>
                    <TableCell>
                      <Button
                        style={secondaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() =>
                          handleAdditionalApplication("vpnConfigurationDetails")
                        }
                      >
                        VIEW
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Grid>

        <Grid item xs={3}>
          <Box sx={{ mb: 3 }}>
            {currentTask?.wfActiveProcess == "task_validate_sdp_details" && (
              <Box>
                <Button
                  variant="contained"
                  style={approveButton}
                  sx={{ mb: 3 }}
                  onClick={() => handleApproval("approveApplication")}
                >
                  APPROVE APPLICATION{" "}
                </Button>

                <Button
                  variant="contained"
                  style={rejectButton}
                  onClick={() => handleApproval("rejectApplication")}
                >
                  REJECT APPLICATION
                </Button>
              </Box>
            )}

            {currentTask?.wfActiveProcess == "task_test_sdp_details" && (
              <Box>
                <Button
                  variant="contained"
                  style={approveButton}
                  onClick={() => handleCompleteTaskTestSdpApplication()}
                >
                  MARK ACCOUNT AS TESTED ON SDP
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="additional-applications-modal"
        aria-describedby="additional-applications-modal-description"
        open={additionalApplicationsSelection}
        onClose={handleCloseAdditionalApplicationsModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={additionalApplicationsSelection !== ""}>
          <Box style={modalStyle2} sx={{ p: 5 }}>
            {additionalApplicationsSelection === "vpnConfigurationDetails" && (
              <Typography
                id="additional-applications-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                VPN Configuration Details
              </Typography>
            )}

            {additionalApplicationsSelection === "vpnConfigurationDetails" && (
              <Box spacing={5}>
                <Grid container sx={{ pt: 2 }}>
                  <Grid item xs={6}>
                    <List>
                      {[
                        "Supplier",
                        "Type",
                        "Model",
                        "OS",
                        "Peer Address",
                        "Test Peer Address",
                        "Proposal Name",
                        "Authentication Mode",
                        "Pre-shared Key",
                        "Authentication Algorithm",
                        "Encryption Algorithm",
                        "Diffie-Hellman Group",
                        "Lifetime Measurement",
                        "Lifetime",
                        "Authentication Algorithm (ESP/SHA)",
                        "Encryption Algorithm (AES 128)",
                        "Encapsulation Mode",
                        "Perfect Forward Secrecy",
                        "Lifetime Measurement",
                        "Lifetime",
                        "Network",
                        "Comment",
                      ].map((value) => {
                        const listItemId = `${value}`;
                        return (
                          <ListItem sx={{ p: 1 }}>
                            <Typography
                              id={listItemId}
                              sx={{ fontWeight: "bold" }}
                            >
                              {`${value}`}
                            </Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <List>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.supplier
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {sdpDetails?.companyDetails?.vpnConfiguration?.type}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {sdpDetails?.companyDetails?.vpnConfiguration?.model}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {sdpDetails?.companyDetails?.vpnConfiguration?.os}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.peerAddress
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.testPeerAddress
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.proposalname
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.authenticationMode
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.preSharedKey
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.authenticationAlgorithm
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.encryptionAlgorithm
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.diffieHellmanGroup
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.lifetimeMeasurement
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.lifetime
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.authenticationAlgorithmEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.encryptionAlgorithmAes
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.encapsulationModeEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.perfectForwardSecrecy
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.lifetimeMeasurementEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.lifetimeEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.network
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            sdpDetails?.companyDetails?.vpnConfiguration
                              ?.comment
                          }
                        </Typography>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="approval-modal"
        aria-describedby="approval-modal-description"
        open={currentSelection}
        onClose={handleCloseApprovalModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={currentSelection !== ""}>
          <Box style={modalStyle} sx={{ p: 5 }}>
            {currentSelection === "approveApplication" && (
              <Typography
                id="approval-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                Approve SDP Application
              </Typography>
            )}

            {currentSelection === "rejectApplication" && (
              <Typography
                id="approval-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                Reject SDP Application
              </Typography>
            )}

            <Box spacing={5} sx={{ mt: 4 }}>
              <TextField
                label="Add Comment"
                name="comment"
                value={comment}
                onChange={handleInput}
                size="small"
                style={inputStyle}
                fullWidth
                multiline
                required
              />
            </Box>

            <Box>
              <Button
                style={isValidData ? primaryButton : disabledButton}
                onClick={() => handleTaskValidateSdpApplication()}
                disabled={!isValidData}
              >
                CONFIRM
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
