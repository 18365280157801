import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Snackbar,
  Stack,
  TextField,
} from "@mui/material";
import { disabledButton, primaryButton } from "../../common-styles";
import { formStyle2, inputStyle, textStyle } from "./user-management-styles";
import { useState } from "react";
import apiRequest from "../services/api-request";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import Password from "../services/password-validator";
import validator from "validator";
import * as Email from "email-validator";
import LoginAppBar from "./login-app-bar";
import LeftImage from "./left-image";

export default function Registration(props) {
  const { handleSignUpToggle } = props;

  const initialState = {
    firstName: "",
    firstNameError: false,
    firstNameHelperText: "",
    lastName: "",
    lastNameError: false,
    lastNameHelperText: "",
    email: "",
    emailError: false,
    emailHelperText: "",
    msisdn: "",
    msisdnError: false,
    msisdnHelperText: "",
    password: "",
    passwordError: false,
    passwordHelperText: "",
    addresses: [],
    roles: [],
    updatePassword: "",
    password2: "",
    password2Error: false,
    password2HelperText: "",
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,

    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
    loading: false,
  };

  const [state, setState] = useState(initialState);

  const [showPassword, setShowPassword] = useState(false);

  const {
    firstName,
    firstNameError,
    firstNameHelperText,
    lastName,
    lastNameError,
    lastNameHelperText,
    email,
    emailError,
    emailHelperText,
    msisdn,
    password,
    passwordError,
    passwordHelperText,
    addresses,
    roles,
    password2,
    password2Error,
    password2HelperText,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
    loading,
  } = state;

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const msisdnError = !msisdn || !validator.isMobilePhone(msisdn, ["en-KE"]);
  const msisdnHelperText = msisdnError
    ? "Required/Invalid/Kenyan mobile number only allowed"
    : "";

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    let { msisdnError, msisdnHelperText } = state;

    // console.logname, value);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      msisdnError,
      msisdnHelperText,
      firstNameError: name === "firstName" ? isEmpty(value) : firstNameError,
      firstNameHelperText:
        name === "firstName"
          ? isEmpty(value)
            ? "Required."
            : ""
          : firstNameHelperText,
      lastNameError: name === "lastName" ? isEmpty(value) : lastNameError,
      lastNameHelperText:
        name === "lastName"
          ? isEmpty(value)
            ? "Required."
            : ""
          : lastNameHelperText,
      emailError: name === "email" ? !Email.validate(value) : emailError,
      emailHelperText:
        name === "email"
          ? !Email.validate(value)
            ? "Invalid. Should contain company domain name"
            : ""
          : emailHelperText,
      passwordError:
        name === "password" ? !Password.validate(value) : passwordError,
      passwordHelperText:
        name === "password"
          ? !Password.validate(value)
            ? "Password should have a min of 8 characters, uppercase and lowercase, 1 symbol and 2 digits"
            : ""
          : passwordHelperText,
      password2Error: !(name === "password2" && password === value),
      password2HelperText: !(name === "password2" && password === value)
        ? "Invalid"
        : "",
    }));
  };

  const isEmpty = (data) => {
    let response = true;
    if (data) {
      response = false;
    }
    return response;
  };

  const checkPassword = Password.validate(password) && password === password2;

  const isValidData =
    checkPassword &&
    !isEmpty(firstName) &&
    !isEmpty(lastName) &&
    Email.validate(email) &&
    !msisdnError;

  const handleApiData = (params) => {
    // console.log{params});
    const { data, dataName, severity, message } = params;
    if (severity === `success`) {
      alert(
        `Successful, please check your ${email} inbox to complete the registration.`
      );
      window.location.reload();
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleRegistration = async () => {
    await makeApiRequest({
      uri: `/users`,
      dataName: `registeredUser`,
      method: `post`,
      data: { firstName, lastName, email, msisdn, password, addresses, roles },
    });
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <Box>
      <Backdrop open={loading}>
        <CircularProgress color="success" />{" "}
      </Backdrop>

      <LoginAppBar />

      {!loading && (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <LeftImage />
          </Grid>
          <Grid item xs={12} sm={6} style={formStyle2}>
            <Box>
              <Typography sx={{ fontSize: 23, fontWeight: "bold", mb: 2 }}>
                Welcome
              </Typography>
              <Typography sx={{ mb: 2 }}>
                Kindly provide the details below to create an account
              </Typography>
              <Typography sx={{ mb: 3, fontWeight: "bold", color: "#E62634" }}>
                Please ensure you use an email address containing your company
                domain name. Register company domain &nbsp;
                <Link
                  sx={{
                    color: "#E62634",
                    cursor: "pointer",
                    fontWeight: "bold",
                    ml: -1
                  }}
                  href="https://domains.safaricom.co.ke/cart.php?a=add&domain=register"
                  target="_blank"
                  rel="noopener"
                >
                  here
                </Link>
              </Typography>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <TextField
                  error={firstNameError}
                  helperText={firstNameHelperText}
                  label="First Name"
                  name="firstName"
                  size="small"
                  value={firstName}
                  style={inputStyle}
                  onChange={handleInput}
                  fullWidth
                  required
                  type="text"
                />
                <TextField
                  error={lastNameError}
                  helperText={lastNameHelperText}
                  label="Last Name"
                  name="lastName"
                  size="small"
                  value={lastName}
                  style={inputStyle}
                  onChange={handleInput}
                  fullWidth
                  required
                  type="text"
                />
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <TextField
                  error={msisdnError}
                  helperText={msisdnHelperText}
                  label="Phone Number"
                  name="msisdn"
                  size="small"
                  value={msisdn}
                  style={inputStyle}
                  onChange={handleInput}
                  fullWidth
                  required
                  type="tel"
                />
                <TextField
                  error={emailError}
                  helperText={emailHelperText}
                  autocomplete="new-email"
                  label="Email Address"
                  name="email"
                  value={email}
                  placeholder="e.g: example@business.com"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  fullWidth
                  required
                  type="text"
                />
              </Stack>
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={{ xs: 1, sm: 2, md: 4 }}
              >
                <TextField
                  error={passwordError}
                  helperText={passwordHelperText}
                  label="Password"
                  name="password"
                  autocomplete="new-password"
                  type={showPassword ? "text" : "password"}
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  value={password ?? ""}
                  fullWidth
                  required
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  error={password2Error}
                  helperText={password2HelperText}
                  label="Confirm Password"
                  name="password2"
                  type={showPassword ? "text" : "password"}
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  value={password2 ?? ""}
                  fullWidth
                  required
                  disabled={!Password.validate(password)}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Stack>
            </Box>
            <Box>
              <Button
                variant="contained"
                style={isValidData ? primaryButton : disabledButton}
                onClick={() => handleRegistration()}
                disableElevation
                disabled={!isValidData}
              >
                REGISTER
              </Button>
            </Box>
            {/*<Box sx={{mt: 4}}>*/}
            {/*    <FormControlLabel*/}
            {/*        style={keyContactStyle}*/}
            {/*        label="Are you Safaricom Staff?"*/}
            {/*        control={<Checkbox/>}*/}
            {/*    />*/}
            {/*</Box>*/}
            <Box>
              <Typography style={textStyle}>
                Already have an account?
                <span
                  style={{
                    color: "#159F4A",
                    textDecoration: "underline",
                    fontWeight: "bolder",
                  }}
                >
                  {" "}
                  <Link
                    sx={{ color: "#159F4A", cursor: "pointer" }}
                    onClick={() => handleSignUpToggle()}
                  >
                    Sign In
                  </Link>
                </span>
              </Typography>
            </Box>
            {/* <Box sx={{ mt: 4 }}>
              <Typography>
                <Link
                  sx={{
                    color: "#159F4A",
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontSize: 14,
                  }}
                  href="https://domains.safaricom.co.ke/cart.php?a=add&domain=register"
                  target="_blank"
                  rel="noopener"
                >
                  Click here to register company domain name in case you do not have one
                </Link>
              </Typography>
            </Box> */}
            <Box sx={{ mt: 3 }}>
              <Typography>
                <Link
                  sx={{
                    color: "#159F4A",
                    cursor: "pointer",
                    fontWeight: "bold",
                    fontSize: 14,
                  }}
                  href="https://www.safaricom.co.ke/dataprivacystatement/"
                  target="_blank"
                  rel="noopener"
                >
                  Click here to read the Safaricom Data Privacy Statement
                </Link>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
