export const logoStyle = {
    width: '198px',
    height: '74px'
}

export const appBarStyle = {
    backgroundColor: '#fff',
    marginTop: '1%'
}

export const assetStyle = {
    transform: "scale(0.65)",
    width: '100%'
}

export const formStyle = {
    // textAlign: 'left',
    paddingLeft: '10%',
    paddingRight: '10%',
    marginTop: '2%',
    marginBottom: '1%'
}

export const formStyle2 = {
    // textAlign: 'left',
    paddingLeft: '2%',
    paddingRight: '3%',
    marginTop: '2%',
    marginBottom: '2%'
}

export const inputStyle = {
    marginBottom: '5%',
    width: '100%',
}

export const textStyle = {
    fontSize: "15px",
    marginTop: '5%'
}