import React from "react";
import { withStyles } from "@material-ui/core/styles";
import customLocalStorage from "../services/local-storage";
import {
  Avatar,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Link
} from "@mui/material";
import { AccountCircle, ExitToAppTwoTone } from "@mui/icons-material";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getcontentanchorel={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.common.white,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: "#159F4A",
      },
    },
  },
}))(MenuItem);

export default function Profile(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = () => {
    localStorage.clear();
    window.location.reload();
  };

  const user = customLocalStorage.decrypt("cpoUser");
  // console.log{ user });

  return (
    <div>
      <IconButton
        size="medium"
        edge="end"
        aria-label="account of current user"
        aria-haspopup="true"
        color="inherit"
        onClick={handleClick}
      >
        <Avatar>
          {user?.firstName?.charAt(0)?.toUpperCase()}
          {user?.lastName?.charAt(0)?.toUpperCase()}
        </Avatar>
      </IconButton>

      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem>

          <ListItemIcon>
            <AccountCircle fontSize="small" />
          </ListItemIcon>

          <Link>
            <ListItemText primary={`${user?.firstName} ${user?.lastName} (${user?.user_name})`} />        
          </Link>

        </StyledMenuItem>

        <StyledMenuItem onClick={handleLogOut}>
          <ListItemIcon>
            <ExitToAppTwoTone fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Log out" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
