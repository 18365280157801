import React, { useEffect, useState } from "react";
import {
  Alert,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Divider,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  Snackbar,
  Stack,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Link,
} from "@mui/material";
import {
  inputStyle,
  keyContactStyle,
  formSubtitleStyle,
  statusSuccessStyle,
  statusFailStyle,
} from "../formStyles";
import { ArrowBack, Delete } from "@mui/icons-material";
import {
  disabledButton,
  primaryButton,
  secondaryButton,
} from "../../../../common-styles";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import apiRequest from "../../../services/api-request";

export default function SupplierCreationForm(props) {
  const { handleGoBack, applications, handleCompanyData } = props;
  const { companyId } = applications[0]?.company;

  const initialState = {
    currentSection: "A1",
    references: [],
    termsOfPayment: "",
    policyDoc: "",
    categoryOfService: [],
    categories: [],
    currentReferenceDetails: {},
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,
    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
    loading: false,
  };

  const [state, setState] = useState(initialState);

  // const [value, setValue] = useState<Date | null>(null);

  const {
    currentSection,
    references,
    currentReferenceDetails,
    termsOfPayment,
    categories,
    policyDoc,
    categoryOfService,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarHorizontalPosition,
    snackBarVerticalPosition,
    loading,
  } = state;

  const { referenceName } = currentReferenceDetails;

  const referenceNameError =
    !referenceName ||
    referenceName?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const referenceNameHelperText = referenceNameError ? "Required/Invalid" : "";

  const termsOfPaymentError =
    !termsOfPayment ||
    termsOfPayment?.length > process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
  const termsOfPaymentHelperText = termsOfPaymentError
    ? "Required/Invalid"
    : "";

  const isValidReferenceDetails = () => {
    let isValid = false;

    for (let index = 0; index < references.length; index++) {
      const item = references[index];

      const referenceNameError =
        !item?.referenceName ||
        item?.referenceName.length >
          process.env.REACT_APP_MAX_INPUT_LENGTH_FIFTY;
      const referenceNameHelperText = referenceNameError
        ? "Required/Invalid"
        : "";

      isValid = !referenceNameError;

      if (!isValid) {
        break;
      }
    }
    return isValid;
  };

  const removeDuplicateReferencesromArrayOfObjects = (objArray) => {
    if (objArray?.length < 1) return [];

    let uniqueObjArray = [
      ...new Map(
        objArray.map((item) => [item["referenceName"], item])
      ).values(),
    ];

    if (uniqueObjArray?.length < 1) return [];

    uniqueObjArray = [
      ...new Map(
        uniqueObjArray.map((item) => [item["referenceName"], item])
      ).values(),
    ];

    return uniqueObjArray;
  };

  useEffect(() => {
    (async () => {
      await handleGetProductCategories();

      if (applications[0]?.company) {
        const { termsOfPayment, references, categoryOfService, policyDoc } =
          applications[0]?.company;
        setState((prevState) => ({
          ...prevState,
          termsOfPayment,
          references,
          categoryOfService,
          policyDoc,
        }));
      }
    })();
  }, [applications]);

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.logname, value);
    let { currentReferenceDetails } = state;

    if (currentSection === "A3") {
      currentReferenceDetails = { ...currentReferenceDetails, [name]: value };
      setState((prevState) => ({
        ...prevState,
        currentReferenceDetails,
      }));
    }

    setState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const isEmpty = (data) => {
    let response = true;
    if (data) {
      response = false;
    }
    return response;
  };

  const isValidData =
    isValidReferenceDetails() && !termsOfPaymentError;

  const handleAddSupplierReferences = () => {
    let { references, currentReferenceDetails } = state;

    if (currentReferenceDetails?.referenceId) {
      const indexOfReference = references.findIndex((reference) => {
        return reference?.referenceId == currentReferenceDetails?.referenceId;
      });

      references[indexOfReference] = currentReferenceDetails;
    } else {
      references = [{ ...currentReferenceDetails }, ...references];
    }
    references = removeDuplicateReferencesromArrayOfObjects(references);
    // console.log({ references });
    setState((prevState) => ({
      ...prevState,
      references,
      currentReferenceDetails: {},
    }));
  };

  const handleApiData = async (params) => {
    // console.log{params});
    const { data, dataName, severity } = params;
    if (severity === "success" && dataName === `updatedCompany`) {
      await handleCompanyData();
      if (isValidData) {
        handleGoBack();
      }
    }
    if (dataName === "deletedReference") {
      handleCompanyData();
    }

    setState((prevState) => ({
      ...prevState,
      [dataName]: data,
      policyDoc: data?.docInfo?.sysDocid,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleFileUpload = async (params) => {
    const { event, docType } = params;
    if (event?.target?.files) {
      const file = event.target.files[0];
      const fileExtension = file.type;
      const allowedFiles = process.env.REACT_APP_ALLOWED_UPLOAD_FILE_TYPES;

      // console.log({ fileExtension });
      if (
        fileExtension &&
        allowedFiles
          .toLocaleLowerCase()
          .includes(fileExtension.toLocaleLowerCase())
      ) {
        const data = new FormData();
        data.append("file", file);
        data.append("companyId", companyId);
        data.append("docNo", "0");
        data.append("docType", docType);
        data.append("module", "companyDocs");
        await makeApiRequest({
          uri: `/upload`,
          dataName: docType,
          method: `post`,
          data,
        });
      } else {
        alert(`${fileExtension} Not allowed. You can only upload a .pdf file `);
        return;
      }
    }
  };

  const handleCompany = async () => {
    await makeApiRequest({
      uri: `/kyc/company/${companyId}`,
      dataName: `updatedCompany`,
      method: `put`,
      data: {
        ...applications[0]?.company,
        references,
        termsOfPayment,
        categoryOfService,
        policyDoc,
        supplierCreationFormStatus: isValidData ? "Complete" : "In Progress",
      },
    });
  };

  const handleGetProductCategories = async () => {
    const pageNumber = 1;
    const limit = 10;
    await makeApiRequest({
      uri: `/application/categories?limit=${limit}&page=${pageNumber}`,
      dataName: `categories`,
      method: `get`,
      data: "",
    });
  };

  const handleDeleteSupplierReferene = async (referenceId) => {
    await makeApiRequest({
      uri: `/kyc/company/${companyId}/references/${referenceId}`,
      dataName: `deletedReference`,
      method: `delete`,
      data: {},
    });
  };

  const handleAccordionChange = (currentSection) => {
    setState((prevState) => ({
      ...prevState,
      currentSection,
    }));
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <Box sx={{ mt: 10 }} textAlign="left">
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      <Stack direction="row" spacing={1}>
        <IconButton onClick={() => handleGoBack()} sx={{ mb: 4, mt: -0.5 }}>
          <ArrowBack />
        </IconButton>
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Supplier Request Form
        </Typography>
      </Stack>

      <Accordion
        expanded={currentSection === "A1"}
        onChange={() => handleAccordionChange("A1")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography textAlign="left" style={formSubtitleStyle}>
            1: Select Category of Product/Service:
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" spacing={5} textAlign="left">
            <FormGroup>
              {categories?.map((category, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    style={keyContactStyle}
                    control={
                      <Checkbox
                        key={category?.categoryShtCode}
                        checked={
                          categoryOfService.filter(
                            (currentCategory) =>
                              currentCategory?.categoryShtCode ==
                              category?.categoryShtCode
                          )?.length > 0
                        }
                        onChange={() => {
                          let { categoryOfService } = state;

                          const indexOfCategory = categoryOfService.findIndex(
                            (currentCategory) =>
                              currentCategory?.categoryShtCode ==
                              category?.categoryShtCode
                          );

                          // console.log({ indexOfCategory });

                          if (indexOfCategory == -1) {
                            categoryOfService = [
                              ...categoryOfService,
                              {
                                categoryShtCode: category?.categoryShtCode,
                                categoryName: category?.categoryName,
                              },
                            ];
                          } else {
                            categoryOfService.splice(indexOfCategory, 1);
                          }
                          setState((prevState) => ({
                            ...prevState,
                            categoryOfService,
                          }));
                        }}
                      />
                    }
                    label={category?.categoryName}
                  />
                );
              })}
            </FormGroup>

            {/* <FormControl
          fullWidth
          error={isEmpty(categoryOfService?.categoryShtCode)}
        >
          <Autocomplete
            options={categories}
            value={categoryOfService}
            getOptionLabel={(option) => option.categoryName}
            getOptionSelected={(option, value) =>
              option.categoryShtCode === value.categoryShtCode
            }
            // style={{ width: 200 }}
            onChange={(event, newValue) => {
              if (newValue) {
                const categoryOfService = newValue;
                setState((prevState) => ({
                  ...prevState,
                  categoryOfService,
                }));
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Category of Product/Service"
                variant="outlined"
              />
            )}
          />
          {isEmpty(categoryOfService?.categoryShtCode) && (
            <FormHelperText>Required.</FormHelperText>
          )}
        </FormControl> */}
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={currentSection === "A2"}
        onChange={() => handleAccordionChange("A2")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography textAlign="left" style={formSubtitleStyle}>
            2: Terms of Payment
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" spacing={5} textAlign="left" sx={{ mt: 5 }}>
            <TextField
              rror={termsOfPaymentError}
              helperText={termsOfPaymentHelperText}
              label="Terms of Payment"
              name="termsOfPayment"
              size="small"
              placeholder="e.g. EFT, RTGS"
              style={inputStyle}
              onChange={handleInput}
              value={termsOfPayment}
              fullWidth
            />
          </Stack>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={currentSection === "A3"}
        onChange={() => handleAccordionChange("A3")}
        sx={{ mt: 3, mb: 4 }}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography textAlign="left" style={formSubtitleStyle}>
            3: Supplier References
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Stack direction="row" spacing={5} textAlign="left">
            <TextField
              error={referenceNameError}
              helperText={referenceNameHelperText}
              label="Supplier Reference"
              name="referenceName"
              size="small"
              style={inputStyle}
              onChange={handleInput}
              value={currentReferenceDetails?.referenceName ?? ""}
              fullWidth
            />
            <Box>
              <Button
                style={referenceNameError ? disabledButton : primaryButton}
                disabled={referenceNameError}
                onClick={() => handleAddSupplierReferences()}
              >
                {currentReferenceDetails?.referenceId ? "UPDATE" : "ADD"}
              </Button>
            </Box>
          </Stack>
          <Box sx={{ backgroundColor: "#f9f9f9", p: 2, mb: 5 }}>
            <Typography textAlign="left" sx={{ textDecoration: "underline" }}>
              Supplier References
            </Typography>
            <List sx={{ pt: 2 }}>
              {references?.map((reference) => (
                <Box>
                  <Stack direction="row">
                    <ListItem>
                      <ListItemText primary={reference?.referenceName} />
                    </ListItem>
                    <IconButton
                      aria-label="delete"
                      size="large"
                      onClick={() => {
                        handleDeleteSupplierReferene(reference?.referenceId);

                        const { references } = state;
                        const indexOfReference = references.findIndex(
                          (currentReference) => {
                            return (
                              currentReference?.referenceId ==
                              reference?.referenceId
                            );
                          }
                        );

                        references.splice(indexOfReference, 1);

                        setState((prevState) => ({
                          ...prevState,
                          references,
                        }));
                      }}
                    >
                      <Delete />
                    </IconButton>
                  </Stack>
                  <Divider />
                </Box>
              ))}
            </List>
          </Box>
        </AccordionDetails>
      </Accordion>

      <Accordion
        expanded={currentSection === "A4"}
        onChange={() => handleAccordionChange("A4")}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography textAlign="left" style={formSubtitleStyle}>
            {/* 4: Internet Usage & Email Policy and Supplier Code of Conduct */}
            4: Supplier Code of Conduct
          </Typography>
        </AccordionSummary>

        <AccordionDetails>
          <TableContainer sx={{ pl: 1, mt: -4 }}>
            {/* <Table sx={{ borderBottom: "0.5px solid #121212" }}> */}
            <Table>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Supplier Code of Conduct
                  </TableCell>
                  {/* <TableCell>PDF</TableCell> */}

                  <TableCell align="right">
                    <Link
                      href={`${process.env.REACT_APP_HOMEPAGE}/files/the_safaricom_supplier_code_of_conduct.pdf`}
                      target="_blank"
                      download
                      sx={{ cursor: "pointer" }}
                    >
                      <Button style={primaryButton} sx={{ mr: 2 }}>
                        DOWNLOAD SUPPLIER CODE OF CONDUCT
                      </Button>
                    </Link>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TableContainer sx={{ mb: 4, pl: 1, pr: 2 }}>
            <Table>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    Internet Usage and Email Policy Form
                  </TableCell>
                  <TableCell>PDF</TableCell>
                  <TableCell>
                    <Typography
                      style={policyDoc ? statusSuccessStyle : statusFailStyle}
                    >
                      {policyDoc ? "Uploaded successfully" : "Not uploaded"}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Link
                      href={`${process.env.REACT_APP_HOMEPAGE}/files/BusinessPlanTemplate.docx`}
                      target="_blank"
                      download
                      sx={{ cursor: "pointer" }}
                    >
                      <Button style={primaryButton} sx={{ mr: 2 }}>
                        DOWNLOAD FORM
                      </Button>
                    </Link>
                    <label htmlFor="policyDoc">
                      <input
                        type="file"
                        id="policyDoc"
                        accept="application/pdf"
                        style={{ display: "none" }}
                        onChange={(event) =>
                          handleFileUpload({
                            event,
                            docType: `policyDoc`,
                          })
                        }
                      />
                      <Button
                        style={secondaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                      >
                        UPLOAD
                      </Button>
                    </label>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer> */}
        </AccordionDetails>
      </Accordion>

      <Box sx={{ mt: 3 }}>
        <Button
          variant="contained"
          style={primaryButton}
          onClick={() => handleCompany()}
          disableElevation
        >
          {isValidData ? "Save & Go Back" : "Save"}
        </Button>
      </Box>

      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
