import {
  Alert,
  Box,
  Button,
  Autocomplete,
  FormControl,
  Grid,
  Snackbar,
  List,
  Container,
  ListItem,
  TextField,
  Typography,
  IconButton,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  Backdrop,
  CircularProgress,
  Modal,
  Fade
} from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import apiRequest from "../services/api-request";
import { inputStyle } from "../partner/application-form/formStyles";
import {
  disabledButton,
  primaryButton,
  secondaryButton,
  modalStyle2
} from "../../common-styles";
import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from "../navigation/theme";
import { ThemeProvider } from "@mui/styles";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";

const sectionHeadingStyle = {
  fontWeight: "bold",
  color: "#159F4A",
  fontSize: "1.2em",
};

const tableHeaderStyle = {
  fontWeight: "bold",
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function AddTaskAssignee(props) {
  const { currentAssignedTask, action, toggleTaskPage } = props;

  // console.log({currentAssignedTask})


  const classes = useStyles();

  let theme = createTheme();

  theme = responsiveFontSizes(theme);

  const { wfId } = currentAssignedTask;

  const { companyId } = currentAssignedTask?.wfCurrTrans;

  const initialState = {
    eligibleUsers: [],
    selectedUser: {},
    commentError: false,
    commentHelperText: "",
    loading: false,
    additionalApplicationsSelection: "",
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,

    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
  };

  const [state, setState] = useState(initialState);

  const [comment, setComment] = useState("");
  const [userId, setUserId] = useState("");

  const {
    eligibleUsers,
    selectedUser,
    commentError,
    additionalApplicationsSelection,
    commentHelperText,
    loading,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarVerticalPosition,
    snackBarHorizontalPosition,
  } = state;

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setComment(value);

    setState((prevState) => ({
      ...prevState,
      commentError: name === "comment" ? isEmpty(value) : commentError,
      commentHelperText:
        name === "comment"
          ? isEmpty(value)
            ? "Required."
            : ""
          : commentHelperText,
    }));
  };

  const isEmpty = (data) => {
    let response = true;
    if (data) {
      response = false;
    }
    return response;
  };

  const isValidData = !isEmpty(comment);

  const handleApiData = (params) => {
    // console.log{params});
    const { severity, message, dataName, data, headers } = params;

    if (severity === "success") {
      const contentType = headers["content-type"];
      // console.log({ contentType });

      if (dataName === "fetchedFile") {
        const file = new Blob([data], { type: contentType });

        const fileURL = URL.createObjectURL(file);

        const pdfWindow = window.open();

        pdfWindow.location.href = fileURL;
      }

      if (dataName === "reportData") {
        // console.log({ data });
        const file = new Blob([data], { type: "application/pdf" });
        // console.log({ file });

        const fileURL = URL.createObjectURL(file);

        const pdfWindow = window.open();

        pdfWindow.location.href = fileURL;
      }
      if (dataName === "eligibleUsers") {

        setState((prevState) => ({
          ...prevState,
          [dataName]: data?.filter((usr) => usr.userId !== currentAssignedTask?.wfUser?.userId),
        }));
        console.log({eligibleUsers})
      }

      if (dataName === "assignedUser") {
        window.location.reload();
      }
    }
    setState((prevState) => ({
      ...prevState,
      // [dataName]: data,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  useEffect(() => {
    makeApiRequest({
      uri: `/tickets/eligibleusers/${wfId}`,
      dataName: `eligibleUsers`,
      method: `get`,
      data: "",
    });
  }, []);

  const handleGetFiles = async (fileId) => {
    await makeApiRequest({
      uri: `/files/file?fileId=${fileId}`,
      dataName: `fetchedFile`,
      method: `get`,
      data: {},
    });
  };

  const handleGetInvoice = async () => {
    await makeApiRequest({
      uri: `/reports/invoice/${companyId}`,
      dataName: `reportData`,
      method: `get`,
      data: {},
    });
  };

  const handleGetVpnFormReport = async () => {
    await makeApiRequest({
      uri: `/reports/vpnform/${companyId}`,
      dataName: `reportData`,
      method: `get`,
      data: {},
    });
  };

  const handleAdditionalApplication = (additionalApplicationsSelection) => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      additionalApplicationsSelection,
    }));
  };

  const handleCloseAdditionalApplicationsModal = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      additionalApplicationsSelection: "",
    }));
  };

  const handleSubmitAssignee = async () => {
    // console.log({ userId });
    // console.log({ comment });
    // console.log({ companyId });

    await makeApiRequest({
      uri: `/tickets/reaassigneeTicket`,
      // uri: `/tickets/reaassigneeTicket`,
      dataName: `assignedUser`,
      method: `post`,
      data: {
        comment,
        companyId,
        userId,
      },
    });
    // window.location.reload();
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
          <CircularProgress color="success" />
        </Backdrop>
        <Box
          sx={{
            flexGrow: 1,
            pb: 5,
            pt: 4,
            pr: 3,
            pl: 3,
            backgroundColor: "#f9f9f9",
          }}
        >
          <Grid container direction="row" alignItems="center">
            <IconButton
              onClick={() =>
                toggleTaskPage({
                  currentPage: "pendingTaskList",
                  currentAssignedTask,
                })
              }
            >
              <ArrowBack />
            </IconButton>

            <Typography
              variant="h5"
              textAlign="left"
              sx={{ fontWeight: "bold" }}
            >
              CSP Application #{currentAssignedTask?.wfCurrTrans?.companyId}
            </Typography>
          </Grid>

          <Grid container>
            <Grid item xs={9}>
              <Container sx={{ pt: 3 }}>
                <Typography textAlign="left" style={sectionHeadingStyle}>
                  Task Details
                </Typography>
                <Container>
                  <Grid container sx={{ pt: 3, ml: -4 }}>
                    <Grid item xs={6}>
                      <List>
                        {[
                          "Task Id",
                          "Current Task Name",
                          "Current Active Stage",
                          "Date Created",
                        ].map((value) => {
                          const listItemId = `${value}`;
                          return (
                            <ListItem sx={{ p: 1 }}>
                              <Typography
                                id={listItemId}
                                sx={{ fontWeight: "bold" }}
                              >
                                {`${value}`}
                              </Typography>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                    <Grid item xs={6}>
                      <List>
                        <ListItem sx={{}}>
                          <Typography>{currentAssignedTask?.wfId}</Typography>
                        </ListItem>
                        <ListItem>
                          <Typography>
                            {currentAssignedTask?.wfTaskName}
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography>
                            {currentAssignedTask?.wfActiveProcess}
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography>
                            {new Date(
                              currentAssignedTask?.wfCreatedDate
                            ).toLocaleDateString()}
                            {/* {currentAssignedTask?.wfCurrTrans?.companyMobileNumber} */}
                          </Typography>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Container>
              </Container>
              <Container sx={{ pt: 3 }}>
                <Typography textAlign="left" style={sectionHeadingStyle}>
                  Company Information
                </Typography>
                <Container>
                  <Grid container sx={{ pt: 3, ml: -4 }}>
                    <Grid item xs={6}>
                      <List>
                        {[
                          "Registered Company",
                          "Company Email Address",
                          "Company Website",
                          "Company Mobile Number",
                          "Physical Address",
                        ].map((value) => {
                          const listItemId = `${value}`;
                          return (
                            <ListItem sx={{ p: 1 }}>
                              <Typography
                                id={listItemId}
                                sx={{ fontWeight: "bold" }}
                              >
                                {`${value}`}
                              </Typography>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                    <Grid item xs={6}>
                      <List>
                        <ListItem sx={{}}>
                          <Typography>
                            {
                              currentAssignedTask?.wfCurrTrans
                                ?.registeredCompanyName
                            }
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography>
                            {
                              currentAssignedTask?.wfCurrTrans
                                ?.companyEmailAddress
                            }
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography>
                            {
                              currentAssignedTask?.wfCurrTrans
                                ?.companyWebsiteUrl
                            }
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography>
                            {
                              currentAssignedTask?.wfCurrTrans
                                ?.companyMobileNumber
                            }
                          </Typography>
                        </ListItem>
                        <ListItem>
                          <Typography>
                            {currentAssignedTask?.wfCurrTrans?.physicalLocation}
                          </Typography>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Container>
              </Container>
              <Container sx={{ pt: 4 }}>
                <Typography textAlign="left" style={sectionHeadingStyle}>
                  Key Contacts
                </Typography>
                <TableContainer sx={{ ml: -2, mt: 3 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Full Name
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Email Address
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Mobile Number
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentAssignedTask?.wfCurrTrans?.keyContacts.map(
                        (row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell
                              component="th"
                              scope="row"
                              sx={{ textDecoration: "underline" }}
                            >
                              {row.type}
                            </TableCell>
                            <TableCell>{row.name}</TableCell>
                            <TableCell>{row.email}</TableCell>
                            <TableCell>{row.mobileNumber}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                {/*<TableContainer sx={{ml: -2}}>*/}
                {/*    <Table sx={{minWidth: 650}} aria-label="simple table">*/}
                {/*        <TableHead>*/}
                {/*            <TableRow>*/}
                {/*                <TableCell></TableCell>*/}
                {/*                <TableCell style={tableHeaderStyle}>CEO/Business Lead</TableCell>*/}
                {/*                <TableCell style={tableHeaderStyle}>CFO/Finance Lead</TableCell>*/}
                {/*                <TableCell style={tableHeaderStyle}>Technical/IT Lead</TableCell>*/}
                {/*                <TableCell style={tableHeaderStyle}>Admin/Key Contact</TableCell>*/}
                {/*            </TableRow>*/}
                {/*        </TableHead>*/}
                {/*        <TableBody>*/}
                {/*            <TableRow>*/}
                {/*                <TableCell style={tableHeaderStyle}>Name</TableCell>*/}
                {/*                <TableCell>{applicationData.keyContacts.businessLead.name}</TableCell>*/}
                {/*                <TableCell>{applicationData.keyContacts.financeLead.name}</TableCell>*/}
                {/*                <TableCell>{applicationData.keyContacts.itLead.name}</TableCell>*/}
                {/*                <TableCell>{applicationData.keyContacts.admin.name}</TableCell>*/}
                {/*            </TableRow>*/}
                {/*            <TableRow>*/}
                {/*                <TableCell style={tableHeaderStyle}>Email</TableCell>*/}
                {/*                <TableCell*/}
                {/*                    style={tableCellStyle}>*/}
                {/*                    {applicationData.keyContacts.businessLead.emailAddress}</TableCell>*/}
                {/*                <TableCell*/}
                {/*                    style={tableCellStyle}>*/}
                {/*                    {applicationData.keyContacts.financeLead.emailAddress}</TableCell>*/}
                {/*                <TableCell*/}
                {/*                    style={tableCellStyle}>*/}
                {/*                    {applicationData.keyContacts.itLead.emailAddress}</TableCell>*/}
                {/*                <TableCell*/}
                {/*                    style={tableCellStyle}>*/}
                {/*                    {applicationData.keyContacts.admin.emailAddress}</TableCell>*/}
                {/*            </TableRow>*/}
                {/*            <TableRow>*/}
                {/*                <TableCell style={tableHeaderStyle}>Phone Number</TableCell>*/}
                {/*                <TableCell>*/}
                {/*                    {applicationData.keyContacts.businessLead.mobilePhoneNumber}</TableCell>*/}
                {/*                <TableCell>*/}
                {/*                    {applicationData.keyContacts.financeLead.mobilePhoneNumber}</TableCell>*/}
                {/*                <TableCell>*/}
                {/*                    {applicationData.keyContacts.itLead.mobilePhoneNumber}</TableCell>*/}
                {/*                <TableCell>*/}
                {/*                    {applicationData.keyContacts.admin.mobilePhoneNumber}</TableCell>*/}
                {/*            </TableRow>*/}

                {/*        </TableBody>*/}
                {/*    </Table>*/}
                {/*</TableContainer>*/}
              </Container>

              <Container sx={{ pt: 4 }}>
                <Typography textAlign="left" style={sectionHeadingStyle}>
                  Documents
                </Typography>
                <TableContainer sx={{ ml: -2 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={tableHeaderStyle}>File</TableCell>
                        <TableCell style={tableHeaderStyle}>Type</TableCell>
                        <TableCell style={tableHeaderStyle}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Certificate of Incorporation
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell align="right">
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() =>
                              handleGetFiles(
                                currentAssignedTask?.wfCurrTrans
                                  ?.certOfIncorpDoc
                              )
                            }
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          KRA Pin Certificate
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell align="right">
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() =>
                              handleGetFiles(
                                currentAssignedTask?.wfCurrTrans?.companyPinDoc
                              )
                            }
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          VAT Certificate
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell align="right">
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() =>
                              handleGetFiles(
                                currentAssignedTask?.wfCurrTrans?.companyVatDoc
                              )
                            }
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Company CR12
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell align="right">
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() =>
                              handleGetFiles(
                                currentAssignedTask?.wfCurrTrans?.cr12Doc
                              )
                            }
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          CAK License
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell align="right">
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() =>
                              handleGetFiles(
                                currentAssignedTask?.wfCurrTrans?.cakLicenseDoc
                              )
                            }
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Copy of Bank A/C Cheque Leaf
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell align="right">
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() =>
                              handleGetFiles(
                                currentAssignedTask?.wfCurrTrans?.chequeLeafDoc
                              )
                            }
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Business Proposal Document
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell align="right">
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() =>
                              handleGetFiles(
                                currentAssignedTask?.wfCurrTrans
                                  ?.businessProposalDoc
                              )
                            }
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Payment Receipt
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell align="right">
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() =>
                              handleGetFiles(
                                currentAssignedTask?.wfCurrTrans?.receiptDoc
                              )
                            }
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Signed Contract
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell align="right">
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() =>
                              handleGetFiles(
                                currentAssignedTask?.wfCurrTrans?.contractDoc
                              )
                            }
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Minimum Baseline Security Requirements
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell align="right">
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() =>
                              handleGetFiles(
                                currentAssignedTask?.wfCurrTrans?.securityDoc
                              )
                            }
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Pro forma Invoice
                        </TableCell>
                        <TableCell>PDF</TableCell>
                        <TableCell align="right">
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() => handleGetInvoice()}
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>

              <Container sx={{ pt: 4 }}>
                <Typography textAlign="left" style={sectionHeadingStyle}>
                  Director Details
                </Typography>
                <TableContainer sx={{ ml: -2, mt: 3 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Full Name
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          ID Number
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          KRA Pin
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          ID/Passport Copy
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          KRA Pin Certificate Copy
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentAssignedTask?.wfCurrTrans?.directorDetails.map(
                        (row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell>{row.fullName}</TableCell>
                            <TableCell>{row.kitambulisho}</TableCell>
                            <TableCell>{row.kraPin}</TableCell>
                            <TableCell align="left">
                              <Button
                                style={secondaryButton}
                                variant="contained"
                                component="span"
                                disableElevation
                                onClick={() => handleGetFiles(row.identityDoc)}
                              >
                                VIEW
                              </Button>
                            </TableCell>
                            <TableCell align="left">
                              <Button
                                style={secondaryButton}
                                variant="contained"
                                component="span"
                                disableElevation
                                onClick={() => handleGetFiles(row.pinDoc)}
                              >
                                VIEW
                              </Button>
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>

              <Container sx={{ pt: 4 }}>
                <Typography textAlign="left" style={sectionHeadingStyle}>
                  Signatory Details
                </Typography>
                <TableContainer sx={{ ml: -2, mt: 3 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Full Name
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Email Address
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          ID Number
                        </TableCell>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Phone Number
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentAssignedTask?.wfCurrTrans?.signatories.map(
                        (row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell>{row.signatoryName}</TableCell>
                            <TableCell>{row.signatoryEmail}</TableCell>
                            <TableCell>{row.signatoryIdNumber}</TableCell>
                            <TableCell>{row.signatoryPhone}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>

              <Container sx={{ pt: 4 }}>
                <Typography textAlign="left" style={sectionHeadingStyle}>
                  Other Applications
                </Typography>
                <TableContainer sx={{ ml: -2 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={tableHeaderStyle}>
                          Application
                        </TableCell>
                        <TableCell style={tableHeaderStyle}>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Supplier Creation Details
                        </TableCell>
                        <TableCell>
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() =>
                              handleAdditionalApplication(
                                "supplierCreationDetails"
                              )
                            }
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          Supplier Bank Details
                        </TableCell>
                        <TableCell>
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() =>
                              handleAdditionalApplication("supplierBankDetails")
                            }
                          >
                            VIEW
                          </Button>
                        </TableCell>
                      </TableRow>
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          VPN Configuration Details
                        </TableCell>
                        <TableCell>
                          <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() =>
                              handleAdditionalApplication(
                                "vpnConfigurationDetails"
                              )
                            }
                          >
                            VIEW
                          </Button>
                          <Button
                            sx={{ ml: 5 }}
                            style={primaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() => handleGetVpnFormReport()}
                          >
                            VIEW VPN DOCUMENT
                          </Button>
                          {/* <Button
                            style={secondaryButton}
                            variant="contained"
                            component="span"
                            disableElevation
                            onClick={() => handleGetVpnFormReport()}
                          >
                            VIEW
                          </Button> */}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Container>

              <Container sx={{ pt: 4 }}>
                <Typography textAlign="left" style={sectionHeadingStyle}>
                  Business Value Proposition
                </Typography>
                <TableContainer sx={{ ml: -2 }}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell style={tableHeaderStyle}>
                          Business Area
                        </TableCell>
                        <TableCell style={tableHeaderStyle}>
                          Financial Projections
                        </TableCell>
                        <TableCell style={tableHeaderStyle}>
                          Marketing Strategy
                        </TableCell>
                        <TableCell style={tableHeaderStyle}>
                          Target Market
                        </TableCell>
                        <TableCell style={tableHeaderStyle}>
                          Consumer Insight
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {currentAssignedTask?.wfCurrTrans?.businessValueProposition.map(
                        (row, index) => (
                          <TableRow
                            key={index}
                            sx={{
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <TableCell>{row.businessArea}</TableCell>
                            <TableCell>
                              {row.financialProjections?.year1},{" "}
                              {row.financialProjections?.year2},{" "}
                              {row.financialProjections?.year3}
                            </TableCell>
                            <TableCell>{row.marketingStrategies}</TableCell>
                            <TableCell>{row.targetMarket}</TableCell>
                            <TableCell>{row.consumerInsight}</TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Typography textAlign="left" sx={{ fontWeight: "bold", mt: 3 }}>
                  Business Value Propostion Assumptions
                </Typography>
                <Typography textAlign="left">
                  {
                    currentAssignedTask?.wfCurrTrans
                      ?.valuePropositionAssumptions
                  }
                </Typography>
              </Container>
            </Grid>

            <Modal
        aria-labelledby="additional-applications-modal"
        aria-describedby="additional-applications-modal-description"
        open={additionalApplicationsSelection}
        onClose={handleCloseAdditionalApplicationsModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={additionalApplicationsSelection !== ""}>
          <Box style={modalStyle2} sx={{ p: 5 }}>
            {additionalApplicationsSelection === "supplierCreationDetails" && (
              <Typography
                id="additional-applications-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                Supplier Creation Details
              </Typography>
            )}
            {additionalApplicationsSelection === "supplierBankDetails" && (
              <Typography
                id="additional-applications-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                Supplier Bank Details
              </Typography>
            )}

            {!(
              currentAssignedTask?.wfActiveProcess == "vas_countercheck_task" ||
              currentAssignedTask?.wfActiveProcess == "task_validate_uploaded_receipt"
            ) &&
              additionalApplicationsSelection === "vpnConfigurationDetails" && (
                <Typography
                  id="additional-applications-modal"
                  variant="h6"
                  component="h2"
                  sx={{ color: "#159F4A", fontWeight: "bold" }}
                >
                  VPN Configuration Details
                </Typography>
              )}

            {additionalApplicationsSelection === "supplierCreationDetails" && (
              <Box spacing={4}>
                <TableContainer sx={{ ml: -2, mt: 3 }}>
                  <Table sx={{ width: 600 }} aria-label="simple table">
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Category of Products/Service
                      </TableCell>
                      <TableCell>
                        {currentAssignedTask?.wfCurrTrans?.categoryOfService.map(
                          (row, index) => (
                            <Typography
                              key={index}
                              sx={{
                                pb: 1,
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              - {row?.categoryName}
                            </Typography>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Terms of Payment
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentAssignedTask?.wfCurrTrans?.termsOfPayment}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Supplier References
                      </TableCell>
                      <TableCell>
                        {currentAssignedTask?.wfCurrTrans?.references.map(
                          (row, index) => (
                            <Typography
                              key={index}
                              sx={{
                                pb: 1,
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              - {row?.referenceName}
                            </Typography>
                          )
                        )}
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>

                {/* <Grid container sx={{ pt: 2 }}>
                  <Grid item xs={6}>
                    <List>
                      {[
                        "Category of Products/Service",
                        "Terms of Payment",
                        "Supplier References",
                      ].map((value) => {
                        const listItemId = `${value}`;
                        return (
                          <ListItem sx={{ p: 1 }}>
                            <Typography
                              id={listItemId}
                              sx={{ fontWeight: "bold" }}
                            >
                              {`${value}`}
                            </Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <List>
                      <ListItem>
                        <Typography>
                          {/* {
                            currentAssignedTask?.wfCurrTrans?.categoryOfService[0]
                              ?.categoryName
                          } */}
                {/* {currentAssignedTask?.wfCurrTrans?.categoryOfService.map(
                            (item, index) => (
                              <React.Fragment key={index}>
                                {item.categoryName}, &nbsp;
                              </React.Fragment>
                            )
                          )}
                        </Typography>
                      </ListItem> */}
                {/* <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.transactionCurrency
                              ?.currencyName
                          }
                        </Typography>
                      </ListItem> */}
                {/* <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.termsOfPayment}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.references.map(
                            (item, index) => (
                              <React.Fragment key={index}>
                                {item.referenceName},&nbsp;
                              </React.Fragment>
                            )
                          )}
                        </Typography>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid> */}
              </Box>
            )}

            {additionalApplicationsSelection === "supplierBankDetails" && (
              <Box spacing={5}>
                <TableContainer sx={{ ml: -2, mt: 3 }}>
                  <Table sx={{ width: 500 }} aria-label="simple table">
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Transaction Currency
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentAssignedTask?.wfCurrTrans?.bankDetails
                            ?.transactionCurrency?.currencyName
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Bank Name
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentAssignedTask?.wfCurrTrans?.bankDetails?.bankName}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Bank Branch
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentAssignedTask?.wfCurrTrans?.bankDetails?.bankBranch}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Account Name
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentAssignedTask?.wfCurrTrans?.bankDetails?.accountName}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Bank Account Number
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentAssignedTask?.wfCurrTrans?.bankDetails?.accountNo}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Bank Routing Code
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentAssignedTask?.wfCurrTrans?.bankDetails?.bankRoutingCode}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Bank Routing Method
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentAssignedTask?.wfCurrTrans?.bankDetails
                            ?.bankRoutingMethod
                        }
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Bank Swift Code
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentAssignedTask?.wfCurrTrans?.bankDetails?.swiftCode}
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>

                {/* <Grid container sx={{ pt: 2 }}>
                  <Grid item xs={6}>
                    <List>
                      {[
                        "Bank Name",
                        "Bank Branch",
                        "Account Name",
                        "Bank Account Number",
                        "Bank Routing Code",
                        "Bank Routing Method",
                        "Bank Swift Code",
                      ].map((value) => {
                        const listItemId = `${value}`;
                        return (
                          <ListItem sx={{ p: 1 }}>
                            <Typography
                              id={listItemId}
                              sx={{ fontWeight: "bold" }}
                            >
                              {`${value}`}
                            </Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <List>
                      <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.bankDetails?.bankName}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.bankDetails?.bankBranch}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.bankDetails?.accountName}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.bankDetails?.accountNo}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.bankDetails
                              ?.bankRoutingCode
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.bankDetails
                              ?.bankRoutingMethod
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.bankDetails?.swiftCode}
                        </Typography>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid> */}
              </Box>
            )}

            {!(
              currentAssignedTask?.wfActiveProcess == "vas_countercheck_task" ||
              currentAssignedTask?.wfActiveProcess == "task_validate_uploaded_receipt"
            ) &&
              additionalApplicationsSelection === "vpnConfigurationDetails" && (
                <Box spacing={5}>
                  <TableContainer sx={{ ml: -2, mt: 3 }}>
                    <Table sx={{ width: 500 }} aria-label="simple table">
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Supplier
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.supplier}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.type}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>Model</TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.model}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>OS</TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.os}
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Peer Address
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.peerAddress
                          }
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Test Peer Address
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.testPeerAddress
                          }
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Proposal Name
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.proposalname
                          }
                        </TableCell>
                      </TableRow>

                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Authentication Mode
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationMode
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Pre-shared Key
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.preSharedKey
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Authentication Algorithm
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationAlgorithm
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Encryption Algorithm
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.encryptionAlgorithm
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Diffie-Hellman Group
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.diffieHellmanGroup
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Lifetime Measurement
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeMeasurement
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Lifetime
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.lifetime}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Authentication Algorithm (ESP/SHA)
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationAlgorithmEsp
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Encryption Algorithm (AES 128)
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.encryptionAlgorithmAes
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Encapsulation Mode
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.encapsulationModeEsp
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Perfect Forward Secrecy
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.perfectForwardSecrecy
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Lifetime Measurement
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeMeasurementEsp
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Lifetime
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeEsp
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Network
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.network}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ fontWeight: "bold" }}>
                          Comment
                        </TableCell>
                        <TableCell style={{ cursor: "pointer" }}>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.comment}
                        </TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>

                  {/* <Grid container sx={{ pt: 2 }}>
                  <Grid item xs={6}>
                    <List>
                      {[
                        "Supplier",
                        "Type",
                        "Model",
                        "OS",
                        "Peer Address",
                        "Test Peer Address",
                        "Proposal Name",
                        "Authentication Mode",
                        "Pre-shared Key",
                        "Authentication Algorithm",
                        "Encryption Algorithm",
                        "Diffie-Hellman Group",
                        "Lifetime Measurement",
                        "Lifetime",
                        "Authentication Algorithm (ESP/SHA)",
                        "Encryption Algorithm (AES 128)",
                        "Encapsulation Mode",
                        "Perfect Forward Secrecy",
                        "Lifetime Measurement",
                        "Lifetime",
                        "Network",
                        "Comment",
                      ].map((value) => {
                        const listItemId = `${value}`;
                        return (
                          <ListItem sx={{ p: 1 }}>
                            <Typography
                              id={listItemId}
                              sx={{ fontWeight: "bold" }}
                            >
                              {`${value}`}
                            </Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <List>
                      <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.supplier}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.type}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.model}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.os}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.peerAddress
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.testPeerAddress
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.proposalname
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationMode
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.preSharedKey
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationAlgorithm
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.encryptionAlgorithm
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.diffieHellmanGroup
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeMeasurement
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.lifetime}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationAlgorithmEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.encryptionAlgorithmAes
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.encapsulationModeEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.perfectForwardSecrecy
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeMeasurementEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentAssignedTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.network}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentAssignedTask?.wfCurrTrans?.vpnConfiguration?.comment}
                        </Typography>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid> */}
                </Box>
              )}
          </Box>
        </Fade>
      </Modal>


            <Grid item xs={3}>
              <Box sx={{ backgroundColor: "#fff", p: 3 }}>
                <Container sx={{ backgroundColor: "#f9f9f9" }}>
                  <Grid container sx={{ width: "280px", ml: -3, pr: 2 }}>
                    <Grid item xs={6}>
                      <List>
                        {["Current User Assigned to Task"].map((value) => {
                          const listItemId = `${value}`;
                          return (
                            <ListItem sx={{ p: 1 }}>
                              <Typography
                                id={listItemId}
                                sx={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                {`${value}`}
                              </Typography>
                            </ListItem>
                          );
                        })}
                      </List>
                    </Grid>
                    <Grid item xs={6}>
                      <List>
                        <ListItem>
                          <Typography sx={{ fontSize: "14px" }}>
                            {currentAssignedTask?.wfUser?.firstName}{" "}
                            {currentAssignedTask?.wfUser?.lastName}
                          </Typography>
                        </ListItem>
                      </List>
                    </Grid>
                  </Grid>
                </Container>

                <Typography
                  textAlign="left"
                  style={sectionHeadingStyle}
                  sx={{ mb: 3, mt: 2 }}
                >
                  Assign task to different user
                </Typography>
                <FormControl fullWidth>
                  <Autocomplete
                    options={eligibleUsers}
                    value={selectedUser}
                    getOptionLabel={(option) => option.firstName ? option.firstName + " " + option.lastName : ""}
                    getOptionSelected={(option, value) =>
                      option.userId === value.userId
                    }
                    // style={{ width: 200 }}
                    onChange={(event, newValue) => {
                      if (newValue) {
                        const selectedUser = newValue;
                        console.log({ selectedUser });
                        setState((prevState) => ({
                          ...prevState,
                          selectedUser,
                        }));
                        setUserId(selectedUser?.userId);
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Select User"
                        variant="outlined"
                      />
                    )}
                  />
                </FormControl>
                <Box spacing={5} sx={{ mt: 2 }}>
                  <TextField
                    label="Add Comment"
                    name="comment"
                    value={comment}
                    onChange={handleInput}
                    size="small"
                    style={inputStyle}
                    fullWidth
                    multiline
                    required
                  />
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Button
                    style={isValidData ? primaryButton : disabledButton}
                    onClick={() => handleSubmitAssignee()}
                    disabled={!isValidData}
                  >
                    REASSIGN
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Snackbar
          anchorOrigin={{
            vertical: snackBarVerticalPosition,
            horizontal: snackBarHorizontalPosition,
          }}
          open={snackbarOpen}
          autoHideDuration={snackBarAutoHideDuration}
          onClose={() => snackBarHandle(false)}
        >
          <Alert
            onClose={handleClose}
            severity={snackBarSeverity}
            sx={{ width: "100%" }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </ThemeProvider>
  );
}
