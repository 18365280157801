import * as React from "react";
import {
  Backdrop,
  Box,
  Button,
  Container,
  Fade,
  Grid,
  IconButton,
  List,
  ListItem,
  Modal,
  Typography,
  Snackbar,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Alert } from "@mui/lab";
import {
  approveButton,
  modalStyle2,
  primaryButton,
  secondaryButton,
  disabledButton,
  modalStyle,
  rejectButton
} from "../../../common-styles";
import { inputStyle } from "../../partner/application-form/formStyles";
import { ArrowBack } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import apiRequest from "../../services/api-request";
import { useState } from "react";

const sectionHeadingStyle = {
  fontWeight: "bold",
  color: "#159F4A",
  fontSize: "1.2em",
};

const tableHeaderStyle = {
  fontWeight: "bold",
};

export default function ApplicationDetailsItNetworksPage(props) {
  const { togglePage, currentTask } = props;

  const { wfId } = currentTask;

  const { companyId } = currentTask?.wfCurrTrans;

  const initialState = {
    approved: false,
    loading: false,
    comment: "",
    commentError: false,
    commentHelperText: "",
    additionalApplicationsSelection: "",
    snackbarOpen: false,
    currentSelection: "",
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,

    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
  };

  const [state, setState] = useState(initialState);

  const {
    currentSelection,
    loading,
    comment,
    commentError,
    commentHelperText,
    additionalApplicationsSelection,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarVerticalPosition,
    snackBarHorizontalPosition,
  } = state;

  // const user = 'admin';

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const handleApiData = async (params) => {
    // console.log{params});
    const { severity, message, dataName, data } = params;
    // console.log(typeof data);

    if (severity === "success") {
      if (dataName === "fetchedVpnReport") {
        const file = new Blob([data], { type: "application/pdf" });

        const fileURL = URL.createObjectURL(file);

        const pdfWindow = window.open();

        pdfWindow.location.href = fileURL;
      }
    }
    setState((prevState) => ({
      ...prevState,
      [dataName]: data,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleGetVpnFormReport = async () => {
    await makeApiRequest({
      uri: `/reports/vpnform/${companyId}`,
      dataName: `fetchedVpnReport`,
      method: `get`,
      data: {},
    });
  };

  const handleTaskValidateVpnApplication = async () => {
    await makeApiRequest({
      uri: `/tickets/complete-task-it-validate-sdp-application`,
      dataName: `approvedCompany`,
      method: `post`,
      data: {
        approved: currentSelection == "approveApplication" ? true : false,
        comment,
        wfId,
      },
    });
    handleCloseApprovalModal();
    window.location.reload();
  };

  const handleCompleteVpnConfiguration = async () => {
    if (
      !window.confirm(
        `Are you sure you wish to mark vpn configuration task as done?`
      )
    ) {
      return false;
    }
    await makeApiRequest({
      uri: `/tickets/complete-task-complete-vpn-configuration`,
      dataName: `vpnConfigurationCompleted`,
      method: `post`,
      data: {
        approved: true,
        comment: "VPN Confirguration Completed",
        wfId: wfId,
      },
    });
    window.location.reload();
  };

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      commentError: name === "comment" ? isEmpty(value) : commentError,
      commentHelperText:
        name === "comment"
          ? isEmpty(value)
            ? "Required."
            : ""
          : commentHelperText,
    }));
  };

  const isEmpty = (data) => {
    let response = true;
    if (data) {
      response = false;
    }
    return response;
  };

  const isValidData = !isEmpty(comment);

  const handleApproval = (currentSelection) => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      currentSelection,
    }));
  };

  const handleCloseApprovalModal = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      currentSelection: "",
    }));
  };

  const handleAdditionalApplication = (additionalApplicationsSelection) => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      additionalApplicationsSelection,
    }));
  };

  const handleCloseAdditionalApplicationsModal = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      additionalApplicationsSelection: "",
    }));
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <Box sx={{ flexGrow: 1, pb: 5, pt: 4, backgroundColor: "#f9f9f9" }}>
      <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
        <CircularProgress color="success" />
      </Backdrop>

      {/* <PDFViewerDisplay fileData={fileData} /> */}
      <Grid container direction="row" alignItems="center">
        <IconButton
          onClick={() =>
            togglePage({
              currentPage: "pendingTaskList",
              currentTask,
            })
          }
        >
          <ArrowBack />
        </IconButton>
        <Typography variant="h5" textAlign="left" sx={{ fontWeight: "bold" }}>
          CSP Application #{currentTask?.wfCurrTrans?.companyId}
        </Typography>
      </Grid>

      <Grid container>
        <Grid item xs={9}>
          <Container sx={{ pt: 3 }}>
            <Typography textAlign="left" style={sectionHeadingStyle}>
              Company Information
            </Typography>
            <Container>
              <Grid container sx={{ pt: 3, ml: -4 }}>
                <Grid item xs={6}>
                  <List>
                    {[
                      "CRQ Number",
                      "Registered Company",
                      "Company Email Address",
                      "Company Website",
                      "Company Mobile Number",
                      "Physical Address",
                    ].map((value) => {
                      const listItemId = `${value}`;
                      return (
                        <ListItem sx={{ p: 1 }}>
                          <Typography
                            id={listItemId}
                            sx={{ fontWeight: "bold" }}
                          >
                            {`${value}`}
                          </Typography>
                        </ListItem>
                      );
                    })}
                  </List>
                </Grid>
                <Grid item xs={6}>
                  <List>
                    <ListItem>
                      <Typography sx={{ fontWeight: "bold" }}>
                        {currentTask?.wfCurrTrans?.application?.crqNumber}
                      </Typography>
                    </ListItem>
                    <ListItem sx={{}}>
                      <Typography>
                        {currentTask?.wfCurrTrans?.registeredCompanyName}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        {currentTask?.wfCurrTrans?.companyEmailAddress}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        {currentTask?.wfCurrTrans?.companyWebsiteUrl}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        {currentTask?.wfCurrTrans?.companyMobileNumber}
                      </Typography>
                    </ListItem>
                    <ListItem>
                      <Typography>
                        {currentTask?.wfCurrTrans?.physicalLocation}
                      </Typography>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Container>
          </Container>

          <Container sx={{ pt: 4 }}>
            <Typography textAlign="left" style={sectionHeadingStyle}>
              Other Applications
            </Typography>
            <TableContainer sx={{ ml: -2 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell style={tableHeaderStyle}>Application</TableCell>
                    <TableCell style={tableHeaderStyle}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      VPN Configuration Details
                    </TableCell>
                    <TableCell>
                      <Button
                        style={secondaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() =>
                          handleAdditionalApplication("vpnConfigurationDetails")
                        }
                      >
                        VIEW
                      </Button>
                      <Button
                        sx={{ ml: 5 }}
                        style={primaryButton}
                        variant="contained"
                        component="span"
                        disableElevation
                        onClick={() => handleGetVpnFormReport()}
                      >
                        VIEW VPN DOCUMENT
                      </Button>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </Grid>

        <Grid item xs={3}>
          <Box sx={{ mb: 3 }}>
            {currentTask?.wfActiveProcess == "task_validate_sdp_details_by_it" && (
              <Box>
                <Button
                  variant="contained"
                  style={approveButton}
                  sx={{ mb: 3 }}
                  onClick={() => handleApproval("approveApplication")}
                >
                  APPROVE APPLICATION{" "}
                </Button>

                <Button
                  variant="contained"
                  style={rejectButton}
                  onClick={() => handleApproval("rejectApplication")}
                >
                  REJECT APPLICATION
                </Button>
              </Box>
            )}

            {currentTask?.wfActiveProcess == "task_vpn_configuration" && (
              <Box>
                <Button
                  variant="contained"
                  sx={{ ml: -5 }}
                  style={approveButton}
                  onClick={() => handleCompleteVpnConfiguration()}
                  disableElevation
                >
                  COMPLETE VPN CONFIGURATION
                </Button>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>

      <Modal
        aria-labelledby="additional-applications-modal"
        aria-describedby="additional-applications-modal-description"
        open={additionalApplicationsSelection}
        onClose={handleCloseAdditionalApplicationsModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={additionalApplicationsSelection !== ""}>
          <Box style={modalStyle2} sx={{ p: 5 }}>
            {additionalApplicationsSelection === "vpnConfigurationDetails" && (
              <Typography
                id="additional-applications-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                VPN Configuration Details
              </Typography>
            )}

            {additionalApplicationsSelection === "vpnConfigurationDetails" && (
              <Box spacing={5}>
                <TableContainer sx={{ ml: -2, mt: 3 }}>
                  <Table sx={{ width: 500 }} aria-label="simple table">
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Supplier
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.vpnConfiguration?.supplier}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Type</TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.vpnConfiguration?.type}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Model</TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.vpnConfiguration?.model}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>OS</TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.vpnConfiguration?.os}
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Peer Address
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.peerAddress
                        }
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Test Peer Address
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.testPeerAddress
                        }
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Proposal Name
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.proposalname
                        }
                      </TableCell>
                    </TableRow>

                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Authentication Mode
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.authenticationMode
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Pre-shared Key
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.preSharedKey
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Authentication Algorithm
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.authenticationAlgorithm
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Encryption Algorithm
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.encryptionAlgorithm
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Diffie-Hellman Group
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.diffieHellmanGroup
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Lifetime Measurement
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.lifetimeMeasurement
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Lifetime
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.vpnConfiguration?.lifetime}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Authentication Algorithm (ESP/SHA)
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.authenticationAlgorithmEsp
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Encryption Algorithm (AES 128)
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.encryptionAlgorithmAes
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Encapsulation Mode
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.encapsulationModeEsp
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Perfect Forward Secrecy
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.perfectForwardSecrecy
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Lifetime Measurement
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.lifetimeMeasurementEsp
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Lifetime
                      </TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {
                          currentTask?.wfCurrTrans?.vpnConfiguration
                            ?.lifetimeEsp
                        }
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Network</TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.vpnConfiguration?.network}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell sx={{ fontWeight: "bold" }}>Comment</TableCell>
                      <TableCell style={{ cursor: "pointer" }}>
                        {currentTask?.wfCurrTrans?.vpnConfiguration?.comment}
                      </TableCell>
                    </TableRow>
                  </Table>
                </TableContainer>

                {/* <Grid container sx={{ pt: 2 }}>
                  <Grid item xs={6}>
                    <List>
                      {[
                        "Supplier",
                        "Type",
                        "Model",
                        "OS",
                        "Peer Address",
                        "Test Peer Address",
                        "Proposal Name",
                        "Authentication Mode",
                        "Pre-shared Key",
                        "Authentication Algorithm",
                        "Encryption Algorithm",
                        "Diffie-Hellman Group",
                        "Lifetime Measurement",
                        "Lifetime",
                        "Authentication Algorithm (ESP/SHA)",
                        "Encryption Algorithm (AES 128)",
                        "Encapsulation Mode",
                        "Perfect Forward Secrecy",
                        "Lifetime Measurement",
                        "Lifetime",
                        "Network",
                        "Comment",
                      ].map((value) => {
                        const listItemId = `${value}`;
                        return (
                          <ListItem sx={{ p: 1 }}>
                            <Typography
                              id={listItemId}
                              sx={{ fontWeight: "bold" }}
                            >
                              {`${value}`}
                            </Typography>
                          </ListItem>
                        );
                      })}
                    </List>
                  </Grid>
                  <Grid item xs={6}>
                    <List>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.supplier}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.type}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.model}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.os}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.peerAddress
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.testPeerAddress
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.proposalname
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationMode
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.preSharedKey
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationAlgorithm
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.encryptionAlgorithm
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.diffieHellmanGroup
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeMeasurement
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.lifetime}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.authenticationAlgorithmEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.encryptionAlgorithmAes
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.encapsulationModeEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.perfectForwardSecrecy
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeMeasurementEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {
                            currentTask?.wfCurrTrans?.vpnConfiguration
                              ?.lifetimeEsp
                          }
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.network}
                        </Typography>
                      </ListItem>
                      <ListItem>
                        <Typography>
                          {currentTask?.wfCurrTrans?.vpnConfiguration?.comment}
                        </Typography>
                      </ListItem>
                    </List>
                  </Grid>
                </Grid> */}
              </Box>
            )}
          </Box>
        </Fade>
      </Modal>

      <Modal
        aria-labelledby="approval-modal"
        aria-describedby="approval-modal-description"
        open={currentSelection}
        onClose={handleCloseApprovalModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={currentSelection !== ""}>
          <Box style={modalStyle} sx={{ p: 5 }}>
            {currentSelection === "approveApplication" && (
              <Typography
                id="approval-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                Approve Application
              </Typography>
            )}

            {currentSelection === "rejectApplication" && (
              <Typography
                id="approval-modal"
                variant="h6"
                component="h2"
                sx={{ color: "#159F4A", fontWeight: "bold" }}
              >
                Reject Application
              </Typography>
            )}

            <Box spacing={5} sx={{ mt: 4 }}>
              <TextField
                label="Add Comment"
                name="comment"
                value={comment}
                onChange={handleInput}
                size="small"
                style={inputStyle}
                fullWidth
                multiline
                required
              />
            </Box>

            <Box>
              <Button
                style={isValidData ? primaryButton : disabledButton}
                onClick={() => handleTaskValidateVpnApplication()}
                disabled={!isValidData}
              >
                CONFIRM
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>

      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
