import Box from "@mui/material/Box";
import { formStyle, inputStyle } from "./user-management-styles";
import Typography from "@mui/material/Typography";
import {
  Alert,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  TextField,
} from "@mui/material";
import { disabledButton, primaryButton } from "../../common-styles";
import * as React from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useState } from "react";
import apiRequest from "../services/api-request";
import Password from "../services/password-validator";
import LoginAppBar from "./login-app-bar";
import LeftImage from "./left-image";

export default function PasswordReset(props) {

  const { password_reset_token } = props;

  const initialState = {
    password: "",
    token: password_reset_token,
    passwordError: false,
    passwordHelperText: "",
    password2: "",
    password2Error: false,
    password2HelperText: "",
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,

    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
    loading: false,
  };

  const [state, setState] = useState(initialState);

  const {
    password,
    token,
    passwordError,
    passwordHelperText,
    password2,
    password2Error,
    password2HelperText,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarVerticalPosition,
    snackBarHorizontalPosition,
    loading,
  } = state;

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    // console.logname, value);
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      passwordError:
        name === "password" ? !Password.validate(value) : passwordError,
      passwordHelperText:
        name === "password"
          ? !Password.validate(value)
            ? "Invalid."
            : ""
          : passwordHelperText,
      password2Error: !(name === "password2" && password === value),
      password2HelperText: !(name === "password2" && password === value)
        ? "Invalid"
        : "",
    }));
  };

  const checkPassword = Password.validate(password) && password === password2;

  const isValidData = checkPassword;

  const handleApiData = (params) => {
    // console.log{params});
    const { data, dataName, severity, message } = params;
    if (severity === "success" && data?.operationResult === "SUCCESS") {
      setState((prevState) => ({
        ...prevState,
        snackbarOpen: true,
        snackbarMessage: message,
        snackBarSeverity: severity,
      }));
      window.location.replace(`${process.env.REACT_APP_BASE_URL}`);
    } else {
      setState((prevState) => ({
        ...prevState,
        snackbarOpen: true,
        snackbarMessage:
          "There was a problem resetting your password. Please try again",
        snackBarSeverity: severity,
      }));
    }
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handlePasswordReset = async (event) => {
    event.preventDefault();
    await makeApiRequest({
      uri: `/users/password-reset`,
      dataName: `requestedResetLink`,
      method: `post`,
      data: { password, token },
    });
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <Box>
      <Backdrop open={loading}>
        <CircularProgress color="success" />{" "}
      </Backdrop>
      <LoginAppBar />
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <LeftImage />
        </Grid>

        <Grid item xs={12} sm={6} style={formStyle}>
          <form noValidate onSubmit={handlePasswordReset}>
            <>
              <Box>
                <Typography sx={{ fontSize: 23, fontWeight: "bold", mb: 3 }}>
                  Reset Your Password
                </Typography>

                <TextField
                  error={passwordError}
                  helperText={passwordHelperText}
                  label="New Password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  id="outlined-size-small"
                  size="small"
                  style={inputStyle}
                  onChange={handleInput}
                  value={password ?? ""}
                  fullWidth
                  required
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />

                <TextField
                  error={password2Error}
                  helperText={password2HelperText}
                  label="Confirm Password"
                  name="password2"
                  type={showPassword ? "text" : "password"}
                  onChange={handleInput}
                  value={password2 ?? ""}
                  id="outlined-size-small"
                  size="small"
                  style={inputStyle}
                  fullWidth
                  required
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              <Box>
                <Button
                  type="submit"
                  variant="contained"
                  style={isValidData ? primaryButton : disabledButton}
                  disableElevation
                  disabled={!isValidData}
                >
                  SET PASSWORD
                </Button>
              </Box>{" "}
            </>
          </form>
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}
