import passwordValidator from "password-validator";
// Create a schema
const schema = new passwordValidator();

// Add properties to it
schema
  .is()
  .min(8) // Minimum length 8
  .is()
  .max(100) // Maximum length 100
  .has()
  .symbols(1) // Must have at least one special character.
  .has()
  .uppercase() // Must have uppercase letters
  .has()
  .lowercase() // Must have lowercase letters
  .has()
  .digits(2) // Must have at least 2 digits
  .has()
  .not()
  .spaces() // Should not have spaces
  .is()
  .not()
  .oneOf(["Passw0rd", "Password123"]); // Blacklist these values

// // Validate against a password string
// console.logschema.validate("validPASS123"));
// // => true
// console.logschema.validate("invalidPASS"));
// // => false

// // Get a full list of rules which failed
// console.logschema.validate("joke", { list: true }));
// => [ 'min', 'uppercase', 'digits' ]
export default schema;
