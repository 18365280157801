import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  FormGroup,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import apiRequest from "../services/api-request";
import {
  inputStyle,
  keyContactStyle,
} from "../partner/application-form/formStyles";
import {
  disabledButton,
  primaryButton,
  secondaryButton,
} from "../../common-styles";

export default function AddUser(props) {
  
  const { handleCloseDialog, allRoles, action } = props;

  const initialState = {
    firstName: "",
    firstNameError: false,
    firstNameHelperText: "",
    lastName: "",
    lastNameError: false,
    lastNameHelperText: "",
    email: "",
    emailError: false,
    emailHelperText: "",
    msisdn: "",
    msisdnError: false,
    msisdnHelperText: "",
    roles: [],
    staff: false,
    snackbarOpen: false,
    snackBarAutoHideDuration: process.env.REACT_APP_SNACKBAR_AUTOHIDE_DURATION,

    snackbarMessage: "",
    snackBarSeverity: "info",
    snackBarVerticalPosition: process.env.REACT_APP_SNACKBAR_VERTICAL_POSITION,
    snackBarHorizontalPosition:
      process.env.REACT_APP_SNACKBAR_HORIZONTAL_POSITION,
  };

  const [state, setState] = useState(initialState);

  const {
    firstName,
    firstNameError,
    firstNameHelperText,
    lastName,
    lastNameError,
    lastNameHelperText,
    email,
    emailError,
    emailHelperText,
    msisdn,
    msisdnError,
    msisdnHelperText,
    roles,
    snackbarOpen,
    snackBarAutoHideDuration,
    snackbarMessage,
    snackBarSeverity,
    snackBarVerticalPosition,
    snackBarHorizontalPosition,
    userId,
  } = state;

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  const handleInput = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setState((prevState) => ({
      ...prevState,
      [name]: value,
      firstNameError: name === "firstName" ? isEmpty(value) : firstNameError,
      firstNameHelperText:
        name === "firstName"
          ? isEmpty(value)
            ? "Required."
            : ""
          : firstNameHelperText,
      lastNameError: name === "lastName" ? isEmpty(value) : lastNameError,
      lastNameHelperText:
        name === "lastName"
          ? isEmpty(value)
            ? "Required."
            : ""
          : lastNameHelperText,
      emailError: name === "email" ? isEmpty(value) : emailError,
      emailHelperText:
        name === "email"
          ? isEmpty(value)
            ? "Required."
            : ""
          : emailHelperText,
      msisdnError: name === "msisdn" ? isEmpty(value) : msisdnError,
      msisdnHelperText:
        name === "msisdn"
          ? isEmpty(value)
            ? "Required."
            : ""
          : msisdnHelperText,
    }));
  };

  const isEmpty = (data) => {
    let response = true;
    if (data) {
      response = false;
    }
    return response;
  };

  const isValidData =
    !isEmpty(firstName) &&
    !isEmpty(lastName) &&
    !isEmpty(email) &&
    !isEmpty(msisdn);

  useEffect(() => {
    if (props?.action === "Update") {
      const { currentUser } = props;
      setState((prevState) => ({
        ...prevState,
        ...currentUser,
      }));
    }
  }, [props.currentUser]);

  const handleApiData = (params) => {
    // console.log{params});
    const { severity, message } = params;
    if (severity === "success") {
      // const {companyId} = data;
      // handleSubmitUserData();
      handleCloseDialog();
    }
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  const handleSubmitUserData = async () => {
    const action = userId ? "update" : "create";
    let uri = `/users`;
    let method = `post`;
    if (action === "update") {
      uri = `/users/${userId}`;
      method = `put`;
    }
    await makeApiRequest({
      uri,
      dataName: `createdUser`,
      method,
      data: {
        firstName,
        lastName,
        email,
        msisdn,
        roles,
        staff: true,
      },
    });
  };

  const snackBarHandle = (status) => {
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: status,
    }));
  };

  const handleClose = () => {
    // setState({...state, open: false});
    setState((prevState) => ({
      ...prevState,
      snackbarOpen: false,
    }));
  };

  return (
    <Box>
      <Dialog
        open={!(action === "Close")}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
      >
        <DialogContent>
          <Typography sx={{ fontWeight: "bold", mb: 4 }}>
            {action} New User
          </Typography>
          <Stack direction="row" spacing={5}>
            <TextField
              error={firstNameError}
              helperText={firstNameHelperText}
              label="First Name"
              name="firstName"
              type="text"
              size="small"
              style={inputStyle}
              onChange={handleInput}
              value={firstName}
              fullWidth
              required
            />
            <TextField
              error={lastNameError}
              helperText={lastNameHelperText}
              label="Last Name"
              name="lastName"
              type="text"
              size="small"
              onChange={handleInput}
              value={lastName}
              fullWidth
              required
            />
          </Stack>
          <Stack direction="row" spacing={5} sx={{ mt: 2 }}>
            <TextField
              error={emailError}
              helperText={emailHelperText}
              label="Email"
              name="email"
              type="email"
              size="small"
              onChange={handleInput}
              value={email}
              fullWidth
              required
              disabled={action == 'Update'}
            />
          </Stack>
          <Stack direction="row" spacing={5} sx={{ mt: 3 }}>
            <TextField
              error={msisdnError}
              helperText={msisdnHelperText}
              label="Phone Number"
              name="msisdn"
              type="number"
              size="small"
              onChange={handleInput}
              value={msisdn}
              fullWidth
              required
              disabled={action == 'Update'}
            />
          </Stack>

          <Typography sx={{ mt: 4, fontWeight: "bold" }}>
            Select Roles:
          </Typography>

          <Stack direction="row" spacing={5} sx={{ mt: 2 }}>
            <FormGroup>
              {allRoles.map((role, index) => {
                return (
                  <FormControlLabel
                    key={index}
                    style={keyContactStyle}
                    control={
                      <Checkbox
                        key={role?.roleName}
                        checked={
                          roles.filter(
                            (currentRole) => currentRole?.name == role?.roleName)?.length > 0
                        }
                        onChange={() => {

                          let { roles } = state;
                          
                          const indexOfRole = roles.findIndex(
                            (currentRole) => currentRole?.name === role?.roleName);
                          
                          // console.log({ indexOfRole });

                          if (indexOfRole == -1) {
                            roles = [...roles, { name: role?.roleName }];
                          } else {
                            roles.splice(indexOfRole, 1);
                          }
                          setState((prevState) => ({
                            ...prevState,
                            roles,
                          }));
                        }}
                      />
                    }
                    label={role?.roleName}
                  />
                );
              })}
            </FormGroup>
          </Stack>

          <Grid container spacing={3} sx={{ mt: 2 }}>
            <Grid item xs>
              <Button
                onClick={() => {
                  handleCloseDialog();
                }}
                color="primary"
                variant="outlined"
                style={secondaryButton}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                variant="contained"
                style={isValidData ? primaryButton : disabledButton}
                onClick={() => {
                  handleSubmitUserData();
                }}
                disableElevation
                disabled={!isValidData}
              >
                {action}
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>

      <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={snackbarOpen}
        autoHideDuration={snackBarAutoHideDuration}
        onClose={() => snackBarHandle(false)}
      >
        <Alert
          onClose={handleClose}
          severity={snackBarSeverity}
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

    </Box>
  );
}
