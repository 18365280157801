import {
  Box,
  Typography,
  Stack,
  Button,
  Backdrop,
  CircularProgress,
  Divider,
} from "@mui/material";
import * as React from "react";
import PropTypes from "prop-types";
import MUIDataTable from "mui-datatables";
import { createTheme, responsiveFontSizes } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { ThemeProvider } from "@mui/styles";
import customLocalStorage from "../../services/local-storage";
import apiRequest from "../../services/api-request";
import { primaryButton, numberContainer } from "../../../common-styles";
import { makeStyles } from "@material-ui/core/styles";
import { drawerWidth } from "../../navigation/theme";
import ApplicationDetailsLegalPage from "./application-details-legal";
import EmptyTaskPage from "../empty-page";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function LegalApproval() {
  const classes = useStyles();

  const { userId, firstName, lastName, authorities } =
    customLocalStorage.decrypt("cpoUser") || {};

  // console.log(customLocalStorage.decrypt("cpoUser"));

  const initialState = {
    pendingTasks: [],
    currentTask: {},
    currentPage: "pendingTaskList",
    loading: false,
  };

  const [state, setState] = useState(initialState);

  let theme = createTheme();
  theme = responsiveFontSizes(theme);

  const { currentTask, pendingTasks, currentPage, loading } = state;

  const columns = [
    {
      name: "wfActiveProcess",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                  {value}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Business Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15, fontWeight: "bold" }}>
                  {value?.registeredCompanyName}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Company Email Address",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.companyEmailAddress}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Company Registration Number",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.companyRegNumber}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Phone Number",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.companyMobileNumber}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfCurrTrans",
      label: "Physical Location",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          return (
            <>
              <Stack direction="row" spacing={3}>
                <Typography sx={{ fontSize: 15 }}>
                  {value?.physicalLocation}
                </Typography>
              </Stack>
            </>
          );
        },
      },
    },
    {
      name: "wfId",
      label: "Action",
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value) => {
          const currentTask = pendingTasks.filter(
            (task) => task?.wfId == value
          )[0];
          return (
            <>
              {currentTask?.wfActiveProcess == "legal_approval_task" && (
                <Button
                  style={primaryButton}
                  onClick={() =>
                    togglePage({
                      currentPage: "applicationDetails",
                      currentTask,
                    })
                  }
                >
                  VIEW APPLICATION
                </Button>
              )}
              {currentTask?.wfActiveProcess ==
                "task_contract_signing_marking" && (
                <Button
                  style={primaryButton}
                  onClick={() =>
                    togglePage({
                      currentPage: "applicationDetails",
                      currentTask,
                    })
                  }
                >
                  CONTRACT SIGNING
                </Button>
              )}
            </>
          );
        },
      },
    },
  ];

  // const handleOnDownload = (buildHead, buildBody, columns, data) =>  {
  //       let csvContent = buildHead(columns) + buildBody(data);
  //       const a = document.createElement('a');
  //       const textFileAsBlob = new Blob(["\ufeff", csvContent], {
  //           type: 'text/csv;charset=UTF-8',
  //           encoding: "UTF-8"
  //         });
  //       a.href = window.URL.createObjectURL(textFileAsBlob);
  //       a.download = 'taskList.csv';
  //       a.click();
  //       return false;
  //   }

  const options = {
    selectableRows: "none",
    download: false,
    // downloadOptions: {filterOptions : {useDisplayedRowsOnly : true}},
    // onDownload: (buildHead, buildBody, columns, data) => { handleOnDownload(buildHead, buildBody, columns, data) },
  };

  const togglePage = (params) => {
    const { currentPage, currentTask } = params;
    setState((prevState) => ({
      ...prevState,
      currentPage,
      currentTask,
    }));
  };

  const handleApiData = (params) => {
    // console.log{params});
    const { severity, message, data, dataName } = params;
    let stateUpdate = {
      snackbarOpen: true,
      snackbarMessage: message,
      snackBarSeverity: severity,
    };
    if (severity === "success" && dataName) {
      // console.log({data})
      stateUpdate = { ...stateUpdate, [dataName]: data };
    }
    setState((prevState) => ({
      ...prevState,
      ...stateUpdate,
    }));
  };

  const makeApiRequest = async (params) => {
    handleLoader(true);
    const { uri, dataName, method, data } = params;
    const requestParams = {
      handleLoader,
      url: `${process.env.REACT_APP_API_URL}${uri}`,
      data,
      callBack: handleApiData,
      method,
      dataName,
    };
    await apiRequest(requestParams);
    handleLoader(false);
  };

  useEffect(() => {
    // const pageNumber = 2;
    const limit = 500;
    makeApiRequest({
      uri: `/tickets?limit=${limit}&userId=${userId}`,
      dataName: `pendingTasks`,
      method: `get`,
      data: {},
    });
  }, []);

  const pendingTasksCount = pendingTasks.length;

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  //   const [checked, setChecked] = React.useState([0]);

  //   const handleToggle = (value: number) => () => {
  //     const currentIndex = checked.indexOf(value);
  //     const newChecked = [...checked];

  //     if (currentIndex === -1) {
  //       newChecked.push(value);
  //     } else {
  //       newChecked.splice(currentIndex, 1);
  //     }

  //     setChecked(newChecked);
  //   };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ flexGrow: 1 }}>
        <Backdrop sx={{ zIndex: 1, color: "#fff" }} open={loading}>
          <CircularProgress color="success" />
        </Backdrop>

        <Typography
          variant="h4"
          textAlign="left"
          sx={{ m: 3, fontWeight: "bold" }}
        >
          Content Service Provider Applications
        </Typography>
        <Typography textAlign="left" sx={{ m: 3 }}>
          Welcome {firstName} {lastName}
        </Typography>

        <Box sx={{ m: 3 }}>
          <Box sx={{ mt: 1 }}>
            <Typography
              variant="h6"
              textAlign="left"
              sx={{ fontWeight: "bold" }}
            >
              <span>Pending Tasks</span>
              <span style={numberContainer}>{pendingTasksCount}</span>
            </Typography>
          </Box>

          <Typography sx={{ mt: 2, fontSize: "14px" }}>
              View and action on tasks assigned to you here
            </Typography>

          {/* <Divider sx={{ mt: 2 }} /> */}

          <Box sx={{ textAlign: "center", mt: 3 }}>
            {currentPage == "pendingTaskList" && pendingTasks?.length < 1 && (
              <EmptyTaskPage />
            )}
          </Box>

          <Box sx={{ mt: 3 }}>
            {currentPage == "pendingTaskList" && pendingTasks?.length > 0 && (
              <MUIDataTable
                title="Task List"
                data={pendingTasks}
                columns={columns}
                options={options}
              />
            )}
            {currentPage == "applicationDetails" && (
              <ApplicationDetailsLegalPage
                currentTask={currentTask}
                togglePage={togglePage}
              />
            )}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
