import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import LoginPage from "../user-management/login";
import Registration from "../user-management/registration";
import customLocalStorage from "../services/local-storage";
import jwt from "jwt-decode";
import * as qs from "query-string";
import verifyRegistration from "../user-management/verify-registration";
import PasswordResetRequest from "../user-management/password-reset-request";
import PasswordReset from "../user-management/password-reset";
import VerifyEmail from "../user-management/verify-email";
import moment from "moment";
import { drawerWidth } from "./theme";
import TopNavBar from "./top-nav-bar";
import SideDrawer from "./side-drawer";
import BottomNavBar from "./bottom-nav-bar";
import PartnerLanding from "../partner/partner-landing";
import {
  Backdrop,
  CircularProgress,
  CssBaseline,
} from "@mui/material";
import debounce from "lodash.debounce";
import apiRequest from "../services/api-request";
import SuperAdminLanding from "../super-admin/super-admin-landing";
import VasAccountManager from "../admin/vas-account-manager/account-manager";
import LegalApproval from "../admin/legal/legal";
import Finance from "../admin/finance/finance";
import VasPlanning from "../admin/vas-support/vas-planning/vas-planning";
import PrsTechSupport from "../admin/vas-support/prs-tech-support/prs-tech-support";
import ItNetworks from "../admin/it-networks/it-networks";
import AccountsReceivable from "../admin/accounts-receivable/accounts-receivable";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

export default function Home() {
  const classes = useStyles();

  const user = customLocalStorage.decrypt("cpoUser");
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const clientSecret = process.env.REACT_APP_CLIENT_SECRET;

  //Record user activity
  if (user) {
    user.lastTimeActive = moment();
    customLocalStorage.encrypt("cpoUser", JSON.stringify(user));
  }

  const initialState = {
    menu: customLocalStorage.decrypt("cpoLastMenu") ?? "Home",
    displaySnackBar: false,
    snackBarMessage: "",
    snackBarAutoHideDuration: 2000, //milliseconds
    snackBarVerticalPosition: "top",
    snackBarHorizontalPosition: "right",
    snackBarSeverity: "info",
    loading: false,
    isSignedIn: false,
    signUp: false,
    otpRequired: false,
    isResetPassword: false,
    isResetPasswordPage: false,
    isAccountsReceivablePage: false,
    isAccountsReceivableTaskCompleted: false,
    tfa_token: "",
    workflowToken: "",
    password_reset_token: "",
    autoLogOutTriggered: false,
    isEmailVerified: false,
    isEmailVerifiedPage: false,
    isSideDrawerOpen: false,
  };

  const [state, setState] = useState(initialState);

  const {
    isSignedIn,
    signUp,
    otpRequired,
    isResetPassword,
    isResetPasswordPage,
    tfa_token,
    password_reset_token,
    workflowToken,
    isEmailVerified,
    isEmailVerifiedPage,
    isAccountsReceivablePage,
    isAccountsReceivableTaskCompleted,
    isSideDrawerOpen,
    loading
  } = state;

  const handleSignedInUser = (data) => {
    if (!data?.accessToken) {
      alert(`Problem with sign in, access token missing`);
      // console.log({ data });
      return;
    }

    let tokenData = {};

    try {
      tokenData = jwt(data?.accessToken);
    } catch (error) {
      alert(`Problem accessing token data`);
      // console.log({ error });
      return;
    }

    const user = { ...data, ...tokenData };
    // const user = jwt(data?.accessToken);
    // console.log{user});
    customLocalStorage.encrypt("cpoUser", JSON.stringify(user));
    setState((prevState) => ({
      ...prevState,
      isSignedIn: true,
      otpRequired: false,
    }));
  };

  let isPartner = false;
  if (user?.authorities?.length > 0) {
    const role = user.authorities.filter((role) => role === "ROLE_PARTNER");
    isPartner = role?.length > 0;
  }

  let isSuperAdmin = false;
  if (user?.authorities?.length > 0) {
    const role = user.authorities.filter((role) => role === "ROLE_SUPER_ADMIN");
    isSuperAdmin = role?.length > 0;
  }

  let isVasAccountManager = false;
  if (user?.authorities?.length > 0) {
    const role = user.authorities.filter(
      (role) => role === "ROLE_VAS_ACCOUNT_MANAGER"
    );
    isVasAccountManager = role?.length > 0;
  }

  let isLegal = false;
  if (user?.authorities?.length > 0) {
    const role = user.authorities.filter((role) => role === "ROLE_LEGAL");
    isLegal = role?.length > 0;
  }

  let isFinance = false;
  if (user?.authorities?.length > 0) {
    const role = user.authorities.filter((role) => role === "ROLE_FINANCE");
    isFinance = role?.length > 0;
  }

  let isVasPlanning = false;
  if (user?.authorities?.length > 0) {
    const role = user.authorities.filter(
      (role) => role === "ROLE_VAS_PLANNING"
    );
    isVasPlanning = role?.length > 0;
  }

  let isPrsTechSupport = false;
  if (user?.authorities?.length > 0) {
    const role = user.authorities.filter(
      (role) => role === "ROLE_PRS_TECH_SUPPORT"
    );
    isPrsTechSupport = role?.length > 0;
  }

  let isItNetworks = false;
  if (user?.authorities?.length > 0) {
    const role = user.authorities.filter((role) => role === "ROLE_IT_NETWORKS");
    isItNetworks = role?.length > 0;
  }

  const refreshToken = async () => {
    const user = customLocalStorage.decrypt("cpoUser");
    const { refreshToken } = user;
    const data = {
      grantType: process.env.REACT_APP_SIGN_IN_GRANT_TYPE,
      clientSecret,
      clientId,
      refreshToken,
    };
    const params = {
      url: `${process.env.REACT_APP_API_URL}/login/refresh/token`,
      data,
      callBack: handleRefreshToken,
      method: `post`,
    };
    handleLoader(true);
    await apiRequest(params);
    handleLoader(false);
  };

  const handleRefreshToken = (params) => {
    const { data, status, severity } = params;
    if (status === 401) handleLogOut({ msg: data, severity });
    if (!data) return;
    const { access } = data;
    if (!access) return;
    const { exp } = jwt(access);
    if (!exp) return;
    const user = customLocalStorage.decrypt("cpoUser");
    user.exp = exp;
    user.access = access;
    customLocalStorage.encrypt("cpoUser", JSON.stringify(user));
  };

  const checkTokenExpiry = async () => {
    const user = customLocalStorage.decrypt("cpoUser");
    const { exp } = user || {};
    // console.log(onsole.log({ exp });
    const currentTimestamp = moment().unix();
    // console.log(onsole.log({ currentTimestamp });
    if (currentTimestamp > exp) {
      await refreshToken();
    }
  };

  const autoLogOut = debounce(async () => {
    const user = customLocalStorage.decrypt("cpoUser");
    // console.log(onsole.log({ user });
    const duration = moment
      .duration(moment().diff(user?.lastTimeActive))
      .asMinutes();
    // console.log({ duration });
    const setTimeOut = process.env.REACT_APP_AUTOLOGOUT_DURATION;
    if (user?.lastTimeActive && duration > setTimeOut) {
      handleLogOut({ msg: `Auto Logout`, severity: `warning` });
    }
    await autoLogOut();
    await checkTokenExpiry();
  }, 10000);

  const handleLogOut = (params) => {
    let msg = params?.msg ? params?.msg : "Signed out";
    let severity = params?.severity ? params?.severity : "info";
    localStorage.clear();
    setState((prevState) => ({
      ...prevState,
      isSignedIn: false,
      menu: "Home",
      displaySnackBar: true,
      snackBarMessage: msg,
      snackBarSeverity: severity,
    }));
  };

  useEffect(() => {
    autoLogOut();

    const urlParams = qs.parse(window.location.search);
    const email_verification_token = urlParams?.email_verification_token;
    const password_reset_token = urlParams?.password_reset_token;
    const workflow_token = urlParams?.workflow_token;

    if (email_verification_token) {
      verifyRegistration({ email_verification_token, toggleVerifyEmail });
    }

    if (workflow_token) {
      // console.log(workflow_token);
      setState((prevState) => ({
        ...prevState,
        workflowToken: workflow_token,
      }));
      accountsReceivablePage({ workflowToken, toggleAccountsReceivablePage });
    }

    if (password_reset_token) {
      // window.location.replace("/?password_reset_token");
      // console.logpassword_reset_token);
      passwordResetPage(password_reset_token);
    }

    if (password_reset_token) {
      // window.location.replace("/?password_reset_token");
      // console.logpassword_reset_token);
      passwordResetPage(password_reset_token);
    }

    const { userId, exp } = customLocalStorage.decrypt("cpoUser") || {};
    const currentTimestamp = moment().unix();
    // console.log{ currentTimestamp });
    if (userId) {
      if (currentTimestamp < exp) {
        setState((prevState) => ({
          ...prevState,
          isSignedIn: true,
        }));
      }
    }
  }, []);

  const handleSignUpToggle = () => {
    setState((prevState) => ({
      ...prevState,
      signUp: !prevState.signUp,
    }));
  };

  const handleAfterRegistration = () => {
    setState((prevState) => ({
      ...prevState,
      signUp: false,
    }));
  };

  const togglePasswordResetRequest = (tfa_token) => {
    setState((prevState) => ({
      ...prevState,
      isResetPassword: !prevState.isResetPassword,
      tfa_token,
    }));
  };

  const passwordResetPage = (password_reset_token) => {
    setState((prevState) => ({
      ...prevState,
      isResetPasswordPage: true,
      password_reset_token,
    }));
  };

  const accountsReceivablePage = (workflow_token) => {
    setState((prevState) => ({
      ...prevState,
      isAccountsReceivablePage: true,
      workflow_token,
    }));
  };

  const toggleVerifyEmail = ({ isEmailVerifiedPage, isEmailVerified }) => {
    setState((prevState) => ({
      ...prevState,
      isEmailVerified,
      isEmailVerifiedPage,
    }));
  };

  const toggleAccountsReceivablePage = ({
    isAccountsReceivablePage,
    isAccountsReceivableTaskCompleted,
  }) => {
    setState((prevState) => ({
      ...prevState,
      isAccountsReceivableTaskCompleted,
      isAccountsReceivablePage,
    }));
  };

  const toggleSideDrawer = () => {
    setState((prevState) => ({
      ...prevState,
      isSideDrawerOpen: !prevState.isSideDrawerOpen,
    }));
  };

  const handleCloseSnackBar = () => {
    setState((prevState) => ({
      ...prevState,
      displaySnackBar: false,
      isLoading: false,
    }));
  };

  const handleLoader = (action) => {
    setState((prevState) => ({
      ...prevState,
      loading: action,
    }));
  };

  return (
    <>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress />
      </Backdrop>
      {/* <Snackbar
        anchorOrigin={{
          vertical: snackBarVerticalPosition,
          horizontal: snackBarHorizontalPosition,
        }}
        open={displaySnackBar}
        onClose={handleCloseSnackBar}
        autoHideDuration={snackBarautoHideDuration}
        // message={snackBarMessage}
      >
        <Alert onClose={handleCloseSnackBar} severity={snackBarSeverity}>
          {snackBarMessage}
        </Alert>
      </Snackbar> */}
      <div className={classes.root}>
        <CssBaseline />
        <TopNavBar
          toggleSideDrawer={toggleSideDrawer}
          isSideDrawerOpen={isSideDrawerOpen}
          isSignedIn={isSignedIn}
        />
        <SideDrawer
          toggleSideDrawer={toggleSideDrawer}
          isSideDrawerOpen={isSideDrawerOpen}
        />
        <main
          className={clsx(classes.content, {
            [classes.contentShift]: isSideDrawerOpen,
          })}
        >
          <div className={classes.drawerHeader} />
          {signUp &&
            !isSignedIn &&
            !otpRequired &&
            !isResetPassword &&
            !isResetPasswordPage &&
            !isEmailVerifiedPage &&
            !isAccountsReceivablePage && (
              <Registration
                handleSignUpToggle={handleSignUpToggle}
                handleAfterRegistration={handleAfterRegistration}
              />
            )}
          {!isSignedIn &&
            !signUp &&
            !isResetPassword &&
            !isResetPasswordPage &&
            !isEmailVerifiedPage &&
            !isAccountsReceivablePage && (
              <LoginPage
                handleSignUpToggle={handleSignUpToggle}
                togglePasswordResetRequest={togglePasswordResetRequest}
                tfa_token={tfa_token}
                handleSignedInUser={handleSignedInUser}
              />
            )}
          {isResetPassword &&
            !isSignedIn &&
            !signUp &&
            !otpRequired &&
            !isResetPasswordPage &&
            !isEmailVerifiedPage &&
            !isAccountsReceivablePage && (
              <PasswordResetRequest
                togglePasswordResetRequest={togglePasswordResetRequest}
              />
            )}
          {!isSignedIn &&
            !signUp &&
            !otpRequired &&
            !isEmailVerifiedPage &&
            !isAccountsReceivablePage &&
            isResetPasswordPage && (
              <PasswordReset password_reset_token={password_reset_token} />
            )}
          {!isSignedIn &&
            !signUp &&
            !otpRequired &&
            !isResetPasswordPage &&
            !isAccountsReceivablePage &&
            isEmailVerifiedPage && (
              <VerifyEmail
                toggleVerifyEmail={toggleVerifyEmail}
                isEmailVerified={isEmailVerified}
              />
            )}
          {!isSignedIn &&
            !signUp &&
            !otpRequired &&
            !isResetPasswordPage &&
            !isEmailVerifiedPage &&
            isAccountsReceivablePage && (
              <AccountsReceivable
                toggleAccountsReceivablePage={toggleAccountsReceivablePage}
                isAccountsReceivableTaskCompleted={
                  isAccountsReceivableTaskCompleted
                }
                workflowToken={workflowToken}
              />
            )}
          {isSignedIn && isPartner && <PartnerLanding />}
          {isSignedIn && isSuperAdmin && <SuperAdminLanding />}
          {isSignedIn && isVasAccountManager && <VasAccountManager />}
          {isSignedIn && isLegal && <LegalApproval />}
          {isSignedIn && isFinance && <Finance />}
          {isSignedIn && isVasPlanning && <VasPlanning />}
          {isSignedIn && isPrsTechSupport && <PrsTechSupport />}
          {isSignedIn && isItNetworks && <ItNetworks />}
        </main>
      </div>
      <BottomNavBar />
    </>
  );
}
