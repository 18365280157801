export const inputStyle = {
    marginBottom: '5%',
    // width: '30%',
}

export const formTitleStyle = {
    color: "#159F4A",
    fontWeight: 'bold',
    marginBottom: '1%'
}

export const formSubtitleStyle = {
    fontWeight: "bold",
    fontSize: '18px',
    marginBottom: '2%',
}

export const keyContactStyle = {
    fontWeight: "bold",
    marginBottom: '2%',
}

export const statusSuccessStyle = {
    color: '#159F4A'
}

export const statusFailStyle = {
    color: 'red'
}
