import AppBar from "@mui/material/AppBar";
import { appBarStyle, logoStyle } from "./user-management-styles";
import { Toolbar, Grid } from "@mui/material";
import safLogo from "../../../assets/project-images/saf-logo-green.png";
import Typography from "@mui/material/Typography";
import * as React from "react";

export default function LoginAppBar() {
  return (
    <>
      <AppBar position="fixed" style={appBarStyle} elevation={0}>
        <Toolbar variant="dense">
          <img src={safLogo} style={logoStyle} alt="saf-logo" />

          <Typography
            component="Box"
            sx={{ color: "#159F4A", fontWeight: "bold", fontSize: "18px" }}
          >
            PARTNER ONBOARDING PORTAL
          </Typography>
        </Toolbar>
      </AppBar>
    </>
  );
}
